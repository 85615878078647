import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { parametroAction } from '../../_actions'
import TitleContent from '../TitleContent'
import PropTypes from 'prop-types'
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Skeleton,
  Input,
  Grid,
  TablePagination
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  }
})

const Parametro = (props) => {

  const dispatch = useDispatch()
  const { classes } = props
  const { isLoading } = props.parametro
  const { parametro } = props.parametro  // é o mesmo que props.parametro.parametro se estiver entre as chaves
  const { totalRegistros } = props.parametro
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('descricao');
  const [direction] = useState('asc');

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction
      };

      dispatch(parametroAction.getParametro(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {

  }, [totalRegistros]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Parâmetros">
      <AccessControl
        rule={'parametros.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== parametro && parametro.length > 0 && (
              <TitleContent
               // rule={'parametros.list'}
                length={parametro.length}
                subTitle={'GERÊNCIA DE PARÂMETROS'}
                title={'Lista de Parâmetros'}
                href={'/parametro'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_PARAMETRO}
              />
            )}
            <Grid container xl={12} lg={12} md={12} xs={12}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                  telaManual={MANUAIS_CATEGORIAS.LISTAGEM_PARAMETRO}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Descrição</strong></TableCell>
                    <TableCell><strong>Valor</strong></TableCell>
                    <TableCell align="right"><strong>Ações</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== parametro && parametro.length
                    ? parametro.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.descricao}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipo != 'H' ? n.valor : 'Conteúdo HTML'}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'parametros.edit'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/parametro/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title="Editar">
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />

                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div>
        )}
        no={() => <AccessDenied />}
      />
    </Page>
  )
}
Parametro.propTypes = {
  classes: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
  return {
    parametro: state.parametro
  }
}
const connectedParametroPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Parametro))
)
export { connectedParametroPage as Parametro }
