import { crudService } from '../_services/'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { obraAction } from '../../src/_actions'

export const obraCoordenadaAction = {
  getObraCoordenada,
  getObraCoordenadaById,
  onChangeProps,
  createObraCoordenada,
  deleteObraCoordenadaById,
  clear,
  clearDetail
}


function getObraCoordenada(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'obrasCoordenadas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraCoordenadasList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraCoordenada(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasCoordenadas'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };

          dispatch(getObraCoordenada(filterModel, true));
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())

      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_COORDENADA_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_COORDENADA_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_COORDENADA_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_COORDENADA_CLEAR_DETAIL'
  }
}

function getObraCoordenadaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasCoordenadas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraCoordenadasDetails(response.data))

      let filterModel = {
        IdObra: response.data.idObra
      };
      dispatch(getObraCoordenada(filterModel, true));
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraCoordenadaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasCoordenadas/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraCoordenadasDetails())
      dispatch(notIsLoading())
      var ret = JSON.parse(response.request.responseText);
      dispatch(obraAction.getObraById(ret.key));
    })
  }
}
export function changeObraCoordenadasList(obraCoordenada, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_COORDENADA',
    obraCoordenada: obraCoordenada,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_COORDENADA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraCoordenadasDetails(obraCoordenada) {
  return {
    type: 'OBRA_COORDENADA_DETAIL',
    id: obraCoordenada.id,
    indice: obraCoordenada.indice,
    idObra: obraCoordenada.idObra,
    obra: obraCoordenada.obra,
    latitude: obraCoordenada.latitude,
    longitude: obraCoordenada.longitude,
    obraCoordenada: obraCoordenada.obraCoordenada
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_COORDENADA_CREATED_SUCCESSFULLY'
  }
}
export function deleteObraCoordenadasDetails() {
  return {
    type: 'DELETED_OBRA_COORDENADA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_COORDENADA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_COORDENADA_NOTISLOADING'
  }
}

