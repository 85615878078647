import React, { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { parametroAction } from '../../_actions'

export const ObraAjudaModal = ({ modal, toggle, props }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { parametro } = props;

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    dispatch(parametroAction.getAjuda());
  }, []);

  const formattedParametro = useMemo(() => {
    return { __html: parametro.valor };
  }, [parametro.valor]);

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, marginTop: -10, marginLeft: 0, marginRight: 0, marginBottom: 0 }}>
        <Card>
          <CardHeader
            style={{ marginBottom: 0, paddingBottom: 0, marginLeft: 7 }}
            title={<Typography variant="h6" align="left">
              <b>{'Ajuda'}</b>
            </Typography>}
          />
          <CardContent>
            <Divider />
            <div style={{ marginTop: 10, marginLeft: 7, marginRight: 7 }} dangerouslySetInnerHTML={formattedParametro}></div>
          </CardContent>
        </Card >
      </DialogContent >
    </Dialog >
  )
}
