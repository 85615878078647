import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { Line } from 'react-chartjs-2';
import { options } from './options'
import { AccessControl } from '../../components/Utils/AccessControl'
import CloseIcon from '@mui/icons-material/Close';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CircleIcon from '@mui/icons-material/Circle';
import { useConfirm } from 'material-ui-confirm'
import {
  Box,
  CardHeader,
  CircularProgress,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TablePagination,
  Typography,
  Tooltip
} from '@mui/material'
import { history } from '../../utils'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authActions, obraMedicaoAction, obraEtapaAction, obraAction } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import DoneIcon from '@mui/icons-material/Done';
import { withRouter, } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import { ObraMedicaoEditModal } from './obraMedicaoEditModal.component'
import { ObraMedicaoMotivoModal } from './obraMedicaoMotivoModal.component'
import CustomButton from '../../components/CustomButton/CustomButton'
import { useDebounce } from 'use-debounce';
import HelpIcon from '@mui/icons-material/Help';
import { MANUAIS_CATEGORIAS } from '../../utils'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        sx={{ color: props.value != 0 ? "primary" : "#d9d9d9" }}
        variant="determinate"
        value={props.value != 0 ? props.value : 100} />
      <Box
        top={2}
        left={2}
        bottom={2}
        right={2}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="#000"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ObraMedicaoEdit = ({ props, idObra, obra }) => {

  const { isLoading } = props.obraEtapa;
  const { totalRegistros } = props.obraMedicao
  const isLoadingObraMedicao = props.obraMedicao.isLoading

  const [status, setStatus] = useState(0);
  const { obraMedicao } = props.obraMedicao
  const { obraEtapa } = props.obraEtapa;

  const { graficoEvolucaoObra } = useSelector(
    (state) => state.obra)

  const [modal, setModal] = useState(false);
  const [aprovaMedicao, setAprovaMedicao] = useState(false);
  const [modalMedicao, setModalMedicao] = useState(false);
  const [medicao, setMedicao] = useState(null);
  const [idObraMedicao, setIdObraMedicao] = useState(0);
  const toggle = () => setModal(!modal);
  const toggleModalMedicao = () => setModalMedicao(!modalMedicao);
  const { match: { params } } = props;

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('asc');
  const [firstTimeAcessPage, setFirstTimeAcessPage] = useState(false);

  const handleOpenModal = (id, n) => {
    setMedicao(n);
    setIdObraMedicao(id);
    toggle();
  }

  const handleOpenModalMedicao = (n, aprovaRecusa) => {
    setMedicao(n);
    setAprovaMedicao(aprovaRecusa);
    toggleModalMedicao();
  }

  useEffect(() => {

    let filterModel = {
      IdObra: idObra
    };

    dispatch(obraEtapaAction.getObraEtapa(filterModel, true));

  }, [])

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        IdObra: idObra,
        Limit: limit,
        Term: term,
        Order: order,
        Situacao: status,
        Direction: direction
      };

      dispatch(obraMedicaoAction.getObraMedicao(filterModel))

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction]);

  useEffect(() => {

    const fetchData = (page, limit, term) => {
      let filterModel = {
        Page: page,
        IdObra: idObra,
        Limit: limit,
        Term: term,
        Order: order,
        Situacao: firstTimeAcessPage ? 0 : status != 0 ? status : 0,
        Direction: direction,
      };
      dispatch(obraMedicaoAction.getObraMedicao(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (idObra) {
      dispatch(obraAction.getGraficoEvolucaoObra(idObra))
    }
  }, [])

  const handleChangeRadio = prop => event => {
    setStatus(event.target.value);
  };

  // Your data generation function
  const generateData = () => {
    if (graficoEvolucaoObra) {
      const data = {
        labels: graficoEvolucaoObra.options, // labels
        datasets: [
          {
            fill: true,
            label: 'Gráfico de Evolução da Obra',
            data: graficoEvolucaoObra.series[0],
            borderColor: '#015191',
            backgroundColor: 'rgba(1, 81, 145, 0.5)',
            tension: 0
          },
        ],
      };
      return data;
    } else {
      return [];
    }
  };

  const handleAprovaMedicao = (n) => {
    confirm({
      title: 'Você deseja aprovar esta medição?',
      description: 'A situação será alterada para "Aprovada" e seu percentual de conclusão será adicionado ao respectivo item',
      disabled: props.obraMedicao.isLoading,
      confirmationText: props.obraMedicao.isLoading ? 'Aprovando medição...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      handleOpenModalMedicao(n, true)
    })
  }

  const handleAdicionaMedicaoItem = () => {
    history.push(`/obrasCronogramasObrasEtapasItens/`, idObra)
  }

  const handleReprovaMedicao = (n) => {
    confirm({
      title: 'Você deseja reprovar esta medição?',
      description: 'A situação será alterada para "Recusada" e seu percentual de conclusão será removido do respectivo item',
      disabled: props.obraMedicao.isLoading,
      confirmationText: props.obraMedicao.isLoading ? 'Recusando medição...' : 'Sim, recusar!',
      cancellationText: 'Não!'
    }).then(() => {
      handleOpenModalMedicao(n, false)
    })
  }

  const getTotalConclusao = () => {
    let total = 0;
    let qntd = 0;
    obraEtapa.forEach((obra) => {
      obra.obrasEtapasItens.forEach((item) => {
        total += item.obrasMedicoesEtapasItens?.reduce((accumulator, object) => {
          return accumulator + (object.obraMedicao?.situacao == 1 && object.percentual)
        }, 0)
        qntd++;
      })
    })

    return (total / qntd) == 100;
  }

  function TitleText() {
    return (
      <Typography variant="h5">
        <b>{'Medições da Obra'}</b>
      </Typography>
    )
  }

  function TitleTextProgresso() {
    return (
      <Typography variant="h5">
        <b>{'Progresso dos Itens da Obra'}</b>
      </Typography>
    )
  }

  function TitleTextProgressoObra() {
    return (
      <Typography variant="h5">
        <b>{'Progresso da Obra'}</b>
      </Typography>
    )
  }

  function TitleTextGraficoObra() {
    return (
      <Typography variant="h5">
        <b>{'Gráfico de Evolução da Obra'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <TitleText />
  }

  function SegHeaderProgresso() {
    return <TitleTextProgresso />
  }

  function SegHeaderProgressoObra() {
    return <TitleTextProgressoObra />
  }

  function SegHeaderGraficoObra() {
    return <TitleTextGraficoObra />
  }

  return (
    <>
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CardHeader
            subheader="Alterar a situação de uma medição"
            title={<SegHeader />}
          />
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        {(authActions.isEmpresa() || authActions.isFiscal()) && obraEtapa !== undefined && obraEtapa.length ?
          !getTotalConclusao() ?
            <Grid item>
              <CustomButton
                size="small"
                variant="outlined"
                style={{ verticalAlign: 'middle' }}
                onClick={handleAdicionaMedicaoItem}
                endIcon={<AddBoxIcon />}
              >
                Cadastrar nova medição
              </CustomButton>
            </Grid>
            :
            authActions.isFiscal() && getTotalConclusao() && props.obra.ultimoEvento == 3 &&
            <Grid item lg={12} md={12} xl={12} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
              <CustomButton dark style={{ marginTop: '15px', marginRight: 0 }} onClick={() => dispatch(obraAction.putFinalizaObra(idObra))}>
                <Typography variant="p">Concluir Obra</Typography>
              </CustomButton>
            </Grid>
          :
          null
        }
      </Grid>
      <Grid item xs={12} align="right">
        <FormControl>
          <FormLabel>
            <Typography variant="subtitle1">Filtrar por Status </Typography>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            onChange={handleChangeRadio('status')}
            value={status}
          >
            <FormControlLabel value={0} control={<Radio size="sm" />} label={<Typography variant="subtitle2">PENDENTES</Typography>} />
            <FormControlLabel value={1} control={<Radio size="sm" />} label={<Typography variant="subtitle2">APROVADAS</Typography>} />
            <FormControlLabel value={2} control={<Radio size="sm" />} label={<Typography variant="subtitle2">RECUSADAS</Typography>} />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                  <TableCell size="small" align="center">
                    <strong>DATA/HORA</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>USUÁRIO</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>ITEM</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>SITUAÇÃO</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>VALOR</strong>
                  </TableCell>
                  <TableCell size="small" align="center">
                    <strong>AÇÕES</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {undefined !== obraMedicao && obraMedicao.length
                  ? obraMedicao.map((n) => {
                    return (
                      <TableRow hover key={n.id}>
                        <TableCell component="th" scope="row" align="center" width="120px">
                          {n.data ? moment(n.data).format("DD/MM/YYYY HH:mm") : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {n.usuario ? n.usuario.nome : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {n.obrasMedicoesEtapasItens ? n.obrasMedicoesEtapasItens[0]?.obraEtapaItem?.descritivo?.toUpperCase() : ''}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          <Tooltip title={n.motivo ? n.motivo + (n.dataMudancaSituacao ? ' em ' + moment(n.dataMudancaSituacao).format("DD/MM/YYYY HH:mm") : '') : null}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <CircleIcon sx={{ color: n.situacao == 1 ? '#0d6e0d' : n.situacao == 2 && '#cf0d0d', fontSize: 12, marginRight: 1 }} /> <span>{n.situacao == 1 ? 'APROVADO' : n.situacao == 2 ? 'RECUSADO' : 'PENDENTE'}</span></div>
                          </Tooltip>
                        </TableCell>
                        <TableCell component="th" scope="row" width="100px" align="center">
                          {'R$ ' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                        </TableCell>
                        <TableCell align="center" scope="row" style={{ width: '130px' }}>
                          <AccessControl
                            rule={'obrasMedicoes.list'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <IconButton
                                aria-label="Visualizar medição"
                                component="a"
                                onClick={() =>
                                  handleOpenModal(n.id, n)
                                }
                                size="small"
                              >
                                <Tooltip title={'Visualizar'}>
                                  <Tooltip >
                                    <VisibilityIcon />
                                  </Tooltip>
                                </Tooltip>
                              </IconButton>
                            )}
                          />
                          {n.situacao === null &&
                            <AccessControl
                              rule={'obrasMedicoes.approve'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Aprovar"
                                  disabled={n.situacao == 1}
                                  component="a"
                                  onClick={() =>
                                    handleAprovaMedicao(n)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Aprovar'}>
                                    <Tooltip >
                                      <DoneIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          }
                          {n.situacao === null &&
                            <AccessControl
                              rule={'obrasMedicoes.refusal'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Reprovar"
                                  onClick={() => handleReprovaMedicao(n)}
                                  size="small"
                                  disabled={n.situacao == 2}
                                >
                                  <Tooltip title="Reprovar">
                                    <CloseIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          }
                        </TableCell>
                      </TableRow >
                    )
                  })
                  :
                  (
                    !isLoadingObraMedicao ?
                      <TableRow>
                        <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                      </TableRow>
                      :
                      Array.apply(null, { length: 5 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell><TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={totalRegistros}
            page={page}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
            onPageChange={handleChangePage}
            rowsPerPage={limit}
            onRowsPerPageChange={handleChangeLimit}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <CardHeader
            subheader="Exibe o percentual de conclusão dos itens. Apenas medições já aprovadas são contabilizadas"
            title={<SegHeaderProgresso />}
          />
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {obraEtapa && undefined !== obraEtapa && obraEtapa.length
                  ?
                  obraEtapa.map((obra) => (
                    <React.Fragment key={obra.id}>
                      <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                        <TableCell size="small">
                          <strong>{obra.titulo.toUpperCase()}</strong>
                        </TableCell>
                        <TableCell size="small">
                          <strong>CONCLUSÃO</strong>
                        </TableCell>
                      </TableRow>
                      {obra.obrasEtapasItens.map((item) =>
                        (authActions.isGestor()
                          || authActions.isPrefeitura()
                          || authActions.isFiscal()
                          || (authActions.isEmpresa() && item.obrasOrcamentosItens.length > 0)) && (
                          <TableRow hover key={item.id}>
                            <TableCell component="th" scope="row">
                              {item.descritivo}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <CircularProgressWithLabel value={item.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                                return accumulator + (object.obraMedicao?.situacao == 1 && object.percentual)
                              }, 0)} />
                            </TableCell>
                          </TableRow>
                        ))}
                    </React.Fragment>
                  ))
                  :
                  (
                    !isLoading ?
                      <TableRow>
                        <TableCell rowSpan={2} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                      </TableRow>
                      :
                      Array.apply(null, { length: 5 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {authActions.isFiscal() && getTotalConclusao() && props.obra.ultimoEvento == 3 &&
        <Grid item lg={12} md={12} xl={12} xs={12} style={{ display: 'flex', justifyContent: 'end' }}>
          <CustomButton dark style={{ marginTop: '15px', marginRight: 0 }} onClick={() => dispatch(obraAction.putFinalizaObra(idObra))}>
            <Typography variant="p">Concluir Obra</Typography>
          </CustomButton>
        </Grid>
      }
      {/*
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <CardHeader
            subheader="Exibe o percentual total de conclusão da obra em relação a todas as medições que foram aprovadas"
            title={<SegHeaderProgressoObra />}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 3, ml: 1 }}>
              <BorderLinearProgress variant="determinate" value={props.obra.percentualConclusaoObra != 0 ? props.obra.percentualConclusaoObra : 0} />
            </Box>
            <Box sx={{ minWidth: 38 }}>
              <Typography variant="body1" color="text.secondary">{`${props.obra.percentualConclusaoObra != 0 ? props.obra.percentualConclusaoObra : 0}%`}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      */}
      <Grid container spacing={2} style={{ marginTop: 10, justifyContent: 'center' }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <CardHeader
            subheader="Exibe a evolução da obra de acordo com as medições aprovadas"
            title={<SegHeaderGraficoObra />}
          />
        </Grid>
        {graficoEvolucaoObra && graficoEvolucaoObra != null && graficoEvolucaoObra?.options?.length > 0 ?
          <Grid item lg={6} md={6} xl={6} xs={12} >
            <Line data={generateData} options={options} />
          </Grid>
          :
          <Grid item lg={12} md={12} xl={12} xs={12} align="center" >
            <Typography variant="body1">Sem informações para exibir</Typography>
          </Grid>
        }
      </Grid>

      {/*  <TimelineGantt props={props} idObra={idObra} obra={obra} />*/}
      {
        (modal ?
          <ObraMedicaoEditModal toggle={toggle} modal={modal} props={props} idObraMedicao={idObraMedicao} medicao={medicao} />
          :
          null
        )
      }
      {
        (modalMedicao ?
          <ObraMedicaoMotivoModal toggle={toggleModalMedicao} modal={modalMedicao} props={props} medicao={medicao} aprovaMedicao={aprovaMedicao} status={status} />
          :
          null
        )
      }

      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_MEDICAO}
          />
          :
          null
        )
      }
    </>
  );
};

ObraMedicaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraMedicaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraMedicaoEdit))
)
export { connectedObraMedicaoEditPage as ObraMedicaoEdit }

export default ObraMedicaoEdit;
