import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const obraOrcamentoItemAction = {
  getObraOrcamentoItem,
  getObraOrcamentoItemObra,
  getObraOrcamentoItemSelect,
  getObraOrcamentoItemById,
  onChangeProps,
  editObraOrcamentoItemInfo,
  editObraOrcamentoItemAprova,
  editObraOrcamentoItemRecusa,
  createObraOrcamentoItem,
  deleteObraOrcamentoItemById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraOrcamentoItem(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentosItens' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraOrcamentoItemsList(response.data.items, response.data.totalRows, response.data.totalEmpresas))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err)
      })
  }
}

function getObraOrcamentoItemObra(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentosItens/obra' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraOrcamentoItemsList(response.data, null))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        dispatch(notIsLoading())
        console.log(err)
      })
  }
}

function getObraOrcamentoItemSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasOrcamentosItens/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObraOrcamentoItemsList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraOrcamentoItem(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasOrcamentosItens'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso!')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/obrasOrcamentosItens')
        } else {
          // toast.error('Oops! Erro ao cadastrar Obra! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar Obra! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ORCAMENTO_ITEM_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ORCAMENTO_ITEM_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_CLEAR'
  }
}

function editObraOrcamentoItemInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasOrcamentosItens/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ORCAMENTO_ITEM_FAILURE', error }
  }
}

function editObraOrcamentoItemAprova(id, idObra) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentosItens/aprova/' + id
    crudService.
      putWithOutMessage(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          let filterModel = {
            Id: idObra
          };
          dispatch(getObraOrcamentoItemObra(filterModel))
          dispatch(notIsLoading())
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar item! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ORCAMENTO_ITEM_FAILURE', error }
  }
}

function editObraOrcamentoItemRecusa(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentosItens/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/obras')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_ORCAMENTO_ITEM_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_ORCAMENTO_ITEM_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_ORCAMENTO_ITEM_FAILURE', error }
}


function getObraOrcamentoItemById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasOrcamentosItens/' + id;
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraOrcamentoItemsDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'obraEtapaItem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraOrcamentoItemById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOrcamentosItens/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraOrcamentoItemsDetails())
      dispatch(notIsLoading())
      history.push('/obrasOrcamentosItens')
      dispatch(getObraOrcamentoItem(filterModel))
    })
  }
}
export function changeObraOrcamentoItemsList(obraOrcamentoItem, totalRows, totalEmpresas) {
  return {
    type: 'FETCHED_ALL_OBRA_ORCAMENTO_ITEM',
    obraOrcamentoItem: obraOrcamentoItem,
    totalRegistros: totalRows,
    totalEmpresas: totalEmpresas
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraOrcamentoItemsDetails(obraOrcamentoItem) {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_DETAIL',
    id: obraOrcamentoItem.id,
    idObraOrcamentoItem: obraOrcamentoItem.idObraOrcamentoItem,
    idObraEtapaItem: obraOrcamentoItem.idObraEtapaItem,
    valor: obraOrcamentoItem.valor,
    excluido: obraOrcamentoItem.excluido,
    obraOrcamentoItem: obraOrcamentoItem.obraOrcamentoItem,
    obrasEtapasItens: obraOrcamentoItem.obrasEtapasItens,
    obrasOrcamentos: obraOrcamentoItem.obrasEtapasItens,
    status: obraOrcamentoItem.status,
    dataAlteracaoStatus: obraOrcamentoItem.dataAlteracaoStatus,
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_CREATED_SUCCESSFULLY'
  }
}
export function deleteObraOrcamentoItemsDetails() {
  return {
    type: 'DELETED_OBRA_ORCAMENTO_ITEM_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_ORCAMENTO_ITEM_NOTISLOADING'
  }
}
