const initialState = {
  loading: false,
  usuario: [],
  open: false,
  id: '',
  descricao: ''
}

export function unidade(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_UNIDADE':
      return {
        ...state,
        unidade: action.unidade
      }    
    default:
      return state
  }
}
