/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import {
  Autocomplete,
  Button,
  Dialog,
  Hidden,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Tooltip,
  TextField
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { usuarioOrgaoAction, authActions } from '_actions'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250
  },
  showPrefeitura: {
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const SelectOrgao = (props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [disabled, setDisabled] = React.useState(false)
  const { usuarioOrgao, orgao, idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  var options = usuarioOrgao.map(u => ({ id: u.orgao?.id, nome: u.orgao?.nome?.toUpperCase() }));

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  const hasOrgaoSelected = useSelector((state) => {
    if (
      state.usuarioOrgao.idOrgao === '' ||
      state.usuarioOrgao.idOrgao === undefined
    ) {
      return false
    }
    return true
  })

  const dispatch = useDispatch()

  useEffect(() => {
    setInitialUsuarioOrgao()
  }, [usuarioOrgao])

  useEffect(() => {
  }, [idOrgao])

  useEffect(() => {
    setValue(idOrgao)
  }, [])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null && obj.id > 0) {
      let selectedUsuarioOrgao = usuarioOrgao.filter(
        (item) => item.idOrgao === obj.id
      )
      dispatch(
        usuarioOrgaoAction.changeDetailsUsuarioOrgao(
          selectedUsuarioOrgao[0]
        )
      )
    } else {
    }
    setOpen(false)
  }

  const handleClickOpen = () => {
    setOpen(true)
    setDisabled(false)
  }

  const setInitialUsuarioOrgao = () => {
    if (
      usuarioOrgao.length === 0 ||
      usuarioOrgao === undefined
    ) {
      return null
    } else if (
      undefined !== usuarioOrgao &&
      usuarioOrgao.length === 1
    ) {
      dispatch(
        usuarioOrgaoAction.changeDetailsUsuarioOrgao(
          usuarioOrgao[0]
        )
      )
    } else if (!hasOrgaoSelected) {
    }
  }

  const handleClose = () => {
    setOpen(false)
    setDisabled(false)
  }

  const handleClear = () => {
    dispatch(
      usuarioOrgaoAction.clearSelected()
    )
    setOpen(false)
    setDisabled(false)
  }

  const returnContent = () => {
    return (
      <div>
        {authActions.isPrefeitura() || authActions.isMotorista() || authActions.isFiscal() ?
          <>
            <Hidden smDown>
              <Typography variant="overline" color="inherit" className={classes.showPrefeitura}>
                Órgão vinculado:
                <Typography variant="button" color="inherit">
                  {' '}
                  {usuarioOrgao.length > 0 && usuarioOrgao[0] != null ? usuarioOrgao[0].orgao.nome : ''}
                </Typography>
              </Typography>
            </Hidden>
          </>
          : authActions.isGestor() ?
            <>
              <Hidden smDown>
                <Typography variant="overline" color="inherit">
                  Órgão selecionado:
                  <Typography variant="button" color="inherit">
                    {' '}
                    <b>{(hasOrgaoSelected ? orgao.nome : 'TODOS')}</b>
                  </Typography>
                </Typography>
              </Hidden>
              <Tooltip title="Trocar órgão">
                <IconButton color="inherit" onClick={handleClickOpen}>
                  <ArrowDropDownIcon />
                </IconButton>
              </Tooltip>
              <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
              >
                <DialogTitle>
                  {' '}
                  <Typography variant="overline" color="inherit">
                    <b>Meus órgãos</b>
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <form>
                    <Autocomplete
                      value={value}
                      onChange={(event, newValue) => {
                        setValue(newValue);
                      }}
                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      id="controllable-states-demo"
                      options={options}
                      defaultValue={null}
                      getOptionLabel={option => option.nome ? option.nome : ''}
                      renderInput={(params) => <TextField {...params} label="SELECIONE ..." fullWidth />}
                      style={{ width: "300px", marginTop: 5 }}
                      fullWidth
                    />
                  </form>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant="outlined"
                    disabled={disabled}
                    align="left"
                    onClick={handleClear}
                    style={{ color: '#000', borderColor: '#000' }}
                  >
                    TODOS
                  </Button>
                  <Button
                    variant="contained"
                    disabled={disabled}
                    align="left"
                    onClick={handleClose}
                    style={{ color: '#FFF', backgroundColor: '#000' }}
                  >
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </>
            : authActions.isEmpresa() &&
            null
        }
      </div>
    )
  }

  return (
    <div>
      {returnContent()}
    </div>
  )
}

SelectOrgao.propTypes = {
  className: PropTypes.string
}
export default SelectOrgao
