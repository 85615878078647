import { crudService } from '../_services/'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { obraAction } from '../../../ClientApp/src/_actions/'

export const obraAvaliacaoAction = {
  getObraAvaliacao,
  getObraAvaliacaoPublic,
  getObraAvaliacaoById,
  onChangeProps,
  editObraAvaliacaoInfo,
  createObraAvaliacao,
  deleteObraAvaliacaoById,
  clear,
  clearDetail
}


function getObraAvaliacao(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'obrasAvaliacoes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraAvaliacaosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraAvaliacaoPublic(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'obras/public/anexos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraAvaliacaosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraAvaliacao(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasAvaliacoes'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };

          dispatch(getObraAvaliacao(filterModel, true));
          dispatch(obraAction.getObraById(ret.key))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
       // toast.error('Oops! Erro ao cadastrar Avaliacao! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_AVALIACAO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_AVALIACAO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_AVALIACAO_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_AVALIACAO_CLEAR_DETAIL'
  }
}

function editObraAvaliacaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasAvaliacoes/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };
          dispatch(getObraAvaliacao(filterModel, true));

        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar anexo da Obra! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_OBRA_AVALIACAO_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_OBRA_AVALIACAO_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_AVALIACAO_FAILURE', error }
  }
}

function getObraAvaliacaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasAvaliacoes/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraAvaliacaosDetails(response.data))

      let filterModel = {
        IdObra: response.data.idObra
      };
      dispatch(getObraAvaliacao(filterModel, true));
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraAvaliacaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasAvaliacoes/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraAvaliacaosDetails())
      dispatch(notIsLoading())

      dispatch(notIsLoading())

      var ret = JSON.parse(response.request.responseText);
      let filterModel = {
        IdObra: ret.key
      };

      dispatch(getObraAvaliacao(filterModel, true));

    })
  }
}
export function changeObraAvaliacaosList(obraAvaliacao, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_AVALIACAO',
    obraAvaliacao: obraAvaliacao,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_AVALIACAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraAvaliacaosDetails(obraAvaliacao) {
  return {
    type: 'OBRA_AVALIACAO_DETAIL',
    id: obraAvaliacao.id,
    idEmpresa: obraAvaliacao.idEmpresa,
    idUsuario: obraAvaliacao.idUsuario,
    nota: obraAvaliacao.nota,
    descricao: obraAvaliacao.descricao,
    idObra: obraAvaliacao.idObra,
    idOrgao: obraAvaliacao.idOrgao,
    obra: obraAvaliacao.obra,
    excluido: obraAvaliacao.excluido,
    obraAvaliacao: obraAvaliacao.obraAvaliacao
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_AVALIACAO_CREATED_SUCCESSFULLY'
  }
}
export function deleteObraAvaliacaosDetails() {
  return {
    type: 'DELETED_OBRA_AVALIACAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_AVALIACAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_AVALIACAO_NOTISLOADING'
  }
}

