import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { options } from './options'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoObrasPorMesChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, ...rest } = props
  const graficoObrasPorMes = useSelector((state) => state.dashboard ? state.dashboard.dashboard.graficoObrasPorMes : undefined)
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  useEffect(() => {
   // dispatch(dashboardAction.getDashboardGraficoObras());    
  }, []);

  const generateData = () => {
    if (graficoObrasPorMes) {
      const data = {
        labels: graficoObrasPorMes.options,
        datasets: [
          {
            label: 'Valor total',
            yAxisID: 'A',
            backgroundColor: '#000',
            data: graficoObrasPorMes.series[0]
          },
          {
            label: 'Quantidade',
            yAxisID: 'B',
            backgroundColor: '#1C5585',
            data: graficoObrasPorMes.series[1]
          }      
        ]
      }
      return data
    } else {
      return []
    }
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={<Typography variant="h6" align="center">
         Obras Por Mês
        </Typography>}
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        <Bar data={generateData} options={options} />
      </CardContent>
      <Divider />
    </Card>
  )
}

GraficoObrasPorMesChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoObrasPorMesChartCard
