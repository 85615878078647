import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { Page } from 'components'
import PropTypes from 'prop-types'
import AddBoxIcon from '@mui/icons-material/AddBox';
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CardActions,
  CardHeader,
  CircularProgress,
  Grid,
  Typography,
  Paper,
  Skeleton
} from '@mui/material'
import moment from 'moment'
import { useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import CustomButton from '../../components/CustomButton/CustomButton'
import clsx from 'clsx'
import { withStyles } from '@mui/styles'
import { ObraSummaryCard } from './obraSummaryCard.component'
import { ObraMedicaoAddModal } from './obraMedicaoAddModal.component'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        sx={{ color: props.value != 0 ? "primary" : "#d9d9d9" }}
        variant="determinate"
        value={props.value != 0 ? props.value : 100} />
      <Box
        top={2}
        left={2}
        bottom={2}
        right={2}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="#000"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const ObraMedicaoListObrasEtapasItens = (props) => {

  console.log('ORGAO DA OBRA =>', props.obra.idOrgao)
  const location = useLocation();
  const idObra = location.state;
  const { obraMedicao } = props.obraMedicao
  const [obraEtapaItem, setObraEtapaItem] = useState({});
  const [obraEtapaModal, setObraEtapaModal] = useState(null);
  const [percentual, setPercentual] = useState('');
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const { obraEtapa } = useSelector(
    (state) => state.obraEtapa)

  const isLoading = useSelector(
    (state) => state.usuarioOrgao.isLoading
  )

  const handleOpenModal = (n, percentual) => {
    setObraEtapaItem(n);
    setPercentual(percentual);
    setObraEtapaModal(null)
    toggle();
  }

  const handleOpenModalEtapas = (etapa) => {
    setObraEtapaModal(etapa);
    toggle();
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Medição'}</b>
      </Typography>
    )
  }

  function ListMedicoesText() {
    return (
      <Typography variant="h5">
        <b>{'Medições enviadas'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  function SegHeaderMedicoes() {
    return <ListMedicoesText />
  }

  return (
    <Page
      className={classes.root}
      title="Lista de Itens da Obra - Lançar Medição"
    >
      <ObraSummaryCard idObra={idObra} />
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <CardHeader
          style={{ marginBottom: 5 }}
          subheader="Cadastro de Medições da Obra"
          title={<SegHeader />}
        />
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableBody>
                  {undefined !== obraEtapa && obraEtapa.length
                    ?
                    obraEtapa.map((etapa) => (
                      <React.Fragment key={etapa.id}>
                        <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                          <TableCell size="small">
                            <strong>{etapa.titulo.toUpperCase()}</strong>
                            <CustomButton
                              size="small"
                              variant="outlined"
                              style={{ verticalAlign: 'middle', marginLeft: 7 }}
                              onClick={() => handleOpenModalEtapas(etapa)}
                              endIcon={<AddBoxIcon />}
                            >
                              Cadastrar nova medição para a etapa
                            </CustomButton>
                          </TableCell>
                          <TableCell size="small">
                            <strong>CONCLUSÃO</strong>
                          </TableCell>
                          <TableCell size="small" align="center">
                            <strong>AÇÃO</strong>
                          </TableCell>
                        </TableRow>
                        {etapa.obrasEtapasItens.map((item) =>
                          item.obrasOrcamentosItens.length > 0 &&
                          (
                            <TableRow hover key={item.id} style={{ alignContent: 'center' }}>
                              <TableCell component="th" scope="row">
                                {item.descritivo}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <CircularProgressWithLabel value={item.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                                  return accumulator + (object.obraMedicao.situacao == 1 && object.percentual)
                                }, 0)} />
                              </TableCell>
                              <TableCell component="th" scope="row" align="center">
                                {item.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                                  return accumulator + (object.obraMedicao.situacao == 1 && object.percentual)
                                }, 0) < 100 ?
                                  <Button
                                    variant="outlined"
                                    style={{ color: '#000', borderColor: '#000', alignContent: 'center' }}
                                    onClick={() =>
                                      handleOpenModal(item, item.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                                        return accumulator + (object.obraMedicao.situacao == 1 && object.percentual)
                                      }, 0))
                                    }>
                                    Adicionar
                                  </Button>
                                  : null
                                }
                              </TableCell>
                            </TableRow>
                          ))}
                      </React.Fragment>
                    ))
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 10 }}>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <CardHeader
              style={{ marginBottom: 5 }}
              subheader="Lista de medições já enviadas pela empresa"
              title={<SegHeaderMedicoes />}
            />
            <TableContainer component={Paper} style={{ marginTop: 10 }}>
              <Table>
                <TableHead>
                  <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell size="small">
                      <strong>DATA/HORA</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>USUÁRIO</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>ITEM</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>SITUAÇÃO</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== obraMedicao && obraMedicao.length
                    ? obraMedicao.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.data ? moment(n.data).format("DD/MM/YYYY HH:mm") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? n.usuario.nome : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.obrasMedicoesEtapasItens ? n.obrasMedicoesEtapasItens[0]?.obraEtapaItem?.descritivo.toUpperCase() : ''}
                            {n.motivo &&
                              <Box display="flex" style={{ marginTop: '8px', height: '24px', backgroundColor: '#ddd7', borderRadius: '8px', paddingLeft: '8px' }}>
                                <Typography variant="p" fontSize="14px" style={{ textAlign: 'left' }}>{n.motivo}</Typography>
                              </Box>
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <CircleIcon sx={{ color: n.situacao == 1 ? '#0d6e0d' : n.situacao == 2 && '#cf0d0d', fontSize: 12, marginRight: 1 }} /> <span>{n.situacao == 1 ? 'APROVADA' : n.situacao == 2 ? 'RECUSADA' : 'PENDENTE DE ANÁLISE'}</span></div>
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <CardActions style={{ marginTop: 15 }}>
          <Grid item lg={12} md={12} xl={12} xs={12} style={{
            display: 'flex',
            alignItems: 'right',
            flexDirection: 'row',
            textAlgin: 'right',
            justifyContent: 'right'
          }}>
            <Button
              variant="outlined"
              style={{ color: '#000', borderColor: '#000' }}
              onClick={() => history.goBack()}
            >
              Voltar para tela anterior
            </Button>
          </Grid>
        </CardActions>
      </Grid >
      {
        (modal ?
          <ObraMedicaoAddModal toggle={toggle} modal={modal} props={props} idObra={idObra} obraEtapaItem={obraEtapaItem} percentual={percentual} obraEtapaModal={obraEtapaModal} />
          :
          null
        )
      }
    </Page >
  )
}

ObraMedicaoListObrasEtapasItens.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraMedicaoListObrasEtapasItensPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraMedicaoListObrasEtapasItens))
)
export { connectedObraMedicaoListObrasEtapasItensPage as ObraMedicaoListObrasEtapasItens }
