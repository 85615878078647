import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'
import { Page, MapContainerCoordenadasModal } from 'components/Maps'
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Fab,
  Grid,
  Input,
  InputAdornment,
  ImageList,
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  authActions,
  obraAction,
  obraEtapaItemAction,
  obraMedicaoAction,
  unidadeAction
} from '../../_actions'
import { styled } from '@mui/material/styles';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { uploadImage } from '_services'
import { stringUtils } from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import CustomButton from '../../components/CustomButton';

const CustomInput = styled(Input)(() => ({
  display: "none"
}));

const CustomDeleteIcon = styled(DeleteIcon)(() => ({
  color: '#4f4f4f',
  marginTop: 47,
  fontSize: 21,
  '&:hover': {
    cursor: 'pointer'
  },
}));

export const ObraCoordenadasModal = ({ modal, toggle, props, obra, idObra }) => {

  const formRef = React.createRef(null);

  const [imagesUploaded, setImagesUploaded] = useState([]);

  const [descricao, setDescricao] = useState('');
  const [quantidade, setQuantidade] = useState('');
  const [percentualValor, setPercentualValor] = useState('');
  const [open, setOpen] = useState(false);

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className, obraCoordenada, obraOcorrenciaAnexo, ...rest } = props

  const handleClose = () => {
    toggle();
  }

  const handleSubmit = () => {

    const payload = {
    }

    if (payload) {
      dispatch(obraMedicaoAction.createObraMedicao(payload))
      toggle();

    } else {
      toast.error('Selecione pelo menos uma imagem para a medição antes de salvar')
    }
  }


  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Coordenada'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      maxWidth='lg'
      style={{ padding: 0, margin: 0 }}
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0, height: 500 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastro de Coordenadas Adicionais"
              title={<SegHeader />}
            />
            <CardContent>
              <MapContainerCoordenadasModal latitude={props.obra.latitude} longitude={props.obra.longitude} nome={props.obra.titulo} classes={props.classes} obra={props.obra} idObra={props.obra.id} />
            </CardContent>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
