import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Pie } from 'react-chartjs-2'
import { makeStyles } from '@mui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@mui/material'
import { options } from './options'
import palette from 'theme/palette'
import { useSelector } from 'react-redux'
import { dashboardAction } from '../../../../_actions'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const GraficoObrasStatusChartCard = (props) => {

  const dispatch = useDispatch()

  const { className, ...rest } = props
  const graficoObras = useSelector((state) => state.dashboard ? state.dashboard.dashboard.graficoObras : undefined)
  const { isLoading } = useSelector((state) => state.dashboard)
  const classes = useStyles()

  useEffect(() => {
   // dispatch(dashboardAction.getDashboardGraficoObras());

    //console.log("graficoObras", graficoObras)
   // console.log("props.graficoObras", props.graficoObras)
  }, []);

  const generateData = () => {
    if (graficoObras) {
      const data = {
        labels: graficoObras.options, //labels
        datasets: [
          {
            label: 'Obras criadas',
            data: graficoObras.series[0],  //dados
            backgroundColor: [
              '#9E9E9E',
              '#673AB7',
              '#2196F3',
              '#FF5722',
              '#4CAF50',
              '#FF9800'
            ],
            borderColor: [
              '#9E9E9E',
              '#673AB7',
              '#2196F3',
              '#FF5722',
              '#4CAF50',
              '#FF9800'
            ],
            borderWidth: 2
          },
        ],
      }
      return data
    } else {
      return []
    }
  }


  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        title={<Typography variant="h6" align="center">
         Obras Por Status
        </Typography>}
      />
      <Divider />
      <CardContent className={classes.chartContainer}>
        <Pie data={generateData} options={options} />
      </CardContent>
      <Divider />
    </Card>
  )
}

GraficoObrasStatusChartCard.propTypes = {
  className: PropTypes.string
}

export default GraficoObrasStatusChartCard
