const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraOrcamento: [],
  open: false,
  id: '',
  data: '',
  prazo: '',
  diasPrazo: 0,
  valor: 0,
  statusOrcamento: 0,
  idOrgao: 0,
  idObra: 0,
  idEmpresa: 0,
  empresa: [],
  obra: [],
  excluido: false,
  observacoes: '',
  obrasOrcamentosItens: []
}

export function obraOrcamento(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_ORCAMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_ORCAMENTO_SUCCESS':
      return {
        ...state,
        id: action.obraOrcamento.id,
        isLoading: false,
        data: action.obraOrcamento.data,
        prazo: action.obraOrcamento.prazo,
        diasPrazo: action.obraOrcamento.diasPrazo,
        valor: action.obraOrcamento.valor,
        statusOrcamento: action.obraOrcamento.statusOrcamento,
        idOrgao: action.obraOrcamento.idOrgao,
        idObra: action.obraOrcamento.idObra,
        obra: action.obraOrcamento.obra,
        idEmpresa: action.obraOrcamento.idEmpresa,
        empresa: action.obraOrcamento.empresa,
        excluido: action.obraOrcamento.excluido,
        observacoes: action.obraOrcamento.observacoes,
        obrasOrcamentosItens: action.obraOrcamento.obrasOrcamentosItens
      }
    case 'CREATE_OBRA_ORCAMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_ORCAMENTO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_ORCAMENTO_SUCCESS':
      return {
        ...state,
        id: action.obraOrcamento.id,
        isLoading: false,
        data: action.obraOrcamento.data,
        prazo: action.obraOrcamento.prazo,
        diasPrazo: action.obraOrcamento.diasPrazo,
        valor: action.obraOrcamento.valor,
        statusOrcamento: action.obraOrcamento.statusOrcamento,
        idOrgao: action.obraOrcamento.idOrgao,
        idObra: action.obraOrcamento.idObra,
        obra: action.obraOrcamento.obra,
        idEmpresa: action.obraOrcamento.idEmpresa,
        empresa: action.obraOrcamento.empresa,
        excluido: action.obraOrcamento.excluido,
        observacoes: action.obraOrcamento.observacoes,
        obrasOrcamentosItens: action.obraOrcamento.obrasOrcamentosItens
      }
    case 'EDIT_OBRA_ORCAMENTO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_ORCAMENTO':
      return {
        ...state,
        obraOrcamento: action.obraOrcamento,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_ORCAMENTO_CLEAR':
      return initialState
    case 'OBRA_ORCAMENTO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraOrcamento: [...state.obraOrcamento, action.data.obraOrcamento]
      }
    case 'OBRA_ORCAMENTO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        data: action.data,
        prazo: action.prazo,
        diasPrazo: action.diasPrazo,
        valor: action.valor,
        statusOrcamento: action.statusOrcamento,
        idOrgao: action.idOrgao,
        idObra: action.idObra,
        obra: action.obra,
        idEmpresa: action.idEmpresa,
        empresa: action.empresa,
        excluido: action.excluido,
        observacoes: action.observacoes,
        obrasOrcamentosItens: action.obrasOrcamentosItens
      }
    case 'OBRA_ORCAMENTO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ORCAMENTO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_ORCAMENTO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
