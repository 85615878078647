import { crudService } from '../_services'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const obraEtapaItemAction = {
  getObraEtapaItem,
  getObraEtapaItemSelect,
  getObraEtapaItemById,
  onChangeProps,
  editObraEtapaItemInfo,
  createObraEtapaItem,
  deleteObraEtapaItemById,
  clear,
  clearCampos,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraEtapaItem(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasEtapasItens' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasEtapasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getObraEtapaItemSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasEtapasItens/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObrasEtapasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraEtapaItem(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasEtapasItens'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(notIsLoading())
          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            Id: ret.key
          };
          dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));

        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ETAPA_ITEM_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ETAPA_ITEM_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_ETAPA_ITEM_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_ETAPA_ITEM_CLEAR_DETAIL'
  }
}

export function clearCampos() {
  return {
    type: 'OBRA_ETAPA_ITEM_CLEAR_CAMPOS'
  }
}

function editObraEtapaItemInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasEtapasItens/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            Id: ret.key
          };
          dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));

        } else {
          //  toast.error('Oops! Erro ao alterar ObraEtapaItem! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ObraEtapaItem! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ETAPA_ITEM_FAILURE', error }
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_ETAPA_ITEM_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_ETAPA_ITEM_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_ETAPA_ITEM_FAILURE', error }
}


function getObraEtapaItemById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasEtapasItens/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObrasEtapasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'obraEtapaItem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraEtapaItemById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasEtapasItens/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObrasEtapasDetails())
      dispatch(notIsLoading())

      var ret = JSON.parse(response.request.responseText);
      let filterModel = {
        Id: ret.key
      };

      dispatch(obraEtapaItemAction.getObraEtapaItem(filterModel, true));

    })
  }
}
export function changeObrasEtapasList(obraEtapaItem, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_ETAPA_ITEM',
    obraEtapaItem: obraEtapaItem,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_ETAPA_ITEM_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObrasEtapasDetails(obraEtapaItem) {
  return {
    type: 'OBRA_ETAPA_ITEM_DETAIL',
    id: obraEtapaItem.id,
    descritivo: obraEtapaItem.descritivo,
    codigo: obraEtapaItem.codigo,
    idObraEtapa: obraEtapaItem.idObraEtapa,
    idUnidade: obraEtapaItem.idUnidade,
    quantidade: obraEtapaItem.quantidade,
    idTipoServico: obraEtapaItem.idTipoServico,
    tipoServico: obraEtapaItem.tipoServico,
    tabelaInsumo: obraEtapaItem.tabelaInsumo,
    valorReferencia: obraEtapaItem.valorReferencia,
    idTabelaInsumo: obraEtapaItem.idTabelaInsumo,
    idEstadoTabelaInsumo: obraEtapaItem.idEstadoTabelaInsumo,
    anoTabelaInsumo: obraEtapaItem.anoTabelaInsumo,
    mesTabelaInsumo: obraEtapaItem.mesTabelaInsumo,
    onerado: obraEtapaItem.onerado,
    obraEtapa: obraEtapaItem.obraEtapa
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_ETAPA_ITEM_CREATED_SUCCESSFULLY'
  }
}
export function deleteObrasEtapasDetails() {
  return {
    type: 'DELETED_OBRA_ETAPA_ITEM_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ETAPA_ITEM_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_ETAPA_ITEM_NOTISLOADING'
  }
}
