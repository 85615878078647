import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  obraAction,
  obraMedicaoAction
} from '../../_actions'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const ObraMedicaoMotivoModal = ({ modal, toggle, props, idObra, aprovaMedicao, medicao, status }) => {

  const formRef = React.createRef(null);

  const [motivo, setMotivo] = useState('');

  const dispatch = useDispatch()
  const { classes, className, obraEtapa, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idObra) {
      dispatch(obraAction.getObraById(idObra))
    }
  }, [])

  const handleSubmit = () => {
    if (aprovaMedicao) {
      dispatch(obraMedicaoAction.editObraMedicaoAprova(medicao.id, props.obra.id, status, motivo));
    } else {
      dispatch(obraMedicaoAction.editObraMedicaoRecusa(medicao.id, props.obra.id, status, motivo));
    }

    toggle();
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{aprovaMedicao ? 'Aprovar Medição' : 'Reprovar Medição'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Alterar o Status de uma Medição"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <TextValidator
                className={classes.textField}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                id="motivo"
                label="Informe o Motivo"
                margin="normal"
                onInput={stringUtils.toInputUppercase}
                onChange={(event) => setMotivo(event.target.value)}
                value={motivo || ''}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.obraMedicao.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraMedicao.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraMedicao.isLoading ? 'Salvando medição...' : 'Salvar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
