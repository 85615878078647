import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  Tooltip,
  Typography,
  IconButton
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { obraMedicaoAction } from '../../_actions'
import { phoneMask, cnpjMask } from '../../utils'
import { ObraMedicaoEditModalAnexo } from './obraMedicaoEditModalAnexo.component';

export const ObraMedicaoEditModal = ({ modal, toggle, props, idObraMedicao, medicao }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const razoesSociaisProcessadas = new Set();

  const [idObraMedicaoEtapaItem, setIdObraMedicaoEtapaItem] = useState(0);
  const [obraMedicaoEtapaItem, setObraMedicaoEtapaItem] = useState(null);

  const [modalAnexos, setModalAnexos] = useState(false);
  const toggleAnexos = () => setModalAnexos(!modalAnexos);

  const [modalAnexosImagem, setModalAnexosImagem] = useState(false);
  const toggleAnexosImagem = () => setModalAnexosImagem(!modalAnexosImagem);

  const handleOpenModalAnexos = (id, n) => {
    setIdObraMedicaoEtapaItem(id)
    setObraMedicaoEtapaItem(n);
    if (n.obrasMedicoesEtapasItensAnexos.length > 1) {
      console.log('nnnnn', n)
      toggleAnexos();
    } else {
      toggleAnexosImagem();
    }
  }

  const isLoading = useSelector(
    (state) => state.usuarioOrgao.isLoading
  )

  const obrasMedicoesEtapasItens = useSelector(
    (state) => state.obraMedicao.obrasMedicoesEtapasItens
  )

  const handleClose = () => {
    toggle();
  }

  function CircularProgressWithLabel(props) {
    return (
      <Box position="relative" display="inline-flex">
        <CircularProgress
          sx={{ color: props.value != 0 ? "primary" : "#d9d9d9" }}
          variant="determinate"
          value={props.value != 0 ? props.value : 100} />
        <Box
          top={2}
          left={2}
          bottom={2}
          right={2}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="#000"
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const handleCloseAnexosImagem = () => {
    toggleAnexosImagem();
  }

  useEffect(() => {
    if (idObraMedicao) {
      dispatch(obraMedicaoAction.getObraMedicaoById(idObraMedicao))
    }
  }, [])

  const handleOpenImagem = (link) => {
    window.open(link);
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          size="small"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader
            style={{ marginTop: 0, paddingTop: 0, marginBottom: 0, paddingBottom: 0 }}
            title={<Typography variant="h6">
              <b>{'Detalhes da medição'}</b>
            </Typography>}
          />
          <CardContent>
            <TableContainer>
              <Table style={{ marginBottom: 8 }}>
                <TableHead>
                  <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell size="small">
                      <strong>DATA/HORA</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>TÍTULO</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>USUÁRIO</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>SITUAÇÃO</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {medicao && undefined != medicao && medicao != null ?
                    <>
                      <TableRow hover key={medicao.id}>
                        <TableCell component="th" scope="row">
                          {medicao.data ? moment(medicao.data).format("DD/MM/YYYY HH:mm") : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {medicao.titulo ? medicao.titulo : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {medicao.usuario ? medicao.usuario.nome + ' ' + medicao.usuario.sobrenome : ''}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {medicao.situacao == 1 ? 'APROVADO' : medicao.situacao == 2 ? 'RECUSADO' : 'PENDENTE DE ANÁLISE'}
                        </TableCell>
                      </TableRow>
                      {medicao.situacao != null &&
                        <>
                          <TableRow style={{ backgroundColor: '#f2f2f2' }} key={medicao.id}>
                            <TableCell size="small" align="left" fullWidth colSpan={2}>
                              <strong>TÍTULO</strong>
                            </TableCell>
                            <TableCell size="small" align="left" fullWidth>
                              <strong>MOTIVO DA {medicao.situacao == 1 ? 'APROVAÇÃO' : 'RECUSA'}</strong>
                            </TableCell>
                          </TableRow>
                          <TableRow hover key={medicao.id}>
                            <TableCell component="th" scope="row" colSpan={2}>
                              {medicao.titulo ? medicao.titulo.toUpperCase() : 'NÃO INFORMADO'}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {medicao.motivo ? medicao.motivo.toUpperCase() : 'NÃO INFORMADO'}
                            </TableCell>
                          </TableRow>
                        </>
                      }
                    </>
                    :
                    (
                      Array.apply(null, { length: 5 }).map((e, i) => (
                        <TableRow hover>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        </TableRow>
                      ))
                    )
                  }
                </TableBody>
              </Table>
              <Table style={{ marginBottom: 8 }}>
                <TableHead>
                  <TableRow size="small" style={{ marginTop: 5, backgroundColor: '#e6e6e6' }}>
                    <TableCell size="small" align="center" colSpan={3}>
                      <strong>EMPRESA QUE ENVIOU A MEDIÇÃO</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell size="small">
                      <strong>RAZÃO SOCIAL</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>CNPJ</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>TELEFONE</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obrasMedicoesEtapasItens && obrasMedicoesEtapasItens.length > 0 ?
                    obrasMedicoesEtapasItens.map((n) => {
                      // Verificar se a razão social já foi processada
                      if (!razoesSociaisProcessadas.has(n.empresa?.razaoSocial)) {
                        // Adicionar a razão social ao conjunto para evitar repetições
                        razoesSociaisProcessadas.add(n.empresa?.razaoSocial);

                        // Renderizar a linha apenas se a razão social não foi processada antes
                        return (
                          <TableRow hover key={n.id}>
                            <TableCell component="th" scope="row">
                              {n.empresa ? n.empresa.razaoSocial : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.empresa ? cnpjMask(n.empresa.cnpj) : ''}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {n.empresa ? phoneMask(n.empresa.telefone) : ''}
                            </TableCell>
                          </TableRow>
                        );
                      } else {
                        return null; //não retorna nada se a empresa for igual a anterior
                      }
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
              <Table>
                <TableHead>
                  <TableRow size="small" style={{
                    marginTop: 5, backgroundColor: '#e6e6e6'
                  }}
                  >
                    <TableCell size="small" align="center" colSpan={3}>
                      < strong > ITENS</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell size="small">
                      <strong>DESCRIÇÃO</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>QUANTIDADE</strong>
                    </TableCell>
                    <TableCell size="small">
                      <strong>PERCENTUAL CONCLUÍDO</strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obrasMedicoesEtapasItens && obrasMedicoesEtapasItens.length > 0 ?
                    obrasMedicoesEtapasItens.map((n) => {
                      return (
                        <TableRow hover key={n.id} style={{ alignItems: 'center' }}>
                          <TableCell component="th" scope="row">
                            {n.descricao ? n.descricao.toUpperCase() : ''}&nbsp;
                            <IconButton component="a" size="small" onClick={() => handleOpenModalAnexos(n.id, n)}>
                              <Tooltip title='Visualizar anexos'>
                                <AttachmentIcon />
                              </Tooltip>
                            </IconButton>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.quantidade ? n.quantidade : '-'}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <CircularProgressWithLabel value={n.percentual} />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={3} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card >
      </DialogContent >
      {
        (modalAnexos ?
          <ObraMedicaoEditModalAnexo toggle={toggleAnexos} modal={modalAnexos} props={props} idObraMedicaoEtapaItem={idObraMedicaoEtapaItem} />
          :
          null
        )
      }

      <Dialog
        open={modalAnexosImagem}
        fullWidth
        style={{ padding: 0, margin: 0 }}
        maxWidth='sm'
        onClose={toggleAnexosImagem}
      >
        <DialogTitle>
          <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
            <Grid item>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
                ANEXO
              </Typography>
            </Grid>
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
              onClick={() => handleCloseAnexosImagem()}>
              <CloseIcon />
            </Button>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ padding: 0, margin: 0 }}>
          <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item lg={2} md={2} xl={2} xs={12}>               
                  <span style={{ cursor: 'pointer', fontSize: 11, textAlign: 'left' }} onClick={() => handleOpenImagem(obraMedicaoEtapaItem?.obrasMedicoesEtapasItensAnexos[0]?.caminhoArquivo)}>{obraMedicaoEtapaItem?.obrasMedicoesEtapasItensAnexos[0]?.caminhoArquivo}</span>
              </Grid>
            </Grid>
          </Card>
        </DialogContent>
      </Dialog >
    </Dialog >
  )
}
