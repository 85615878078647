import React, { useState, useEffect } from 'react'
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Input
} from '@mui/material'
import { notificacaoAction, notificacaoUsuarioAction } from '_actions'
import { useDispatch, useSelector, connect } from 'react-redux'
import { withStyles } from '@mui/styles'
import SendIcon from '@mui/icons-material/Send';
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 300,
    width: 500,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ModalNotificacaoTestes = (props, { idTipoUsuario }) => {

  const dispatch = useDispatch()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order, setOrder] = useState('nome');
  const [direction, setDirection] = useState('asc');
  const [selectedUsuarios, setSelectedUsuarios] = useState([]);

  const { notificacaoUsuario, totalRegistros, isLoading } = useSelector(
    (state) => state.notificacaoUsuario
  )

  const { openModalNotificacaoTestes } = useSelector(
    (state) => state.notificacao
  )

  useEffect(() => {

  }, [totalRegistros]);

  useEffect(() => {
    dispatch(notificacaoAction.openModalNotificacaoTestes(false))
  }, [])

  useEffect(() => {

  }, [notificacaoUsuario]);

  const fetchData = (page, limit, term) => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdNotificacao: props.notificacao.id
    };

    dispatch(notificacaoUsuarioAction.getNotificacaoUsuario(filterModel, true));

  };

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, []);

  useEffect(() => {
    fetchData(page, limit, debounceTerm, order, direction);
  }, [openModalNotificacaoTestes])

  const handleClose = () => {
    dispatch(notificacaoAction.openModalNotificacaoTestes(false))
  }

  const handleClick = (id) => {
    const isSelected = selectedUsuarios.some((n) => n.idUsuario == id)
    if (isSelected) {
      const newSelected = selectedUsuarios.filter((n) => n.idUsuario !== id)
      setSelectedUsuarios(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idNotificaoTeste: 0,
        idUsuario: id
      }
      setSelectedUsuarios([...selectedUsuarios, newSelecteds])
    }
  }

  useEffect(() => {
    setSelectedUsuarios([])
  }, [openModalNotificacaoTestes]);

  const isItemSelected = (id) => {
    return selectedUsuarios.some((n) => n.idUsuario == id)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleSend = (event) => {
    if (props.notificacao.id) {
      dispatch(notificacaoAction.sendNotificacaoTeste(props.notificacao.id, selectedUsuarios.map((n) => {
        return n.idUsuario
      })))
    }
  }

  return (
    <Dialog
      open={openModalNotificacaoTestes}
      onClose={handleClose}
      aria-labelledby="Notificação de teste"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        Selecione os usuários que receberão a notificação de teste
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <Input
              id="term"
              label="term"
              placeholder="Filtro"
              value={term}
              onChange={handleChange('term')}
            />
          </Grid>
          <br />
          <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'center', alignContent: 'center' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(undefined !== notificacaoUsuario && notificacaoUsuario.length
                    ? notificacaoUsuario.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.nome ? n.usuario.nome : '') : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.usuario ? (n.usuario.email ? n.usuario.email : '') : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Checkbox
                              onClick={() => handleClick(n.idUsuario)}
                              color="primary"
                              checked={isItemSelected(n.idUsuario)}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })
                    : null)
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          style={{ padding: 5 }}
          spacing={2}
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Button onClick={handleClose} variant="outlined"
              style={{ color: '#000', borderColor: '#000' }}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isLoading}
              onClick={() => handleSend()}
              type="submit"
              variant="outlined"
              style={{ color: '#000', borderColor: '#000' }}
              startIcon={<SendIcon size={24} />}
              endIcon={isLoading && <CircularProgress size={24} />}
            >
              {isLoading
                ? 'Enviando...'
                : 'Enviar'}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

ModalNotificacaoTestes.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedModalNotificacaoTestesPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ModalNotificacaoTestes))
)
export { connectedModalNotificacaoTestesPage as ModalNotificacaoTestes }
