import { crudService } from '../_services/'

export const tipoServicoAction = {
  getTiposServicoSelect,
  getTiposServico,
  changeDetailsTipoServico,
}

function getTiposServicoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposServico/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposServicosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTiposServico() {
  return (dispatch) => {
    let apiEndpoint = 'tiposServico'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposServicosList(response.data.items))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposServicosList(tipoServico) {
  return {
    type: 'FETCHED_ALL_TIPO_SERVICO',
    tipoServico: tipoServico
  }
}

export function clear() {
  return {
    type: 'TIPO_SERVICO_CLEAR'
  }
}

function changeDetailsTipoServico(tipoServico) {
  return (dispatch) => {
    if (tipoServico) {
      dispatch(onChangeDetailsTipoServico(tipoServico))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsTipoServico(tipoServico) {
  return [
    {
      type: 'CHANGE_DETAILS_TIPO_SERVICO',
      id: tipoServico.id,
      nome: tipoServico.nome
    }
  ]
}
