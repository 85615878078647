import React, { useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@mui/styles'
import { Avatar, Chip, Typography } from '@mui/material'
import { NavLink as RouterLink } from 'react-router-dom'
import getInitials from 'utils/getInitials'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
    paddingTop: 10,
    paddingBottom: 10
  },
}))

const Profile = (props) => {
  const { user, className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={getInitials(user.nome)}
        style={{ width: 55, height: 55 }}
        component={RouterLink}
        src={user?.imagem}
        to={`/usuario/${user?.id}`}
      >
        {' '}
        {getInitials(user.nome)}
      </Avatar>
      <Typography align="center" style={{ marginTop: 7 }} variant="h6">
        {user.nome}
      </Typography>
      <Typography variant="subtitle2" style={{ fontSize: 12 }} gutterBottom>
        {user.email}
      </Typography>
      <Chip style={{ padding: 0 }} label={
        <Typography variant="overline" style={{ fontSize: 11, padding: 0 }}>
          {user?.idTipoUsuario === 3 ? user?.usuariosEmpresas[0].empresa.nomeFantasia : user?.tipoUsuario?.nome}
        </Typography>}
      />
    </div >
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
