import React, { useState, useEffect } from "react";
import { obraAvaliacaoAction } from '../../_actions';
import { useDispatch } from 'react-redux';

const StarRating = ({ ratingClear, props }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const dispatch = useDispatch();

  const setRatingProps = (index) => {
    setRating(index);

    let changeNotaEvent = {
      type: 'text',
      target: {
        value: index
      }
    };

    dispatch(obraAvaliacaoAction.onChangeProps('nota', changeNotaEvent));
  }

  useEffect(() => {
    if (ratingClear == true) {
      setRating(0)
      setHover(0)

      let changeNotaEvent = {
        type: 'text',
        target: {
          value: 0
        }
      };

      dispatch(obraAvaliacaoAction.onChangeProps('nota', changeNotaEvent));
    }
  }, [ratingClear])


  useEffect(() => {
    if (props.obraAvaliacao.nota != 0) {
      setRating(props.obraAvaliacao.nota)
      setHover(props.obraAvaliacao.nota)
    }

    console.log('props.obraAvaliacao.nota', props.obraAvaliacao.nota)
  }, [props.obraAvaliacao.nota])

  return (
    <div className="star-rating">
      {[...Array(5)].map((star, index) => {
        index += 1;
        return (
          <button
            type="button"
            margin="normal"
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              color: index <= (hover || rating) ? '#e6b800' : '#ccc', // Set color based on condition
              fontSize: 24
            }}
            key={index}
            className={index <= (hover || rating) ? "on" : "off"}
            onClick={() => setRatingProps(index)}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(rating)}
          >
            <span className="star">&#9733;</span>
          </button>
        );
      })}
    </div>
  );
};

export default StarRating;
