// eslint-disable-next-line no-undef
module.exports = {
  headers: {
    accept: 'application/json'
  },
  // baseUrl: 'https://f3a6-177-137-253-157.ngrok-free.app/api/'
  // baseUrl: 'http://localhost:44301/api/'
  baseUrl: 'https://demo.raiarconstrutoraonline.com.br/api/'

}
