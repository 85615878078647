const initialState = {
  loading: false,
  open: false,
  id: 0,
  descricao: '',
  excluido: false
}

export function tabelaInsumo(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TABELA_INSUMO':
      return {
        ...state,
        tabelaInsumo: action.tabelaInsumo
      }   
    default:
      return state
  }
}
