import { crudService } from '../_services/'

export const logAction = {
  getLog,
  getLogByUsuario,
  onChangeProps,
  changeDetailsLog,
  editLogInfo,
  editLogsDetails,
  clear,
  clearAll
}

function getLog() {
  return (dispatch) => {
    let apiEndpoint = 'logs'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeLogsList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getLogByUsuario(idUsuario) {
  return (dispatch) => {
    let apiEndpoint = 'logs/' + idUsuario
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeLogsList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function changeDetailsLog(log) {
  return (dispatch) => {
    if (log) {
      dispatch(onChangeDetailsLog(log))
    } else {
      clear()
    }
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    dispatch(handleOnChangeProps(props, event.target.value))
  }
}

function editLogInfo(id, payload) {
  return (dispatch) => {
    let apiEndpoint = 'logs/' + id
    crudService.put(apiEndpoint, payload).then(() => {
      dispatch(updatedLogInfo())
      dispatch(clear())
    })
  }
}

export function changeLogsList(log) {
  return {
    type: 'FETCHED_ALL_LOG',
    log: log
  }
}

export function clear() {
  return {
    type: 'LOG_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'LOG_CLEAR_ALL'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'LOG_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editLogsDetails(log) {
  return {
    type: 'LOG_DETAIL',
    id: log.id,
    tipo: log.tipo,
    tabela: log.tabela,
    chave: log.chave,
    id_usuario: log.id_usuario,
    dataHora: log.dataHora,
    campo: log.campo,
    valorAntigo: log.valorAntigo,
    valorNovo: log.valorNovo,
    usuario: log.usuario
  }
}

export function updatedLogInfo() {
  return {
    type: 'LOG_UPDATED'
  }
}

export function createLogInfo() {
  return {
    type: 'LOG_CREATED_SUCCESSFULLY'
  }
}

export function deleteLogsDetails() {
  return {
    type: 'DELETED_LOG_DETAILS'
  }
}

export function onChangeDetailsLog(log) {
  return [
    {
      type: 'CHANGE_DETAILS_LOG',
      id: log.id,
      tipo: log.tipo,
      tabela: log.tabela,
      chave: log.chave,
      id_usuario: log.id_usuario,
      dataHora: log.dataHora,
      campo: log.campo,
      valorAntigo: log.valorAntigo,
      valorNovo: log.valorNovo,
      usuario: log.usuario
    }
  ]
}
