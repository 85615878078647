import { crudService } from '../_services/'
import { empresaAction, usuarioAction } from '_actions'

export const usuarioEmpresaAction = {
  changeDetailsUsuarioEmpresa,
  getUsuarioEmpresa,
  getUsuarioEmpresas,
  createUsuarioEmpresa,
  editUsuarioEmpresasDetails,
  deleteUsuarioById,
  clearSelected,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function createUsuarioEmpresa(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuariosEmpresas'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(success(response.data))
          dispatch(notIsLoading())
          dispatch(empresaAction.getEmpresaById(payload.idEmpresa))
          dispatch(clear())
        } else {
          dispatch(clear())
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(clear())
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'USUARIO_EMPRESA_ADD_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'USUARIO_EMPRESA_ADD_FAILURE', error }
  }

  function success(data) {
    return {
      type: 'CREATE_USUARIO_EMPRESA_SUCCESS',
      data: data
    }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuariosEmpresas/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteUsuarioEmpresaDetails())
      dispatch(notIsLoading())
      dispatch(usuarioAction.getUsuario(filterModel))
    })
  }

  function request(id) {
    return { type: 'USUARIO_EMPRESA_DELETE_REQUEST', id }
  }

  function success(data) {
    return {
      type: 'USUARIO_EMPRESA_DELETE_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_EMPRESA_DELETE_FAILURE', error }
  }
}

export function deleteUsuarioEmpresaDetails() {
  return {
    type: 'DELETED_USUARIO_EMPRESA_DETAILS'
  }
}

function changeDetailsUsuarioEmpresa(usuarioEmpresa) {
  return (dispatch) => {
    if (usuarioEmpresa) {
      dispatch(onChangeDetailsUsuarioEmpresa(usuarioEmpresa))
    } else {
      clear()
    }
  }
}

function getUsuarioEmpresas(usuarioEmpresa) {
  return (dispatch) => {
    dispatch(changeUsuarioEmpresasList(usuarioEmpresa))
    if (
      undefined !== usuarioEmpresa &&
      usuarioEmpresa.length === 1
    ) {
      dispatch(editUsuarioEmpresasDetails(usuarioEmpresa[0]))
    }
  }
}

function getUsuarioEmpresa(usuarioEmpresa) {
  return (dispatch) => {
    dispatch(editUsuarioEmpresasDetails(usuarioEmpresa))
  }
}

export function addUsuarioEmpresa(payload) {
  return [
    { type: 'ADD_USUARIO_EMPRESA', usuarioEmpresa: payload },
    clear()
  ]
}

export function changeUsuarioEmpresasList(usuarioEmpresa) {
  return {
    type: 'FETCHED_ALL_USUARIO_EMPRESA',
    usuarioEmpresa: usuarioEmpresa
  }
}

export function clear() {
  return {
    type: 'USUARIO_EMPRESA_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'USUARIO_EMPRESA_CLEAR_ALL'
  }
}

export function clearSelected() {
  return {
    type: 'USUARIO_EMPRESA_CLEAR_SELECTED'
  }
}


export function onChangeDetailsUsuarioEmpresa(usuarioEmpresa) {
  return [
    {
      type: 'CHANGE_DETAILS_USUARIO_EMPRESA',
      id: usuarioEmpresa.id,
      idUsuario: usuarioEmpresa.idUsuario,
      idEmpresa: usuarioEmpresa.idEmpresa,
      excluido: usuarioEmpresa.excluido,
      empresa: usuarioEmpresa.empresa
    },
    empresaAction.getEmpresaById(
      usuarioEmpresa.idEmpresa
    )
  ]
}

export function editUsuarioEmpresasDetails(usuarioEmpresa) {
  return {
    type: 'USUARIO_EMPRESA_DETAIL',
    id: usuarioEmpresa.id,
    idUsuario: usuarioEmpresa.idUsuario,
    idEmpresa: usuarioEmpresa.idEmpresa,
    excluido: usuarioEmpresa.excluido,
    empresa: usuarioEmpresa.empresa
  }
}

export function updatedUsuarioEmpresaInfo() {
  return {
    type: 'USUARIO_EMPRESA_UPDATED'
  }
}

export function createUsuarioEmpresaInfo() {
  return {
    type: 'USUARIO_EMPRESA_CREATED_SUCCESSFULLY'
  }
}

export function isLoading() {
  return {
    type: 'USUARIO_EMPRESA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'USUARIO_EMPRESA_NOTISLOADING'
  }
}
