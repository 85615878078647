const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraMedicaoEtapaItemAnexo: [],
  open: false,
  id: '',
  caminhoArquivo: '',
  idObraMedicaoEtapaItem: 0
}

export function obraMedicaoEtapaItemAnexo(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraMedicaoEtapaItemAnexo.id,
        isLoading: false,
        caminhoArquivo: action.obraMedicaoEtapaItemAnexo.caminhoArquivo,        
        idObraMedicaoEtapaItem: action.obraMedicaoEtapaItemAnexo.idObraMedicaoEtapaItem
      }
    case 'CREATE_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraMedicaoEtapaItemAnexo.id,
        isLoading: false,
        caminhoArquivo: action.obraMedicaoEtapaItemAnexo.caminhoArquivo,        
        idObraMedicaoEtapaItem: action.obraMedicaoEtapaItemAnexo.idObraMedicaoEtapaItem
      }
    case 'EDIT_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_MEDICAO_ETAPA_ITEM_ANEXO':
      return {
        ...state,
        obraMedicaoEtapaItemAnexo: action.obraMedicaoEtapaItemAnexo,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_CLEAR':
      return initialState
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraMedicaoEtapaItemAnexo: [...state.obraMedicaoEtapaItemAnexo, action.data.obraMedicaoEtapaItemAnexo]
      }
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        caminhoArquivo: action.caminhoArquivo,
        idObraMedicaoEtapaItem: action.idObraMedicaoEtapaItem
      }
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
