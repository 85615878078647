import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LinkIcon from '@mui/icons-material/Link';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Tooltip,
  Typography
} from '@mui/material'
import {
  authActions,
  manualAction
} from '../../_actions'
import CloseIcon from '@mui/icons-material/Close';

const HelpModal = ({ modal, toggle, props, tela }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props

  const handleClose = () => {
    toggle();
  }

  const { manual, isLoading } = useSelector(
    (state) => state.manual)

  useEffect(() => {
    dispatch(manualAction.clear());

    let filterModel = {
      Id: tela
    }
    dispatch(manualAction.getManualByTela(filterModel))
  }, [])

  const { user } = useSelector(
    (state) => state.authentication)

  useEffect(() => {
    console.log('manual', manual)
    console.log('user', user)
  }, [manual, user])

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Manuais disponíveis para esta tela'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <CardHeader
            style={{ marginTop: 0, paddingTop: 0 }}
            subheader="Visualização de manuais"
            title={<SegHeader />}
          />
          <Divider />
          <CardContent lg={12} md={12} xl={12} xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Manual</TableCell>
                    {authActions.isGestor() && <TableCell>Tipo(s) de usuário(s)</TableCell>}
                    <TableCell>Link</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    // Exibir skeletons enquanto o carregamento estiver ativo
                    Array.from(new Array(3)).map((_, index) => (
                      <TableRow key={index} hover>
                        <TableCell component="th" scope="row">
                          <Skeleton animation="wave" />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Skeleton animation="wave" />
                        </TableCell>
                        {authActions.isGestor() && (
                          <TableCell component="th" scope="row">
                            <Skeleton animation="wave" />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  ) : (
                    // Renderizar os dados quando não estiver carregando
                    manual && manual.length > 0 ? (
                      manual.map((n) => {
                        // Verifique a condição antes de retornar o JSX
                        if (authActions.isGestor() || (n.tiposUsuariosManuais && n.tiposUsuariosManuais.length > 0 && n.tiposUsuariosManuais.find((u) => u.idTipoUsuario == user?.idTipoUsuario))) {
                          return (
                            <TableRow hover key={n.id}>
                              <TableCell component="th" scope="row">
                                {n.nome ? n.nome.toUpperCase() : ''}
                              </TableCell>
                              {authActions.isGestor() && (
                                <TableCell component="th" scope="row">
                                  <>
                                    <Chip label="ADMINISTRADOR" style={{ color: '#000000', margin: 2 }} />
                                    {n.tiposUsuariosManuais?.map((t) => (
                                      <Chip key={t.tipoUsuario.id} label={t.tipoUsuario.nome} style={{ color: '#000000', margin: 2 }} />
                                    ))}
                                  </>
                                </TableCell>
                              )}
                              <TableCell component="th" scope="row">
                                {n.link ? (
                                  <IconButton
                                    aria-label="Edit"
                                    component="a"
                                    onClick={() => window.open(n.link, '_blank')}
                                    size="small"
                                  >
                                    <Tooltip title={'Visualizar manual'}>
                                      <Tooltip>
                                        <LinkIcon />
                                      </Tooltip>
                                    </Tooltip>
                                  </IconButton>
                                ) : ''}
                              </TableCell>
                            </TableRow>
                          );
                        }
                        // Retorne null se a condição não for satisfeita
                        return null;
                      })
                    ) : manual && manual.length > 0 && (
                      manual.map((n) => n.tiposUsuariosManuais.length == 0) && (
                        <TableRow>
                          <TableCell colSpan={authActions.isGestor() ? 3 : 2} align="center" component="th" scope="row">
                            Nenhum manual localizado
                          </TableCell>
                        </TableRow>
                      )))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </DialogContent>
    </Dialog >
  )
}

export default HelpModal;
