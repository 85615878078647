const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraAnexo: [],
  open: false,
  id: 0,
  idTipoAnexo: 0,
  caminhoAnexo: '',
  idObra: 0,
  obra: null,
  excluido: false
}

export function obraAnexo(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraAnexo.id,
        isLoading: false,
        idTipoAnexo: action.obraAnexo.idTipoAnexo,
        caminhoAnexo: action.obraAnexo.caminhoAnexo,
        idObra: action.obraAnexo.idObra,
        obra: action.obraAnexo.obra,
        excluido: action.obraAnexo.excluido,
        obraAnexo: action.obraAnexo.obraAnexo
      }
    case 'CREATE_OBRA_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraAnexo.id,
        isLoading: false,
        idTipoAnexo: action.obraAnexo.idTipoAnexo,
        caminhoAnexo: action.obraAnexo.caminhoAnexo,
        idObra: action.obraAnexo.idObra,
        obra: action.obraAnexo.obra,
        excluido: action.obraAnexo.excluido,
        obraAnexo: action.obraAnexo.obraAnexo
      }
    case 'EDIT_OBRA_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_ANEXO':
      return {
        ...state,
        obraAnexo: action.obraAnexo,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_ANEXO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idTipoAnexo: action.idTipoAnexo,
        caminhoAnexo: action.caminhoAnexo,
        idObra: action.idObra,
        excluido: action.excluido,
        obra: action.obra,
        obraAnexo: action.obraAnexo
      }
    case 'OBRA_ANEXO_CLEAR':
      return initialState
    case 'OBRA_ANEXO_CLEAR_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: 0,        
        idObra: 0,
        caminhoAnexo: '',
        idTipoAnexo: 0,
        obra: null
      }
    case 'OBRA_ANEXO_ADD_SUCCESS':
      return {
        ...state,
        obraAnexo: [...state.obraAnexo, action.data.obraAnexo]
      }
    case 'OBRA_ANEXO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ANEXO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_ANEXO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
