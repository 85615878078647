import React from 'react';
import ImageUploading from 'react-images-uploading';
import { styled } from '@mui/material/styles';
import CustomButton from '../../components/CustomButton/CustomButton'
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone'
import {
  Avatar,
  Typography,
  Tooltip,
  Card,
  CardContent,
  CardActions,
  Divider
} from '@mui/material';

const AvatarStyled = styled(Avatar)({
  height: 300,
  width: 500,
  borderRadius: '10px',
  webkitBorderRadius: '10px',
  mozBorderRadius: '10px',
  cursor: 'pointer'
})

const ImageUploader = ({ isObra, disabled, images, onChangeImage, onError, acceptType, maxFileSize, resolutionWidth, resolutionHeight, resolutionType, removeImage, props, imagem, getInitials, classes, rest, className, isBanner }) => {
  function bytesToMB(bytes) {
    return bytes / (1024 * 1024);
  }

  return (
    <>
      <ImageUploading
        maxFileSize={maxFileSize}
        onError={onError}
        acceptType={acceptType}
        value={images}
        disabled={disabled}
        onChange={onChangeImage}
        dataURLKey="data_url"
        resolutionWidth={resolutionWidth}
        resolutionHeight={resolutionHeight}
        resolutionType={resolutionType}
      >
        {({ onImageUpload, isDragging, dragProps }) => (
          <Card {...rest} className={classes.root} style={{ marginBottom: 10 }}>
            <CardContent {...dragProps} className={classes.content}>
              {isDragging ? (
                <>
                  <CloudUploadTwoToneIcon
                    color="primary"
                    style={{ fontSize: 80 }}
                  />
                  <Typography variant="h3" color="initial">
                    Solte a imagem aqui!
                  </Typography>
                </>
              ) : (
                <>
                  <Tooltip
                    title={
                      props != null &&
                        imagem != null &&
                        imagem != '' &&
                        !disabled
                        ? 'Clique para trocar a imagem!' :
                        !disabled &&
                        'Clique para adicionar uma imagem!'
                    }
                  >
                    <AvatarStyled
                      key={(imagem != '' && imagem != null ? imagem.substr(imagem.lastIndexOf('/'), imagem.length - imagem.lastIndexOf('/')) : '')}
                      onClick={!disabled && onImageUpload}
                      variant="rounded"
                      disabled={disabled}
                      className={classes.avatar}
                      //remover cache = new Date().getTime()} 
                      src={imagem}
                    >
                      {props.nome != null ? getInitials(props.nome) : getInitials(props.titulo)}
                    </AvatarStyled>
                  </Tooltip>
                  <Typography
                    align="center"
                    className={classes.nome}
                    gutterBottom
                    variant="h3"
                  >
                    {/*{produto.nome}*/}
                  </Typography>
                </>
              )}
            </CardContent>
            {!disabled &&
              <>
                <Divider />
                <CardActions style={{
                  justifyContent: 'center'
                }} align="center">
                  <CustomButton size="small" variant="outlined" style={{ justifyContent: 'center' }} onClick={onImageUpload}>
                    {!isObra ? props && imagem ? 'Trocar imagem' : 'Carregar imagem' :
                      props && imagem ? 'Trocar foto da placa da obra' : 'Carregar placa da obra'}
                  </CustomButton>
                  {props && imagem && (
                    <CustomButton size="small" variant="outlined" onClick={removeImage}>
                      {!isObra ? 'Remover imagem' : 'Remover placa da obra'}
                    </CustomButton>
                  )}
                </CardActions>
              </>
            }
          </Card>
        )}
      </ImageUploading >
      <Typography variant="subtitle2" color="#BDBDBD">
        Tamanho máximo da imagem para upload: {bytesToMB(maxFileSize).toFixed(2)} MB.
      </Typography>
      <Typography variant="subtitle2" color="#BDBDBD">
        Resolução máxima: {resolutionWidth}x{resolutionHeight}px.
      </Typography>
      <Typography variant="subtitle2" color="#BDBDBD">
        Formatos aceitos: {acceptType.join(', ').toUpperCase()}
      </Typography>
    </>
  );
}

export default ImageUploader;
