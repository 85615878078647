import {
  Button,
  Card,
  CircularProgress,
  DialogContent,
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import { useConfirm } from 'material-ui-confirm'
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import DeleteIcon from '@mui/icons-material/Delete'
import React, { useEffect, useState } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux';
import SearchIcon from '@mui/icons-material/Search';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { toast } from 'react-toastify';
import { stringUtils } from '../../utils';
import { obraAction, obraCoordenadaAction } from '../../_actions';
import CustomButton from '../CustomButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { editObrasDetails } from '../../_actions/obra.actions';

const DialogCustom = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    height: 590,
    overflow: 'hidden'
  },
}));

const CustomDeleteIcon = styled(DeleteIcon)(() => ({
  color: '#4f4f4f',
  fontSize: 21,
  marginLeft: 2
}));

const MapContainerCoordenadasModal = ({ toggle, modal, props }) => {

  const { classes, className, obra, google } = props;
  const [, forceUpdate] = React.useState(0);
  const confirm = useConfirm()

  const dispatch = useDispatch();

  const pinBlue = require('../../img/pinBlue.png');

  const [endereco, setEndereco] = useState('');

  const [mapProps, setMapProps] = useState({});

  useEffect(() => {
    if (props.latitude && props.longitude) {
      const setMarker = (marker) => {
        setMapProps({
          region: {
            lat: props.latitude,
            lng: props.longitude
          },
          marker: marker,
          showInfoWindow: false
        });
      };
      setMarker();
    }
    // dispatch(editObrasDetails(propsMarker.obra));
  }, []);

  const handleClose = () => {
    toggle();
  }

  const onMarkerClick = (propsMarker, marker) => {
    setMapProps({
      ...mapProps,
      showInfoWindow: false,
      selectedPlace: propsMarker,
      activeMarker: marker,
    });
  };

  const getLatitudeLongitude = () => {

    if (endereco) {
      const geocoder = new props.google.maps.Geocoder();

      geocoder.geocode({ address: endereco }, (response) => {
        if (response?.length) {
          let changeLatEvent = {
            type: 'text',
            target: {
              value: response[0]?.geometry?.location?.lat()
            }
          };
          dispatch(obraAction.onChangeProps('latitude', changeLatEvent));

          let changeLngEvent = {
            type: 'text',
            target: {
              value: response[0]?.geometry?.location?.lng()
            }
          };
          dispatch(obraAction.onChangeProps('longitude', changeLngEvent));
        } else if (response?.status === 'REQUEST_DENIED') {
          toast.error('Ocorreu um problema ao realizar a busca do endereço')
        } else {
          toast.error('O endereço informado não foi localizado')
        }
      });
    } else {
      toast.error('Digite um endereço')
    }
  }

  const handleAddCoordenada = () => {

    if (endereco) {
      if (props.obra.obrasCoordenadas.find(f => f.latitude == props.latitude && f.longitude == props.longitude) == undefined) {
        let allCoordenadas = [
          ...props.obra.obrasCoordenadas,
          {
            id: props.id,
            indice: props.obra.obrasCoordenadas.length + 1,
            idObra: obra.id,
            latitude: props.latitude,
            longitude: props.longitude,
          }
        ];

        let changeCoordenadaEvent = {
          type: 'text',
          target: {
            value: allCoordenadas
          }
        };

        changeMapProps();

        dispatch(obraAction.onChangeProps('obrasCoordenadas', changeCoordenadaEvent));
        setEndereco('');
      } else {
        toast.error('Selecione um ponto diferente')
      }
    } else {
      toast.error('Digite um endereço')
    }
  }

  const handleClean = () => {
    setEndereco('');
  }

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.obra.obrasCoordenadas])

  const handleSaveCoordenadas = () => {
    props.obra.obrasCoordenadas.map((n) => {
      dispatch(obraCoordenadaAction.createObraCoordenada(n))
      toggle();
    });
  }

  const handleRemoveOnList = (indice, id) => {
    confirm({
      title: 'Você deseja excluir esta coordenada?',
      description: 'Essa operação desvinculará a localização da obra',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Excluindo coordenada...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraCoordenadaAction.deleteObraCoordenadaById(id));
    })
  };

  const changeMapProps = (newProps) => {
    setMapProps({
      ...mapProps,
      ...newProps,
    });
  };

  const handleMarkerDrag = (map, marker, coordIndex) => {
    const geocoder = new props.google.maps.Geocoder();

    // Ensure that map.position is defined before accessing its properties
    geocoder.geocode({ location: { lat: marker.latLng.lat(), lng: marker.latLng.lng() } }, (response) => {
      if (response?.length) {
        //console.log('response', response)

        setEndereco(response[0].formatted_address.toUpperCase());

        // Update the specific coordinate in obrasCoordenadas array
        const updatedCoordenadas = props.obra.obrasCoordenadas.map((oc, index) => {
          if (index + 1 == coordIndex.label) {
            return {
              ...oc,
              latitude: marker.latLng.lat(),
              longitude: marker.latLng.lng(),
            };
          }
          return oc;
        });

        // Dispatch the updated coordinates to the store
        let changeCoordenadaEvent = {
          type: 'text',
          target: {
            value: updatedCoordenadas,
          },
        };
        dispatch(obraAction.onChangeProps('obrasCoordenadas', changeCoordenadaEvent));

        changeMapProps()
      } else {
        toast.error('Ocorreu um problema ao utilizar esta localização');
      }
    });
    // Add an else block or handle the case where map.position is not defined
  };



  function isEditable(e) {
    if (e == '2' || e == '3' || e == '4' || e == '5' || e == '6') {
      return false
    }
    else return true
  }

  return (
    <DialogCustom
      open={modal}
      fullWidth
      maxWidth='lg'
      onClose={toggle}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              <strong>Coordenadas</strong>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              size="small"
              style={{ backgroundColor: '#000' }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="left" alignItems="center">
          <Grid item lg={8} md={8} xl={8} xs={12}>
            <TextValidator
              className={classes.textField}
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              id="endereco"
              label="Endereço"
              disabled={isEditable(obra.ultimoEvento) == false}
              margin="normal"
              onInput={stringUtils.toInputUppercase}
              onChange={(event) => setEndereco(event.target.value)}
              value={endereco}
              variant="outlined"
            />
          </Grid>
          <Grid item lg={2} md={2} xl={2} xs={12}>
            <CustomButton
              variant="outlined"
              onClick={getLatitudeLongitude}
              style={{ marginTop: 10, height: '3.5rem' }}
              disabled={props.obra.isLoading}
              startIcon={<SearchIcon />}
              endIcon={props.obra.isLoading && <CircularProgress size={24} />}
            >
              {props.obra.isLoading ? 'Pesquisando...' : 'Pesquisar'}
            </CustomButton>
          </Grid>
          <Grid item lg={2} md={2} xl={2} xs={12}>
            <CustomButton
              variant="outlined"
              onClick={handleAddCoordenada}
              style={{ marginTop: 10, height: '3.5rem', justifyContent: 'left' }}
              disabled={props.obra.isLoading}
              startIcon={<AddCircleOutlineIcon />}
              endIcon={props.obra.isLoading && <CircularProgress size={24} />}
            >
              {props.obra.isLoading ? 'Adicionando...' : 'Adicionar'}
            </CustomButton>
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Grid item lg={6} md={6} xl={6} xs={12}>
              <Map
                google={props.google}
                zoom={15}
                style={{ width: '50%', height: 400, marginLeft: 7, position: 'relative' }}
                center={mapProps.region}
                initialCenter={mapProps.region}
              >
                {props.obra.obrasCoordenadas && props.obra.obrasCoordenadas.map((oc) => (
                  <Marker
                    name={props.obra.titulo}
                    title={props.obra.titulo}
                    obra={props.obra}
                    label={`${oc.indice}`}  // Ensure label is a string
                    key={oc.indice}
                    draggable
                    labelStyle={{ backgroundColor: "yellow", fontSize: "32px", padding: "16px" }}
                    onDragend={(t, map, coordIndex) => handleMarkerDrag(t, coordIndex, map)}
                    position={{ lat: oc.latitude, lng: oc.longitude }}
                    onClick={onMarkerClick}
                    icon={pinBlue}
                  />
                ))}
              </Map>
            </Grid>
            <Grid item lg={6} md={6} xl={6} xs={12} align="center">
              <TableContainer style={{ marginLeft: 10 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell hover>
                        <Typography align="center">
                          <strong>Coordenadas já cadastradas para a obra</strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {props.obra.obrasCoordenadas && props.obra.obrasCoordenadas != null ?
                    props.obra.obrasCoordenadas.map(oc => {
                      console.log('OC => ', oc)
                      return (
                        <TableRow>
                          <TableCell>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography align="center">
                                <b>{oc.indice ? oc.indice : ''}</b>&nbsp;-&nbsp;
                                ({oc.latitude ? oc.latitude.toFixed(4) : null},&nbsp;
                                {oc.longitude ? oc.longitude.toFixed(4) : null})
                              </Typography>
                              <IconButton onClick={() => handleRemoveOnList(oc.indice, oc.id)} style={{ verticalAlign: 'middle' }}>
                                <CustomDeleteIcon size="small" style={{ padding: 0 }} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    })
                    :
                    null
                  }
                </Table>
              </TableContainer>
              {props.obra.obrasCoordenadas && props.obra.obrasCoordenadas != null && props.obra.obrasCoordenadas.length > 0 ?
                <>
                  <CustomButton
                    variant="outlined"
                    onClick={handleSaveCoordenadas}
                    style={{ marginTop: 10, justifyContent: 'center' }}
                    disabled={props.obra.isLoading}
                    startIcon={<SaveIcon />}
                    endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                  >
                    {props.obra.isLoading ? 'Salvando...' : 'Salvar todas'}
                  </CustomButton>
                  <CustomButton
                    variant="outlined"
                    onClick={handleClean}
                    style={{ marginTop: 10, justifyContent: 'center' }}
                    disabled={props.obra.isLoading}
                    startIcon={<CleaningServicesIcon />}
                    endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                  >
                    {props.obra.isLoading ? 'Limpando...' : 'Limpar'}
                  </CustomButton>
                </>

                : null
              }
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </DialogCustom >
  );

};

export default GoogleApiWrapper((props) => ({
  apiKey: 'AIzaSyC1QwWDv0BApsU0gjwIqEgmns_8RX2s-AU',
}))(MapContainerCoordenadasModal);

