import { crudService } from '../_services/'
import { stringUtils } from 'utils'

export const tipoUsuarioAction = {
  getTiposUsuarioSelect,
  clear
}

function getTiposUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposUsuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposUsuariosList(tipoUsuario) {
  return {
    type: 'FETCHED_ALL_TIPO_USUARIO',
    tipoUsuario: tipoUsuario
  }
}

export function isLoading() {
  return {
    type: 'TIPO_USUARIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'TIPO_USUARIO_NOTISLOADING'
  }
}

export function clear() {
  return {
    type: 'TIPO_USUARIO_CLEAR'
  }
}
