const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraEtapaItem: [],
  open: false,
  id: 0,
  descritivo: '',
  codigo: '',
  idObraEtapa: 0,
  idUnidade: 0,
  quantidade: 0,
  idTipoServico: 0,
  tabelaInsumo: [],
  valorReferencia: '',
  valorReferenciaComBdi: '',
  idTabelaInsumo: 1,
  idEstadoTabelaInsumo: 0,
  anoTabelaInsumo: 0,
  mesTabelaInsumo: 0,
  onerado: false,
  obraEtapa: null
}

export function obraEtapaItem(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_ETAPA_ITEM_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_ETAPA_ITEM_SUCCESS':
      return {
        ...state,
        id: action.obraEtapaItem.id,
        isLoading: false,
        descritivo: action.obraEtapaItem.descritivo,
        codigo: action.obraEtapaItem.codigo,
        idObraEtapa: action.obraEtapaItem.idObraEtapa,
        idUnidade: action.obraEtapaItem.idUnidade,
        quantidade: action.obraEtapaItem.quantidade,
        idTipoServico: action.obraEtapaItem.idTipoServico,
        valorReferencia: action.obraEtapaItem.valorReferencia,
        valorReferenciaComBdi: action.obraEtapaItem.valorReferenciaComBdi,
        tabelaInsumo: action.obraEtapaItem.tabelaInsumo,
        idTabelaInsumo: action.obraEtapaItem.idTabelaInsumo,
        idEstadoTabelaInsumo: action.obraEtapaItem.idEstadoTabelaInsumo,
        anoTabelaInsumo: action.obraEtapaItem.anoTabelaInsumo,
        mesTabelaInsumo: action.obraEtapaItem.mesTabelaInsumo,
        onerado: action.obraEtapaItem.onerado,
        obraEtapa: action.obraEtapaItem.obraEtapa
      }
    case 'CREATE_OBRA_ETAPA_ITEM_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_ETAPA_ITEM_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_ETAPA_ITEM_SUCCESS':
      return {
        ...state,
        id: action.obraEtapaItem.id,
        isLoading: false,
        descritivo: action.obraEtapaItem.descritivo,
        codigo: action.obraEtapaItem.codigo,
        idObraEtapa: action.obraEtapaItem.idObraEtapa,
        idUnidade: action.obraEtapaItem.idUnidade,
        quantidade: action.obraEtapaItem.quantidade,
        idTipoServico: action.obraEtapaItem.idTipoServico,
        tabelaInsumo: action.obraEtapaItem.tabelaInsumo,
        idEstadoTabelaInsumo: action.obraEtapaItem.idEstadoTabelaInsumo,
        valorReferencia: action.obraEtapaItem.valorReferencia,
        valorReferenciaComBdi: action.obraEtapaItem.valorReferenciaComBdi,
        idTabelaInsumo: action.obraEtapaItem.idTabelaInsumo,
        idEstadoTabelaInsumo: action.obraEtapaItem.idEstadoTabelaInsumo,
        anoTabelaInsumo: action.obraEtapaItem.anoTabelaInsumo,
        mesTabelaInsumo: action.obraEtapaItem.mesTabelaInsumo,
        onerado: action.obraEtapaItem.onerado,
        obraEtapa: action.obraEtapaItem.obraEtapa
      }
    case 'EDIT_OBRA_ETAPA_ITEM_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_ETAPA_ITEM':
      return {
        ...state,
        obraEtapaItem: action.obraEtapaItem,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_ETAPA_ITEM_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        descritivo: action.descritivo,
        codigo: action.codigo,
        idObraEtapa: action.idObraEtapa,
        idUnidade: action.idUnidade,
        quantidade: action.quantidade,
        idTipoServico: action.idTipoServico,
        tabelaInsumo: action.tabelaInsumo,
        valorReferencia: action.valorReferencia,
        valorReferenciaComBdi: action.valorReferenciaComBdi,
        idTabelaInsumo: action.idTabelaInsumo,
        idEstadoTabelaInsumo: action.idEstadoTabelaInsumo,
        anoTabelaInsumo: action.anoTabelaInsumo,
        mesTabelaInsumo: action.mesTabelaInsumo,
        onerado: action.onerado,
        obraEtapa: action.obraEtapa
      }
    case 'OBRA_ETAPA_ITEM_CLEAR':
      return initialState
    case 'OBRA_ETAPA_ITEM_CLEAR_DETAIL':
      return {
        ...state,
        id: 0,
        isLoading: false,
        descritivo: '',
        codigo: '',
        idObraEtapa: 0,
        idUnidade: 0,
        quantidade: '',
        idTipoServico: '',
        valorReferencia: '',
        valorReferenciaComBdi: '',
        idTabelaInsumo: 0,
        idEstadoTabelaInsumo: 0,
        anoTabelaInsumo: '',
        mesTabelaInsumo: '',
        onerado: false,
        obraEtapa: null
      }
    case 'OBRA_ETAPA_ITEM_CLEAR_CAMPOS':
      return {
        ...state,        
        isLoading: false,
        descritivo: '',
        codigo: '',
        idUnidade: 0,
        quantidade: '',
        idTipoServico: '',
        valorReferencia: '',
        valorReferenciaComBdi: ''        
      }
    case 'OBRA_ETAPA_ITEM_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraobraEtapaItem: [...state.obraEtapaItem, action.data.obraEtapaItem]
      }
    case 'OBRA_ETAPA_ITEM_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ETAPA_ITEM_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_ETAPA_ITEM_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
