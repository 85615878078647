import { crudService } from '../_services/'
import { empresaAction } from '_actions'
import { toast } from 'react-toastify'

export const empresaOrgaoAction = {
  changeDetailsEmpresaOrgao,
  getEmpresaOrgao,
  getEmpresaOrgaos,
  addUsuarioByEmail,
  editEmpresaOrgaosDetails,
  openModalCadastroEmpresaOrgao,
  openModalCadastroTipoEmpresa,
  deleteUsuarioById,
  clearSelected,
  clear,
  clearAll
}

function addUsuarioByEmail(payload) {
  return (dispatch) => {
    dispatch(request({ payload }))
    let apiEndpoint = 'usuariosEmpresas/email'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        toast.success('Usuário adicionado com sucesso! 😎')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(data) {
    return { type: 'EMPRESA_ORGAO_ADD_REQUEST', data }
  }

  function success(data) {
    return {
      type: 'EMPRESA_ORGAO_ADD_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'EMPRESA_ORGAO_ADD_FAILURE', error }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'usuariosEstabelecimentos/' + id
    crudService
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(id) {
    return { type: 'EMPRESA_ORGAO_DELETE_REQUEST', id }
  }

  function success(data) {
    return {
      type: 'EMPRESA_ORGAO_DELETE_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'EMPRESA_ORGAO_DELETE_FAILURE', error }
  }
}

function changeDetailsEmpresaOrgao(empresaOrgao) {
  return (dispatch) => {
    if (empresaOrgao) {
      dispatch(onChangeDetailsEmpresaOrgao(empresaOrgao))
    } else {
      clear()
    }
  }
}

function getEmpresaOrgaos(empresaOrgao) {
  return (dispatch) => {
    dispatch(changeEmpresaOrgaosList(empresaOrgao))
    if (
      undefined !== empresaOrgao &&
      empresaOrgao.length === 1
    ) {
      dispatch(editEmpresaOrgaosDetails(empresaOrgao[0]))
    }
  }
}

function getEmpresaOrgao(empresaOrgao) {
  return (dispatch) => {
    dispatch(editEmpresaOrgaosDetails(empresaOrgao))
  }
}

export function addEmpresaOrgao(payload) {
  return [
    { type: 'ADD_EMPRESA_ORGAO', empresaOrgao: payload },
    clear()
  ]
}

export function changeEmpresaOrgaosList(empresaOrgao) {
  return {
    type: 'FETCHED_ALL_EMPRESA_ORGAO',
    empresaOrgao: empresaOrgao
  }
}

export function clear() {
  return {
    type: 'EMPRESA_ORGAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'EMPRESA_ORGAO_CLEAR_ALL'
  }
}

export function clearSelected() {
  return {
    type: 'EMPRESA_ORGAO_CLEAR_SELECTED'
  }
}

export function openModalCadastroEmpresaOrgao(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_CADASTRO_EMPRESA_ORGAO',
    open: open
  }
}

export function openModalCadastroTipoEmpresa(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_CADASTRO_TIPO_EMPRESA',
    open: open
  }
}

export function onChangeDetailsEmpresaOrgao(empresaOrgao) {
  return [
    {
      type: 'CHANGE_DETAILS_EMPRESA_ORGAO',
      id: empresaOrgao.id,
      idOrgao: empresaOrgao.idOrgao,
      idEmpresa: empresaOrgao.idEmpresa,
      excluido: empresaOrgao.excluido,
      empresa: empresaOrgao.empresa
    },
    empresaAction.getEmpresaById(
      empresaOrgao.idEmpresa
    )
  ]
}

export function editEmpresaOrgaosDetails(empresaOrgao) {
  return {
    type: 'EMPRESA_ORGAO_DETAIL',
    id: empresaOrgao.id,
    idOrgao: empresaOrgao.idOrgao,
    idEmpresa: empresaOrgao.idEmpresa,
    excluido: empresaOrgao.excluido,
    empresa: empresaOrgao.empresa
  }
}

export function updatedEmpresaOrgaoInfo() {
  return {
    type: 'EMPRESA_ORGAO_UPDATED'
  }
}

export function createEmpresaOrgaoInfo() {
  return {
    type: 'EMPRESA_ORGAO_CREATED_SUCCESSFULLY'
  }
}
