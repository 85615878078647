import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const orgaoAction = {
  getOrgao,
  getOrgaoSelect,
  getOrgaoSelectPublic,
  getOrgaoById,
  onChangeProps,
  editOrgaoInfo,
  editAprovaCadastroOrgao,
  editRecusaCadastroOrgao,
  changeDetailsOrgao,
  createOrgao,
  createOrgaoApp,
  deleteOrgaoById,
  clear,
  clearUsuariosOrgaos,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getOrgao(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'orgaos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeOrgaosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrgaoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'orgaos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrgaosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getOrgaoSelectPublic(filterModelPublic) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = `orgaos/select/map${filterModelPublic}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeOrgaosList(response.data))
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        console.log(err)
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }
}

function createOrgao(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'orgaos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/orgaos')
        } else {
          //     toast.err('Oops! Erro ao cadastrar órgão! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //    toast.err('Oops! Erro ao cadastrar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORGAO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORGAO_FAILURE', error }
  }
}

function createOrgaoApp(payload, user) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'orgaos/orgaoApp'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Órgão cadastrado com sucesso!')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/login')
        } else {
          //     toast.err('Oops! Erro ao cadastrar órgão! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //    toast.err('Oops! Erro ao cadastrar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_ORGAO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_ORGAO_FAILURE', error }
  }
}

function failure(error) {
  return { type: 'EDIT_ORGAO_FAILURE', error }
}

export function clear() {
  return {
    type: 'ORGAO_CLEAR'
  }
}

function editAprovaCadastroOrgao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'orgaos/aprova/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/orgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_ORGAO_FAILURE', error }
  }
}

function editRecusaCadastroOrgao(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'orgaos/recusa/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/orgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao alterar Obra! 😥')
      })
  }
}

function editOrgaoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'orgaos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/orgaos')
        } else {
          //  toast.error('Oops! Erro ao alterar órgão! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.error('Oops! Erro ao alterar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_ORGAO_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_ORGAO_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_ORGAO_FAILURE', error }
  }
}

function getOrgaoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'orgaos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editOrgaosDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsOrgao(orgao) {
  return (dispatch) => {
    if (orgao) {
      dispatch(onChangeDetailsOrgao(orgao))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsOrgao(orgao) {
  return [
    {
      type: 'CHANGE_DETAILS_ORGAO',
      id: orgao.id,
      nome: orgao.nome
    }
  ]
}

function deleteOrgaoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'orgaos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteOrgaosDetails())
      history.push('/orgaos')
      dispatch(getOrgao(filterModel, true))
      dispatch(notIsLoading())
    })
  }
}
export function changeOrgaosList(orgao, totalRows) {
  return {
    type: 'FETCHED_ALL_ORGAO',
    orgao: orgao,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'ORGAO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editOrgaosDetails(orgao) {
  return {
    type: 'ORGAO_DETAIL',
    id: orgao.id,
    isLoading: orgao.isLoading,
    nome: orgao.nome,
    celular: orgao.celular,
    cnpj: orgao.cnpj,
    email: orgao.email,
    endereco: orgao.endereco,
    telefone: orgao.telefone,
    idCidade: orgao.idCidade,
    idEstado: orgao.idEstado,
    excluido: orgao.excluido,
    cidade: orgao.cidade,
    cadastroLiberado: orgao.cadastroLiberado,
    mapaPublico: orgao.mapaPublico,
    naoExigeOrcamentos: orgao.naoExigeOrcamentos,
    usuariosOrgaos: orgao.usuariosOrgaos
  }
}
export function createUserInfo() {
  return {
    type: 'ORGAO_CREATED_SUCCESSFULLY'
  }
}
export function deleteOrgaosDetails() {
  return {
    type: 'DELETED_ORGAO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'ORGAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'ORGAO_NOTISLOADING'
  }
}

export function clearUsuariosOrgaos() {
  return {
    type: 'ORGAO_CLEAR_USUARIOS_ORGAOS'
  }
}


