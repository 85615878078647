import { crudService } from '../_services'
import { toast } from 'react-toastify'
import { history, stringUtils } from 'utils'
import { obraAction } from '_actions'

export const obraCobrancaAction = {
  getObraCobranca,
  getObraCobrancaSelect,
  getObraCobrancaById,
  onChangeProps,
  editObraCobrancaInfo,
  createObraCobranca,
  deleteObraCobrancaById,
  editObraCobrancaPaga,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getObraCobranca(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasCobrancas' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObrasCobrancasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function editObraCobrancaPaga(id, idObra) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasCobrancas/paga/' + id
    crudService
      .put(apiEndpoint)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(obraAction.getObraById(idObra))
          dispatch(getObraCobranca({ IdObra: idObra }))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_COBRANCA_FAILURE', error }
  }
}

function getObraCobrancaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasCobrancas/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObrasCobrancasList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraCobranca(payload, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasCobrancas'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Cadastro realizado com sucesso!')
          dispatch(notIsLoading())
          dispatch(clear())
          history.push('/obrasCobrancas');

          let filterModel = {
            IdOrgao: idOrgao
          };

          dispatch(obraCobrancaAction.getObraCobranca(filterModel, true));

        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        // toast.error('Oops! Erro ao cadastrar ObraCobranca! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_COBRANCA_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_COBRANCA_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_COBRANCA_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_COBRANCA_CLEAR_DETAIL'
  }
}

function editObraCobrancaInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasCobrancas/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          dispatch(clear())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };
          dispatch(obraCobrancaAction.getObraCobranca(filterModel, true));

        } else {
          //  toast.error('Oops! Erro ao alterar ObraCobranca! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar ObraCobranca! 😥')
      })
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_COBRANCA_FAILURE', error }
  }
}

function request(payload) {
  return { type: 'EDIT_OBRA_COBRANCA_REQUEST', payload }
}

function success(user) {
  return {
    type: 'EDIT_OBRA_COBRANCA_SUCCESS',
    user: user
  }
}

function failure(error) {
  return { type: 'EDIT_OBRA_COBRANCA_FAILURE', error }
}


function getObraCobrancaById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasCobrancas/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObrasCobrancasDetails(response.data))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem' || props === 'obraCobrancaItem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraCobrancaById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasCobrancas/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObrasCobrancasDetails())
      dispatch(notIsLoading())

      var ret = JSON.parse(response.request.responseText);
      let filterModel = {
        IdObra: ret.key
      };

      dispatch(obraCobrancaAction.getObraCobranca(filterModel, true));

    })
  }
}
export function changeObrasCobrancasList(obraCobranca, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_COBRANCA',
    obraCobranca: obraCobranca,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_COBRANCA_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObrasCobrancasDetails(obraCobranca) {
  return {
    type: 'OBRA_COBRANCA_DETAIL',
    id: obraCobranca.id,
    dataCobranca: obraCobranca.dataCobranca,
    dataPagamento: obraCobranca.dataPagamento,
    idEmpresa: obraCobranca.idEmpresa,
    caminhoNota: obraCobranca.caminhoNota,
    empresa: obraCobranca.empresa,
    obra: obraCobranca.obra,
    obrasMedicoesCobrancas: obraCobranca.obrasMedicoesCobrancas,
    valor: obraCobranca.valor
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_COBRANCA_CREATED_SUCCESSFULLY'
  }
}
export function deleteObrasCobrancasDetails() {
  return {
    type: 'DELETED_OBRA_COBRANCA_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_COBRANCA_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_COBRANCA_NOTISLOADING'
  }
}
