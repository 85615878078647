const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraAvaliacao: [],
  open: false,
  id: 0,
  descricao: '',
  idObra: 0,
  idOrgao: 0,
  idEmpresa: 0,
  nota: 0,
  obra: null,
  excluido: false
}

export function obraAvaliacao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_AVALIACAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_AVALIACAO_SUCCESS':
      return {
        ...state,
        id: action.obraAvaliacao.id,
        isLoading: false,
        descricao: action.obraAvaliacao.descricao,
        nota: action.obraAvaliacao.nota,
        idObra: action.obraAvaliacao.idObra,
        idOrgao: action.obraAvaliacao.idOrgao,
        idEmpresa: action.obraAvaliacao.idEmpresa,
        obra: action.obraAvaliacao.obra,
        excluido: action.obraAvaliacao.excluido,
        obraAvaliacao: action.obraAvaliacao.obraAvaliacao
      }
    case 'CREATE_OBRA_AVALIACAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_AVALIACAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_AVALIACAO_SUCCESS':
      return {
        ...state,
        id: action.obraAvaliacao.id,
        isLoading: false,
        nota: action.obraAvaliacao.nota,
        descricao: action.obraAvaliacao.descricao,
        idObra: action.obraAvaliacao.idObra,
        idOrgao: action.obraAvaliacao.idOrgao,
        idEmpresa: action.obraAvaliacao.idEmpresa,
        obra: action.obraAvaliacao.obra,
        excluido: action.obraAvaliacao.excluido,
        obraAvaliacao: action.obraAvaliacao.obraAvaliacao
      }
    case 'EDIT_OBRA_AVALIACAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_AVALIACAO':
      return {
        ...state,
        obraAvaliacao: action.obraAvaliacao,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_AVALIACAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        nota: action.nota,
        descricao: action.descricao,
        idObra: action.idObra,
        idOrgao: action.idOrgao,
        idEmpresa: action.idEmpresa,
        excluido: action.excluido,
        obra: action.obra,
        obraAvaliacao: action.obraAvaliacao
      }
    case 'OBRA_AVALIACAO_CLEAR':
      return initialState
    case 'OBRA_AVALIACAO_CLEAR_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: 0,        
        idObra: 0,
        descricao: '',
        idOrgao: 0,
        idEmpresa: 0,
        nota: 0,
        obra: null
      }
    case 'OBRA_AVALIACAO_ADD_SUCCESS':
      return {
        ...state,
        obraAvaliacao: [...state.obraAvaliacao, action.data.obraAvaliacao]
      }
    case 'OBRA_AVALIACAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_AVALIACAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_AVALIACAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
