import React from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Chip } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginLeft: 6,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    backgroundColor: '#015192'
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CardHorasRecebimentoOrcamentos = (props) => {
  const { className, ...rest } = props

  const horasRecebimentoOrcamentos = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.horasRecebimentoOrcamentos : undefined))

  return (
    <Chip
      icon={<AccessTimeIcon />}
      sx={{
        fontSize: 15,
        borderColor: '#015192',
        '& .MuiSvgIcon-root': {
          color: '#FFF'
        },
        backgroundColor: '#015192',
        color: '#FFF',      
      }}
      variant="outlined"
      label={horasRecebimentoOrcamentos ? horasRecebimentoOrcamentos + ' horas para o recebimento de orçamentos' : ''}
    />

  );
}

CardHorasRecebimentoOrcamentos.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardHorasRecebimentoOrcamentos
