export const options =
{
  tooltips: {
    callbacks: {
      label: (tooltipItems, data) => {
        return `${Math.round((tooltipItems.value), 2)}%`
      }
    }
  },
  scales: {
    yAxes: [
      {
        ticks: {
          callback: function (label, index, labels) {
            return `${(label)}%`
          },
          min: 0
        },
      }
    ]
  }
}
