const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraCobranca: [],
  open: false,
  id: 0,
  dataCobranca: '',
  dataPagamento: '',
  idEmpresa: 0,
  caminhoNota: '',
  empresa: [],
  obra: [],
  obrasMedicoesCobrancas: [],
  valor: ''
}

export function obraCobranca(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_COBRANCA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_COBRANCA_SUCCESS':
      return {
        ...state,
        id: action.obraCobranca.id,
        isLoading: false,
        dataCobranca: action.obraCobranca.dataCobranca,
        dataPagamento: action.obraCobranca.dataPagamento,
        idEmpresa: action.obraCobranca.idEmpresa,
        caminhoNota: action.obraCobranca.caminhoNota,
        empresa: action.obraCobranca.empresa,
        obra: action.obraCobranca.obra,
        obrasMedicoesCobrancas: action.obraCobranca.obrasMedicoesCobrancas,
        valor: action.obraCobranca.valor
      }
    case 'CREATE_OBRA_COBRANCA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_COBRANCA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_COBRANCA_SUCCESS':
      return {
        ...state,
        id: action.obraCobranca.id,
        isLoading: false,
        dataCobranca: action.obraCobranca.dataCobranca,
        dataPagamento: action.obraCobranca.dataPagamento,
        idEmpresa: action.obraCobranca.idEmpresa,
        caminhoNota: action.obraCobranca.caminhoNota,
        empresa: action.obraCobranca.empresa,
        obra: action.obraCobranca.obra,
        obrasMedicoesCobrancas: action.obraCobranca.obrasMedicoesCobrancas,
        valor: action.obraCobranca.valor
      }
    case 'EDIT_OBRA_COBRANCA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_COBRANCA':
      return {
        ...state,
        obraCobranca: action.obraCobranca,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_COBRANCA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        dataCobranca: action.dataCobranca,
        dataPagamento: action.dataPagamento,
        idEmpresa: action.idEmpresa,
        caminhoNota: action.caminhoNota,
        empresa: action.empresa,
        obra: action.obra,
        obrasMedicoesCobrancas: action.obrasMedicoesCobrancas,
        valor: action.valor
      }
    case 'OBRA_COBRANCA_CLEAR':
      return initialState
    case 'OBRA_COBRANCA_CLEAR_DETAIL':
      return {
        ...state,
        id: 0,
        isLoading: false,
        dataCobranca: '',
        dataPagamento: '',
        idEmpresa: 0,
        caminhoNota: '',
        empresa: [],
        obra: [],
        obrasMedicoesCobrancas: [],
        valor: ''
      }
    case 'OBRA_COBRANCA_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraCobranca: [...state.obraCobranca, action.data.obraCobranca]
      }
    case 'OBRA_COBRANCA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_COBRANCA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_COBRANCA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
