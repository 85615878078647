import { crudService } from '../_services/'
import { stringUtils } from 'utils'

export const tabelaInsumoMesAction = {
  getMesesImportadosSelect,
  clear
}

function getMesesImportadosSelect(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'tabelasInsumos/select/mes' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTabelasInsumosListMes(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTabelasInsumosListMes(tabelaInsumoMes) {
  return {
    type: 'FETCHED_ALL_TABELA_INSUMO_MES',
    tabelaInsumoMes: tabelaInsumoMes
  }
}

export function clear() {
  return {
    type: 'MES_CLEAR'
  }
}

