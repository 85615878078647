import { crudService } from '../_services/'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const obraAnexoAction = {
  getObraAnexo,
  getObraAnexoPublic,
  getObraAnexoSelect,
  getObraAnexoById,
  onChangeProps,
  editObraAnexoInfo,
  createObraAnexo,
  deleteObraAnexoById,
  clear,
  clearDetail
}


function getObraAnexo(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'obrasAnexos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraAnexosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraAnexoPublic(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'obras/public/anexos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraAnexosList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraAnexoSelect() {
  return (dispatch) => {
    let apiEndpoint = 'obrasAnexos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeObraAnexosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createObraAnexo(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasAnexos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };

          dispatch(getObraAnexo(filterModel, true));
        } else {
          toast.error('Oops! Erro ao cadastrar Anexo! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao cadastrar Anexo! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_OBRA_ANEXO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_OBRA_ANEXO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'OBRA_ANEXO_CLEAR'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_ANEXO_CLEAR_DETAIL'
  }
}

function editObraAnexoInfo(id, payload, redirect) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'obrasAnexos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())

          var ret = JSON.parse(response.request.responseText);
          let filterModel = {
            IdObra: ret.key
          };
          dispatch(getObraAnexo(filterModel, true));

        } else {
          //  toast.error('Oops! Erro ao alterar Obra! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar anexo da Obra! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_OBRA_ANEXO_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_OBRA_ANEXO_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_OBRA_ANEXO_FAILURE', error }
  }
}

function getObraAnexoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'obrasAnexos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editObraAnexosDetails(response.data))

      let filterModel = {
        IdObra: response.data.idObra
      };
      dispatch(getObraAnexo(filterModel, true));
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteObraAnexoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasAnexos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteObraAnexosDetails())
      dispatch(notIsLoading())

      dispatch(notIsLoading())

      var ret = JSON.parse(response.request.responseText);
      let filterModel = {
        IdObra: ret.key
      };

      dispatch(getObraAnexo(filterModel, true));

    })
  }
}
export function changeObraAnexosList(obraAnexo, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_ANEXO',
    obraAnexo: obraAnexo,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_ANEXO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraAnexosDetails(obraAnexo) {
  return {
    type: 'OBRA_ANEXO_DETAIL',
    id: obraAnexo.id,
    idTipoAnexo: obraAnexo.idTipoAnexo,
    caminhoAnexo: obraAnexo.caminhoAnexo,
    idObra: obraAnexo.idObra,
    obra: obraAnexo.obra,
    excluido: obraAnexo.excluido,
    obraAnexo: obraAnexo.obraAnexo
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_ANEXO_CREATED_SUCCESSFULLY'
  }
}
export function deleteObraAnexosDetails() {
  return {
    type: 'DELETED_OBRA_ANEXO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_ANEXO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_ANEXO_NOTISLOADING'
  }
}

