import { crudService } from '../_services/'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const insumoAction = {
  getInsumos,
  clear,
  notIsLoading,
  isLoading
}

function getInsumos(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'insumos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data && response.data.items) {
          dispatch(changeInsumossList(response.data.items, response.data.totalRows))
          dispatch(notIsLoading())
        } else if (response.data) {
          toast.error(response.data);
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        toast.error(err);
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

export function clear() {
  return {
    type: 'INSUMO_CLEAR'
  }
}

export function changeInsumossList(insumo, totalRows) {
  return {
    type: 'FETCHED_ALL_INSUMO',
    insumo: insumo,
    totalRegistros: totalRows
  }
}
export function isLoading() {
  return {
    type: 'INSUMO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'INSUMO_NOTISLOADING'
  }
}
