import { crudService } from '../_services/'

export const obraMedicaoEtapaItemAnexoAction = {
  getObraMedicaoEtapaItemAnexos,
  onChangeProps,
  clear,
  clearDetail,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

export function clear() {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_CLEAR'
  }
}

function getObraMedicaoEtapaItemAnexos(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasMedicoesEtapasItensAnexos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(changeObraMedicoesEtapasItensAnexosList(response.data))
      dispatch(notIsLoading())
    })
      .catch((err) => {
        dispatch(notIsLoading())
      })
  }
}

export function changeObraMedicoesEtapasItensAnexosList(obraMedicaoEtapaItemAnexo) {
  return {
    type: 'FETCHED_ALL_OBRA_MEDICAO_ETAPA_ITEM_ANEXO',
    obraMedicaoEtapaItemAnexo: obraMedicaoEtapaItemAnexo,
  }
}

export function editObraMedicoesEtapaItensAnexosDetails(obraMedicaoEtapaItemAnexo) {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_DETAIL',
    id: obraMedicaoEtapaItemAnexo.id,
    caminhoArquivo: obraMedicaoEtapaItemAnexo.caminhoArquivo,
    idObraMedicaoEtapaItem: obraMedicaoEtapaItemAnexo.idObraMedicaoEtapaItem
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'caminhoImagem' || props == 'obrasMedicoesEtapasItensAnexos') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

export function changeObraMedicaoEtapaItemAnexosList(obraMedicaoEtapaItemAnexo, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_MEDICAO_ETAPA_ITEM_ANEXO',
    obraMedicaoEtapaItemAnexo: obraMedicaoEtapaItemAnexo,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editObraMedicaoEtapaItemAnexosDetails(obraMedicaoEtapaItemAnexo) {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_DETAIL',
    id: obraMedicaoEtapaItemAnexo.id,
    caminhoArquivo: obraMedicaoEtapaItemAnexo.caminhoArquivo,
    idObraMedicaoEtapaItem: obraMedicaoEtapaItemAnexo.idObraMedicaoEtapaItem
  }
}
export function createUserInfo() {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_CREATED_SUCCESSFULLY'
  }
}

export function clearDetail() {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_CLEAR_DETAIL'
  }
}

export function deleteObraMedicaoEtapaItemAnexosDetails() {
  return {
    type: 'DELETED_OBRA_MEDICAO_ETAPA_ITEM_ANEXO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_MEDICAO_ETAPA_ITEM_ANEXO_NOTISLOADING'
  }
}
