import React, { useEffect } from 'react';
import { View, Text, StyleSheet, Image, Font } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  image: {
    padding: 10,
    width: 60
  },
  title: {
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'Roboto-Bold'
  },
  subTitle: {
    fontSize: 12,
    textAlign: 'center',
    marginTop: 10,
    fontFamily: 'Roboto-Regular'
  },
  info: {
    fontSize: 8,
    textAlign: 'center',
    fontFamily: 'Roboto-Regular'
  },
  details: {
    fontSize: 8,
    textAlign: 'left',
    fontFamily: 'Roboto-Regular'
  },
  header: {
    position: 'absolute',
    top: 20,
    left: 20,
    right: 25,
  },
});

const font = require('./fonts/Roboto-Regular.ttf')
const fontBold = require('./fonts/Roboto-Bold.ttf')

Font.register({ family: 'Roboto-Regular', src: font, fontStyle: 'normal', fontWeight: 'normal' });
Font.register({ family: 'Roboto-Bold', src: fontBold, fontStyle: 'normal', fontWeight: 'bold' });

const Header = (props) => {

  return (
    <View style={styles.header}>
      <View style={{ flexDirection: "row", width: "100%" }}>
        <View style={{ alignItems: "flex-start", marginLeft: 5, width: "100%", textAlign: "center" }} >
          <Text style={styles.title}>{props.title}</Text>
          {(props.info != null ? <Text style={styles.info}>{props.info}</Text> : null)}
        </View>
      </View>
    </View>
  )

};

export { Header };
