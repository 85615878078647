import { crudService } from '../_services/'

export const tipoObraAction = {
  getTipoObraSelect
}
function getTipoObraSelect(isObra) {
  return (dispatch) => {
    let apiEndpoint = `tiposObras/select?isObra=${isObra}`
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeTiposObrasList(response.data))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
export function changeTiposObrasList(tipoObra) {
  return {
    type: 'FETCHED_ALL_TIPO_OBRA',
    tipoObra: tipoObra
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
