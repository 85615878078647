import React from 'react';
import clsx from 'clsx'
import Card from '@mui/material/Card';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Avatar, Typography, CardContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';
import theme from './../../../../theme'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginLeft: 6,
    marginRight: 6,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CustomAvatar = styled(Avatar)({
  height: 45,
  width: 45,
  backgroundColor: '#1C5585',
  [theme.breakpoints.down('md')]: {
    height: 35,
    width: 35,
    display: 'flex'
  },
})

const CardAnexosPendentes = (props) => {
  const { className, ...rest } = props
  const classes = useStyles();

  const totalAnexosPendentes = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.totalAnexosPendentes : undefined))

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item styles={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item>
              <Typography variant="h5" className={classes.title}>
                {totalAnexosPendentes ? totalAnexosPendentes : 0}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" gutterBottom>
                Anexos pendentes de aprovação
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <CustomAvatar>
              <AttachFileIcon sx={{ fontSize: 25, color: '#fff' }} />
            </CustomAvatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
}

CardAnexosPendentes.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardAnexosPendentes
