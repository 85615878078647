import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { empresaAnexoAction } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Grid,
  IconButton,
  Input,
  Paper,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const EmpresaAnexo = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className } = props
  const { empresaAnexo } = props.empresaAnexo
  const { totalRegistros } = props.empresaAnexo
  const { isLoading } = props.empresaAnexo

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const isAnexo = true;

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    //Listar apenas anexos pendentes de aprovação
    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      IdOrgao: idOrgao ? idOrgao : 0,
      Situacao: 0
    };

    dispatch(empresaAnexoAction.getEmpresaAnexo(filterModel, true));

  }, []);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      //Listar apenas anexos pendentes de aprovação
      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: idOrgao ? idOrgao : 0,
        Situacao: 0
      };

      dispatch(empresaAnexoAction.getEmpresaAnexo(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir este anexo?',
      description: 'Essa operação é irreversível',
      disabled: props.empresaAnexo.isLoading,
      confirmationText: props.empresaAnexo.isLoading ? 'Excluindo anexo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAnexoAction.deleteEmpresaAnexoById(id))
    })
  }

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  useEffect(() => {

    let filterModel = {
      Page: page,
      Limit: limit,
      Term: term,
      Order: order,
      Direction: direction,
      Situacao: 0
    };

    dispatch(empresaAnexoAction.getEmpresaAnexo(filterModel, true));

  }, []);

  return (
    <Page className={classes.root} title="Anexos">
      <AccessControl
        rule={'empresasAnexos.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== empresaAnexo && empresaAnexo.length > 0 ? (
              <TitleContent
                length={empresaAnexo.length}
                subTitle={'GERÊNCIA DE ANEXOS'}
                title={'Lista de Anexos de Empresas Pendentes de Aprovação'}
                href={'/empresaAnexo'}
              />
            ) : (
              <TitleContent
                length={0}
                subTitle={'GERÊNCIA DE ANEXOS'}
                title={'Lista de Anexos de Empresas Pendentes de Aprovação'}
                href={'/empresaAnexo'}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Tipo de Anexo</TableCell>
                    <TableCell>Data de Validade</TableCell>
                    <TableCell>Situação</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== empresaAnexo && empresaAnexo.length
                    ? empresaAnexo.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.empresa ? n.empresa.razaoSocial?.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipoAnexo ? n.tipoAnexo.nome : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.dataValidade ? moment(n.dataValidade).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.situacao == 1 ? 'APROVADO' : n.situacao == 2 ? 'RECUSADO' : 'PENDENTE DE ANÁLISE'}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'empresasAnexos.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Visualizar"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/empresa/${n.idEmpresa}`, isAnexo)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Visualizar Anexo'}>
                                    <Tooltip >
                                      <VisibilityIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'empresasAnexos.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleClick(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
EmpresaAnexo.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedEmpresaAnexoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmpresaAnexo))
)
export { connectedEmpresaAnexoPage as EmpresaAnexo }
