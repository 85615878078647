import { crudService } from '../_services/'
import { stringUtils } from 'utils'

export const obraOcorrenciasAction = {
  getObraOcorrenciasSistema,
  getObraOcorrencias,
  notIsLoading,
  isLoading
}

function getObraOcorrencias(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOcorrencias' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraOcorrenciasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getObraOcorrenciasSistema() {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'obrasOcorrencias/ultimasOcorrenciasSistema';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeObraOcorrenciasList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

export function changeObraOcorrenciasList(obraOcorrencias, totalRows) {
  return {
    type: 'FETCHED_ALL_OBRA_OCORRENCIAS',
    obraOcorrencias: obraOcorrencias,
    totalRegistros: totalRows
  }
}

export function isLoading() {
  return {
    type: 'OBRA_OCORRENCIAS_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'OBRA_OCORRENCIAS_NOTISLOADING'
  }
}
