const initialState = {
    totalRegistros: 0,
    isLoading: false,
    obraOcorrencias: []
}

export function obraOcorrencias(state = initialState, action) {
    switch (action.type) {
        case 'FETCHED_ALL_OBRA_OCORRENCIAS':
            return {
                ...state,
                obraOcorrencias: action.obraOcorrencias,
                totalRegistros: action.totalRegistros
            }
        case 'OBRA_OCORRENCIAS_ISLOADING':
            return {
                ...state,
                isLoading: true
            }
        case 'OBRA_OCORRENCIAS_NOTISLOADING':
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}
