import { crudService } from '../_services/'
import { orgaoAction, usuarioAction, authActions } from '_actions'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const usuarioOrgaoAction = {
  changeDetailsUsuarioOrgao,
  getUsuarioOrgao,
  getUsuarioOrgaos,
  addUsuarioByEmail,
  createUsuarioOrgao,
  editUsuarioOrgaosDetails,
  deleteUsuarioById,
  clearSelected,
  isLoading,
  notIsLoading,
  clear,
  clearAll
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function createUsuarioOrgao(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuariosOrgaos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(authActions.refresh())
        //  dispatch(getUsuariosOrgaos(filterModel))
          dispatch(orgaoAction.getOrgaoById(payload.idOrgao))
          dispatch(clear())
        } else {
          dispatch(clear())
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(clear())
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function request(payload) {
    return { type: 'USUARIO_ORGAO_ADD_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'USUARIO_ORGAO_ADD_FAILURE', error }
  }

  function success(data) {
    return {
      type: 'CREATE_USUARIO_ORGAO_SUCCESS',
      data: data
    }
  }
}

function addUsuarioByEmail(payload) {
  return (dispatch) => {
    dispatch(request({ payload }))
    let apiEndpoint = 'usuariosOrgaos/email'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        toast.success('Usuário adicionado com sucesso! 😎')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(data) {
    return { type: 'USUARIO_ORGAO_ADD_REQUEST', data }
  }

  function success(data) {
    return {
      type: 'USUARIO_ORGAO_ADD_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_ORGAO_ADD_FAILURE', error }
  }
}

function deleteUsuarioById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuariosOrgaos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteUsuarioOrgaoDetails())
      dispatch(notIsLoading())
      dispatch(usuarioAction.getUsuario(filterModel))     
    })
  }

  function request(id) {
    return { type: 'USUARIO_ORGAO_DELETE_REQUEST', id }
  }

  function success(data) {
    return {
      type: 'USUARIO_ORGAO_DELETE_SUCCESS',
      data: data
    }
  }

  function failure(error) {
    return { type: 'USUARIO_ORGAO_DELETE_FAILURE', error }
  }
}

export function deleteUsuarioOrgaoDetails() {
  return {
    type: 'DELETED_USUARIO_ORGAO_DETAILS'
  }
}

export function changeUsuariosOrgaosList(usuarioOrgao, totalRows) {
  return {
    type: 'FETCHED_ALL_USUARIO_ORGAO',
    usuarioOrgao: usuarioOrgao,
    totalRegistros: totalRows
  }
}

function changeDetailsUsuarioOrgao(usuarioOrgao) {
  return (dispatch) => {
    if (usuarioOrgao) {
      dispatch(onChangeDetailsUsuarioOrgao(usuarioOrgao))
    } else {
      clear()
    }
  }
}

function getUsuariosOrgaos(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuariosOrgaos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosOrgaosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
        dispatch(notIsLoading())
      })
  }
}

function getUsuarioOrgaos(usuarioOrgao) {
  return (dispatch) => {
    dispatch(changeUsuarioOrgaosList(usuarioOrgao))
    if (
      undefined !== usuarioOrgao &&
      usuarioOrgao.length === 1
    ) {
      dispatch(editUsuarioOrgaosDetails(usuarioOrgao[0]))
    }
  }
}

function getUsuarioOrgao(usuarioOrgao) {
  return (dispatch) => {
    dispatch(editUsuarioOrgaosDetails(usuarioOrgao))
  }
}

export function addUsuarioOrgao(payload) {
  return [
    { type: 'ADD_USUARIO_ORGAO', usuarioOrgao: payload },
    clear()
  ]
}

export function changeUsuarioOrgaosList(usuarioOrgao) {
  return {
    type: 'FETCHED_ALL_USUARIO_ORGAO',
    usuarioOrgao: usuarioOrgao
  }
}

export function clear() {
  return {
    type: 'USUARIO_ORGAO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'USUARIO_ORGAO_CLEAR_ALL'
  }
}

export function clearSelected() {
  return {
    type: 'USUARIO_ORGAO_CLEAR_SELECTED'
  }
}


export function onChangeDetailsUsuarioOrgao(usuarioOrgao) {
  return [
    {
      type: 'CHANGE_DETAILS_USUARIO_ORGAO',
      id: usuarioOrgao.id,
      idUsuario: usuarioOrgao.idUsuario,
      idOrgao: usuarioOrgao.idOrgao,
      excluido: usuarioOrgao.excluido,
      orgao: usuarioOrgao.orgao
    },
    orgaoAction.getOrgaoById(
      usuarioOrgao.idOrgao
    )
  ]
}

export function editUsuarioOrgaosDetails(usuarioOrgao) {
  return {
    type: 'USUARIO_ORGAO_DETAIL',
    id: usuarioOrgao.id,
    idUsuario: usuarioOrgao.idUsuario,
    idOrgao: usuarioOrgao.idOrgao,
    excluido: usuarioOrgao.excluido,
    orgao: usuarioOrgao.orgao
  }
}

export function updatedUsuarioOrgaoInfo() {
  return {
    type: 'USUARIO_ORGAO_UPDATED'
  }
}

export function createUsuarioOrgaoInfo() {
  return {
    type: 'USUARIO_ORGAO_CREATED_SUCCESSFULLY'
  }
}

export function isLoading() {
  return {
    type: 'USUARIO_ORGAO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'USUARIO_ORGAO_NOTISLOADING'
  }
}
