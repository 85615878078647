import React, { Component } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  Button,
  Checkbox,
  Container,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { usuarioAction } from '../../_actions'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
    width: theme.spacing(9),
    height: theme.spacing(9)
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: '#ffffff'
  },
  backButton: {
    marginTop: theme.spacing(1),
    color: '#1C5585'
  }
})

class PasswordReset extends Component {
  state = {
    email: '',
    token: '',
    password: '',
    repeatPassword: ''
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search)
    var email = query.toString().substr(6, query.toString().indexOf('&', 0) - 6)
    this.setState({ email: decodeURIComponent(email) })

    var token = query
      .toString()
      .substr(
        query.toString().indexOf('token=', 0) + 6,
        query.toString().length - query.toString().indexOf('token=', 0) - 6
      )
    this.setState({ token: token })

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      if (value !== this.state.password) {
        return false
      }
      return true
    })
  }

  handleChangePassword = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleChangeRepeatPassword = (prop) => (event) => {
    this.setState({ [prop]: event.target.value })
  }

  handleChangeBox = (name) => (event) => {
    this.setState({ ...this.state, [name]: event.target.checked })
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { dispatch } = this.props
    let payload = {
      login: this.state.email,
      senha: this.state.password
    }
    dispatch(usuarioAction.passwordReset(payload, this.state.token))
  }

  render() {
    const { loading } = this.props.usuario
    const { classes } = this.props

    return (
      <Container component={Paper} maxWidth="sm">
        <CssBaseline />
        <div className={classes.paper}>
          <Grid container justifyContent="flex-start">
          </Grid>
          <Typography component="h2" variant="h6" style={{ marginTop: 5 }}>
            Informe e confirme a nova senha.
          </Typography>
          <ValidatorForm
            className={classes.form}
            ref="form"
            onSubmit={this.handleSubmit}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  name="senha"
                  label="Nova Senha *"
                  value={this.state.password}
                  type={this.state.showPassword ? 'text' : 'password'}
                  onChange={this.handleChangePassword('password')}
                  id="senha"
                  validators={['required']}
                  errorMessages={['Opss! Você esqueceu da senha.']}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextValidator
                  variant="outlined"
                  fullWidth
                  name="repeatPassword"
                  label="Repita a Nova Senha *"
                  type={this.state.showPassword ? 'text' : 'password'}
                  value={this.state.repeatPassword}
                  onChange={this.handleChangeRepeatPassword('repeatPassword')}
                  id="repeatPassword"
                  validators={['isPasswordMatch', 'required']}
                  errorMessages={[
                    'As senhas não são iguais!',
                    'Opss! Você esqueceu da senha.'
                  ]}
                />
              </Grid>
              <Grid item justifyContent="start">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={this.handleChangeBox('showPassword')}
                      value="showPassword"
                      color="primary"
                    />
                  }
                  label="Exibir senhas"
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              style={{ backgroundColor: '#1C5585', marginTop: 13, marginBottom: 13 }}
              disabled={loading}
              className={classes.submit}
              endIcon={loading && <CircularProgress size={24} />}
            >
              {loading ? 'Enviando informações...' : 'Alterar'}
            </Button>
          </ValidatorForm>
        </div>
      </Container>
    )
  }
}
PasswordReset.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return state
}
const connectedPasswordResetPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(PasswordReset))
)
export { connectedPasswordResetPage as PasswordReset }
