import { crudService } from '../_services/'
import { history } from '../utils'
import { orgaoAction, empresaAction } from '_actions'
import { authActions } from '../_actions/'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'

export const usuarioAction = {
  getUsuario,
  getUsuarioSelect,
  getUsuarioSemOrgaoAindaSelect,
  getUsuarioMotoristaByOrgaoSelect,
  getUsuarioById,
  getUsuarioByTipo,
  changeDetailsUsuario,
  onChangeProps,
  editUsuarioInfo,
  editUsuarioEmailInfo,
  createUsuario,
  createUsuarioOrgao,
  createUsuarioEmpresa,
  deleteUsuarioById,
  emailVerification,
  requestEmailVerification,
  requestPasswordReset,
  passwordReset,
  openModalRequestPasswordReset,
  setIdUsuarioOrgao,
  verifyUsuarioEmail,
  clear,
  isLoading,
  notIsLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getUsuario(filterModel, showWaiting) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuarios' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioByTipo(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuarios/tipos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeUsuariosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioSelect() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioSemOrgaoAindaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/select/orgao'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getUsuarioMotoristaByOrgaoSelect(idOrgao) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/select/orgao/motoristas/' + idOrgao
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          // response.data.unshift({ value: '', text: ' SELECIONE ...' })
          dispatch(changeUsuariosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function requestEmailVerification(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/request-email-verification'
    crudService
      .post(apiEndpoint, payload)
      .then(() => {
        dispatch(success())
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(payload) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_EMAIL_VERIFICATION_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_EMAIL_VERIFICATION_FAILURE', error }
  }
}

function createUsuario(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          var ret = JSON.parse(response.request.responseText);
          dispatch(clear())
          dispatch(notIsLoading())
          dispatch(setIdUsuarioOrgao(ret.key))
          history.push('/usuarios')
        } else {
          // toast.err('Oops! Erro ao cadastrar usuário! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.err('Oops! Erro ao cadastrar usuário! 😥')
      })
    function request(payload) {
      return { type: 'CREATE_USER_REQUEST', payload }
    }

    function failure(error) {
      return { type: 'CREATE_USER_FAILURE', error }
    }
  }
}

function createUsuarioOrgao(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    dispatch(isLoading())
    let apiEndpoint = 'usuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          var ret = JSON.parse(response.request.responseText);
          dispatch(clear())
          dispatch(notIsLoading())

          dispatch(orgaoAction.getOrgaoById(payload.usuariosOrgaos[0].idOrgao))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.err('Oops! Erro ao cadastrar usuário! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_USER_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_USER_FAILURE', error }
  }
}

function createUsuarioEmpresa(payload) {
  return (dispatch) => {
    dispatch(request(payload))
    dispatch(isLoading())
    let apiEndpoint = 'usuarios'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          var ret = JSON.parse(response.request.responseText);
          dispatch(clear())
          dispatch(notIsLoading())

          dispatch(empresaAction.getEmpresaById(payload.usuariosEmpresas[0].idEmpresa))
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.err('Oops! Erro ao cadastrar usuário! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_USER_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_USER_FAILURE', error }
  }
}

function verifyUsuarioEmail(id, idOrgao) {
  return (dispatch) => {
    dispatch(request(id))
    let apiEndpoint = 'usuarios/verificarEmail/admin/' + id
    crudService
      .post(apiEndpoint)
      .then(() => {
        toast.success('Feito! E-mail verificado com sucesso! 😎')

        let filterModel = {
          IdOrgao: idOrgao,
        }

        dispatch(getUsuario(filterModel))
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }
}

function request(id) {
  return { type: 'EDIT_USUARIO_REQUEST', id }
}

function failure(error) {
  return { type: 'EDIT_USUARIO_FAILURE', error }
}

export function clear() {
  return {
    type: 'USER_CLEAR'
  }
}

function changeDetailsUsuario(usuario) {
  return (dispatch) => {
    if (usuario) {
      dispatch(onChangeDetailsUsuario(usuario))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsUsuario(usuario) {
  return [
    {
      type: 'CHANGE_DETAILS_USUARIO',
      id: usuario.id,
      nome: usuario.nome
    }
  ]
}

function requestPasswordReset(email) {
  let payload = {
    email: email
  }
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(email))
    let apiEndpoint = 'usuarios/request-reset-password'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        dispatch(success(response.data))
        dispatch(notIsLoading())
        if (response && response.request.status === 200) {
          toast.success('E-mail de recuperação enviado!')
        } else if (response && response.request.status === 404) {
          toast.warn('Não existe nenhum usuário com este e-mail')
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(notIsLoading())
        dispatch(failure(err))
      })
  }
  function request(payload) {
    return { type: 'REQUEST_RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'REQUEST_RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'REQUEST_RESET_PASSWORD_FAILURE', error }
  }
}

function setIdUsuarioOrgao(id) {
  return {
    type: 'USUARIO_ORGAO_SET_ID',
    idUsuarioOrgao: id
  }
}

function passwordReset(payload, token) {
  return (dispatch) => {
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/reset-password'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response && response.request.status === 200) {
          dispatch(success(response.data))
          toast.success('Senha redefinida com sucesso! 😎')
          history.push('/password-reset-success')
        } else if (response && response.request.status === 404) {
          //    toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          //   toast.error('Erro ao recuperar senha do usuário')
        }
      })
      .catch((err) => {
        console.log("🚀 ~ return ~ err:", err)
        dispatch(failure(err))
      })
  }
  function request(payload) {
    return { type: 'RESET_PASSWORD_REQUEST', payload }
  }

  function success() {
    return {
      type: 'RESET_PASSWORD_SUCCESS'
    }
  }

  function failure(error) {
    return { type: 'RESET_PASSWORD_FAILURE', error }
  }
}

function emailVerification(payload, token) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/email-verification'
    crudService
      .postWithToken(apiEndpoint, payload, token)
      .then((response) => {
        if (response && response.request.status === 200) {
          toast.success('E-mail verificado com sucesso! 😎')
          history.push('/')
          dispatch(success())
        } else if (response && response.request.status === 404) {
          toast.success('Não existe nenhum usuário com o e-mail informado!')
        } else {
          //   toast.error('Erro ao verificar e-mail do usuário')
        }
      })
      .catch(() => {
        //   toast.error('Erro ao verificar e-mail do usuário')
      })
  }

  function success() {
    return {
      type: 'VERIFICATION_USER_SUCCESS'
    }
  }
}

function editUsuarioInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'usuarios/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          if (!authActions.isFiscal()) {
            history.push('/usuarios')
          } else {
            history.push('/')
          }
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
        toast.error('Oops! Erro ao alterar usuário! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_USER_REQUEST', payload }
  }

  function success(user) {
    return {
      type: 'EDIT_USER_SUCCESS',
      user: user
    }
  }

  function failure(error) {
    return { type: 'EDIT_USER_FAILURE', error }
  }
}

function editUsuarioEmailInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuarios/email/' + id
    crudService.put(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          dispatch(updatedUsuarioEmailInfo())
          dispatch(clear())
          dispatch(authActions.refresh())
          dispatch(notIsLoading())
          history.push('/usuarios')
        }
      }).catch((err) => {
        dispatch(notIsLoading())
        return err.response;
      })
  }
}

export function updatedUsuarioEmailInfo() {
  return {
    type: 'USUARIOEMAIL_UPDATED'
  }
}

function getUsuarioById(id) {
  return (dispatch) => {
    let apiEndpoint = 'usuarios/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editUsuariosDetails(response.data))
    })
  }
}

export function openModalRequestPasswordReset(open) {
  return {
    type: 'OPEN_MODAL_REQUEST_PASSWORD_RESET',
    open: open
  }
}
function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

function deleteUsuarioById(id, idOrgao) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'usuarios/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteUsuariosDetails())
      dispatch(notIsLoading())

      history.push('/usuarios')
      let filterModel = {
        IdOrgao: idOrgao,
      }

      dispatch(getUsuario(filterModel))
    })
  }
}

export function changeUsuariosList(usuario, totalRows) {
  return {
    type: 'FETCHED_ALL_USUARIO',
    usuario: usuario,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'USUARIO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editUsuariosDetails(usuario) {
  return {
    type: 'USUARIO_DETAIL',
    id: usuario.id,
    isLoading: usuario.isLoading,
    email: usuario.email,
    nome: usuario.nome,
    senha: usuario.senha,
    sobrenome: usuario.sobrenome,
    idTipoUsuario: usuario.idTipoUsuario,
    dataHoraUltimoAcesso: usuario.dataHoraUltimoAcesso,
    caminhoCnh: usuario.caminhoCnh,
    celular: usuario.celular,
    cnh: usuario.cnh,
    matricula: usuario.matricula,
    telefone: usuario.telefone,
    idUsuarioOrgao: usuario.idUsuarioOrgao,
    emailVerificado: usuario.emailVerificado
  }
}
export function createUserInfo() {
  return {
    type: 'USUARIO_CREATED_SUCCESSFULLY'
  }
}
export function deleteUsuariosDetails() {
  return {
    type: 'DELETED_USUARIO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'USUARIO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'USUARIO_NOTISLOADING'
  }
}
