const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraOcorrenciaAnexo: [],
  id: '',
  caminhoArquivo: '',
  idObraOcorrencia: 0,
  excluido: false
}

export function obraOcorrenciaAnexo(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_OCORRENCIA_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_OCORRENCIA_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraOcorrenciaAnexo.id,
        isLoading: false,
        caminhoArquivo: action.obraOcorrenciaAnexo.caminhoArquivo,
        idObraOcorrencia: action.obraOcorrenciaAnexo.idObraOcorrencia,
        excluido: action.obraOcorrenciaAnexo.excluido,
      }
    case 'CREATE_OBRA_OCORRENCIA_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_OCORRENCIA_ANEXO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_OCORRENCIA_ANEXO_SUCCESS':
      return {
        ...state,
        id: action.obraOcorrenciaAnexo.id,
        isLoading: false,
        caminhoArquivo: action.obraOcorrenciaAnexo.caminhoArquivo,
        idObraOcorrencia: action.obraOcorrenciaAnexo.idObraOcorrencia,
        excluido: action.obraOcorrenciaAnexo.excluido,
      }
    case 'EDIT_OBRA_OCORRENCIA_ANEXO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_OCORRENCIA_ANEXO':
      return {
        ...state,
        obraOcorrenciaAnexo: action.obraOcorrenciaAnexo,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_OCORRENCIA_ANEXO_CLEAR':
      return initialState
    case 'OBRA_OCORRENCIA_ANEXO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraOcorrenciaAnexo: [...state.obraOcorrenciaAnexo, action.data.obraOcorrenciaAnexo]
      }
    case 'OBRA_OCORRENCIA_ANEXO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        caminhoArquivo: action.caminhoArquivo,
        idObraOcorrencia: action.idObraOcorrencia,
        excluido: action.excluido,
      }
    case 'OBRA_OCORRENCIA_ANEXO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_OCORRENCIA_ANEXO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_OCORRENCIA_ANEXO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
