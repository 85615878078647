import { authServices, crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'

const $package = require('../../package.json')
const versaoSistema = $package?.version || '0.0.0'

export const authActions = {
  login,
  isGestor,
  isPrefeitura,
  isEmpresa,
  isMotorista,
  isFiscal,
  isUsuarioOrgaoPublico,
  refresh,
  logout
}

function login(username, password) {
  return (dispatch) => {
    dispatch(request({ username }))
    let apiEndpoint = 'authentication/login'
    let payload = {
      login: username,
      senha: password,
      admin: true
    }
    authServices
      .post(apiEndpoint, payload)  
      .then((response) => {
        dispatch(success(response.data))
        history.push('/dashboard')
      })
      .catch((err) => {
        dispatch(failure(err))
      })
  }

  function request(user) {
    return { type: 'LOGIN_REQUEST', user }
  }

  function success(user) {
    return {
      type: 'LOGIN_SUCCESS',
      token: user.token,
      expires: user.expires,
      user: user.usuario
    }
  }

  function failure(error) {
    return { type: 'LOGIN_FAILURE', error }
  }
}

function refresh() {
  return (dispatch) => {
    const token = localStorage.getItem('token')

    const payload = {
      admin: true,
      plataforma: navigator?.userAgent,
      versaoSistema: versaoSistema
    }

    if (token !== null && token !== undefined) {
      dispatch(request(token))
      let apiEndpoint = 'authentication/refresh'
      crudService
        .postWithToken(apiEndpoint, payload, token)
        .then((response) => {
          dispatch(success(response.data))
        })
        .catch((err) => {
          dispatch(failure(err))
          dispatch(logout())
        })
    }
  }

  function request(token) {
    return { type: 'AUTHENTICATION_REFRESH_REQUEST', token }
  }

  function success(user) {
    return {
      type: 'AUTHENTICATION_REFRESH_SUCCESS',
      user: user.usuario
    }
  }

  function failure(error) {
    return { type: 'AUTHENTICATION_REFRESH_FAILURE', error }
  }
}

function isGestor() {
  const isGestor = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 1 ? true : false);
  return isGestor;
}

function isMotorista() {
  const isMotorista = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 4 ? true : false);
  return isMotorista;
}

function isPrefeitura() {
  const isPrefeitura = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 2 || JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 6 ? true : false);
  return isPrefeitura;
}

function isFiscal() {
  const isFiscal = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 5 ? true : false);
  return isFiscal;
}

function isEmpresa() {
  const isEmpresa = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 3 ? true : false);
  return isEmpresa;
}

function isUsuarioOrgaoPublico() {
  const isUsuarioOrgaoPublico = (JSON.parse(localStorage.getItem('user'))["idTipoUsuario"] == 6 ? true : false);
  return isUsuarioOrgaoPublico;
}

function logout() {
  return (dispatch) => {
    authServices.logout()
    dispatch(logoutUser())
    history.push('/login')
  }
}
export function setUserDetails(user) {
  return {
    type: 'LOGIN_SUCCESS',
    auth: user.auth,
    token: user.token,
    expires: user.expires,
    user: user.usuario
  }
}
export function logoutUser() {
  return {
    type: 'LOGOUT_SUCCESS'
  }
}
