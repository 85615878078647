export const TIPO_USUARIO = {
  ADMIN: 1,
  GESTOR_ORGAO: 2,
  PRESTADOR: 3,
  MOTORISTA: 4,
  FISCAL: 5,
  USUARIO_ORGAO: 6
};

export const STATUS_SERVICO = {
  LANCAMENTO: null,
  PENDENTE: '1',
  RECEBENDO_ORCAMENTOS: '2',
  ORCAMENTO_APROVADO: '3',
  RECUSADO: '4',
  CONCLUIDO: '5',
  AVALIADO: '6',
  VERIFICADO: '7',
  DESERTO: '8',
  SEM_SUCESSO: '9'
};

export const STATUS_FINANCEIRO_EMPENHO = {
  EM_ABERTO: '1',
  PAGOS: '2',
  TODOS: '3'
};

export const MANUAIS_CATEGORIAS = {
  ABA_CADASTRO_OBRA: 1,
  ABA_EDICAO_OBRA: 2,
  LISTAGEM_ORDEM_SERVICO: 3,
  CADASTRO_ORGAO_ADMIN: 4,
  CADASTRO_ORGAO_ORGAO: 5,
  CADASTRO_EMPRESA_ADMIN: 6,
  CADASTRO_EMPRESA_EMPRESA: 7,
  EDICAO_ORGAO: 8,
  LISTAGEM_ORGAO: 9,
  CADASTRO_SUB_ORGAO: 10,
  EDICAO_SUB_ORGAO: 11,
  LISTAGEM_SUB_ORGAO: 12,
  EDICAO_PARAMETRO: 13,
  EDICAO_EMPRESA: 14,
  CADASTRO_USUARIO: 15,
  EDICAO_USUARIO: 16,
  LISTAGEM_USUARIO: 17,
  LISTAGEM_ORCAMENTO: 18,
  CADASTRO_ORCAMENTO: 19,
  LISTAGEM_EMPENHO: 20,
  CADASTRO_EMPENHO: 21,
  RELATORIO_EMPRESA_ORGAO: 22,
  RELATORIO_SERVICO_REALIZADO: 23,
  CADASTRO_AJUDA: 24,
  EDICAO_AJUDA: 25,
  LISTAGEM_AJUDA: 26,
  ABA_OBRA_FINANCEIRO: 27,
  ABA_OBRA_OCORRENCIA: 28,
  ABA_OBRA_ETAPA: 29,
  ABA_OBRA_ITEM: 30,
  ABA_OBRA_MEDICAO: 31,
  LISTAGEM_MEDICAO: 32,
  RELATORIO_LOGS_SISTEMA: 33,
  RELATORIO_OCORRENCIA_OBRA: 34,
  LISTAGEM_COBRANCA: 35,
  LISTAGEM_EMPRESA: 36,
  EMPRESA_EDIT_ANEXO: 37,
  LISTAGEM_NOTIFICACAO: 38,
  LISTAGEM_PARAMETRO: 39,
  LISTAGEM_OBRA_REFORMA: 40,
  LISTAGEM_OBRA_COMPRA_MATERIAL: 41,
  ABA_CADASTRO_COMPRA_MATERIAL: 42,
  ABA_OBRA_ANEXO: 43,
  ABA_OBRA_ORCAMENTO: 44,
  LISTAGEM_OBRA_AGUARDANDO_ORCAMENTO: 45,
  LISTAGEM_ORCAMENTO_ENVIADO: 46,
  RELATORIO_USUARIO: 47,
  LINK_BDI: 48,
  LINK_CALCMAT: 49,
  LINK_DIARIO_OBRA: 50,
  LINK_GOV: 51,
  DASHBOARD: 52,
  CADASTRO_COBRANCA: 53
};
