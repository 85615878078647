import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import DeleteIcon from '@mui/icons-material/Delete'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import HelpIcon from '@mui/icons-material/Help';
import {
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Tooltip,
  Skeleton,
  Input
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  obraEtapaAction
} from '../../_actions'
import { withRouter, } from 'react-router-dom'
import { stringUtils, MANUAIS_CATEGORIAS } from '../../utils'
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit'
import { useDebounce } from 'use-debounce';
import { AccessControl } from '../../components/Utils/AccessControl'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ObraEtapaEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()

  const duracaoEtapa = useSelector((state) => (state.obraEtapa && !isNaN(state.obraEtapa.duracaoEtapa)) ? state.obraEtapa.duracaoEtapa : 15)

  const { classes, idObra, ...rest } = props
  const { totalRegistros } = props.obraEtapa
  const { isLoading } = props.obra
  const formRef = React.createRef(null);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const { obraEtapa } = useSelector(
    (state) => state.obraEtapa)

  useEffect(() => {
    dispatch(obraEtapaAction.clear())

    if (!props.obraEtapa.id && !props.obraEtapa.dataInicio && props.obra.dataInicio) {
      const fakeEvent = {
        type: 'text',
        target: {
          value: props.obra.dataInicio
        }
      }

      dispatch(obraEtapaAction.onChangeProps('dataInicio', fakeEvent))
    }
  }, []);

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      if (idObra) {
        let filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          IdObra: idObra
        };

        dispatch(obraEtapaAction.getObraEtapa(filterModel, true));
      }
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idObra]);

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [props.obra.isLoading])

  useEffect(() => {
    const newDataTermino = moment(props.obraEtapa.dataInicio).add(duracaoEtapa, 'days').format('YYYY-MM-DD')

    const fakeEvent = {
      type: 'text',
      target: {
        value: newDataTermino
      }
    }
    dispatch(obraEtapaAction.onChangeProps('dataTermino', fakeEvent))
  }, [props.obraEtapa.duracaoEtapa, duracaoEtapa])

  useEffect(() => {
    let duracao = props.obra.id ? moment(props.obraEtapa.dataTermino).diff(props.obraEtapa.dataInicio, 'days') : 15

    const fakeEvent = {
      type: 'text',
      target: {
        value: duracao
      }
    }

    dispatch(obraEtapaAction.onChangeProps('duracaoEtapa', fakeEvent))
  }, [props.obraEtapa.dataTermino])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEdit = (id) => {
    dispatch(obraEtapaAction.getObraEtapaById(id))
  }

  const handleClean = () => {
    dispatch(obraEtapaAction.clearDetail())
  }

  const handleDelete = (id) => {
    confirm({
      title: 'Você deseja excluir esta Obra?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Excluindo Obra...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraEtapaAction.deleteObraEtapaById(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(obraEtapaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: props.obraEtapa.id,
      dataInicio: props.obraEtapa.dataInicio,
      dataTermino: props.obraEtapa.dataTermino != 'Invalid date' ? props.obraEtapa.dataTermino : moment(props.obraEtapa.dataInicio).add(duracaoEtapa, 'days').format('YYYY-MM-DD'),
      idObra: parseInt(idObra),
      descritivo: props.obraEtapa.descritivo,
      titulo: props.obraEtapa.titulo,
      periodos: props.obraEtapa.periodos
    }

    if (props.obraEtapa.id) {
      dispatch(obraEtapaAction.editObraEtapaInfo(props.obraEtapa.id, payload, true))
    } else {
      dispatch(obraEtapaAction.createObraEtapa(payload))
    }

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{props.obra.ultimoEvento == null ? 'Novo Agrupamento' : 'Agrupamentos'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Agrupamento'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (props.obraEtapa.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (

    <Grid item lg={12} md={12} xl={12} xs={12}>
      {props.obra.ultimoEvento == null &&
        <Grid item md={12} xs={12}>
          <ValidatorForm
            className={classes.form}
            ref={formRef}
            id="formEtapa"
            onSubmit={(event) => handleSubmit(event)}
          >
            <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <CardHeader
                subheader="Cadastro de Agrupamentos"
                title={<SegHeader />}
              />
              <Grid item lg={1} md={1} xl={1} xs={12}>
                <IconButton
                  aria-label="Ajuda"
                  component="a"
                  onClick={() =>
                    handleOpenModalHelp()
                  }
                  size="small"
                >
                  <Tooltip title={'Ajuda'}>
                    <Tooltip>
                      <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                    </Tooltip>
                  </Tooltip>
                </IconButton>
              </Grid>
            </Grid>
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="titulo"
                    label="Título *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('titulo')}
                    value={props.obraEtapa.titulo || ''}
                    variant="outlined"
                    validators={['required']}
                    InputLabelProps={{
                      shrink: true
                    }}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    multiline
                    minRows={2}
                    InputLabelProps={{
                      shrink: true
                    }}
                    id="descritivo"
                    label="Descritivo"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('descritivo')}
                    value={props.obraEtapa.descritivo || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    type="date"
                    id="dataInicio"
                    label="Data Início *"
                    margin="normal"
                    onChange={handleChange('dataInicio')}
                    value={props.obraEtapa.dataInicio ? moment(props.obraEtapa.dataInicio).format("yyyy-MM-DD") : ''}
                    variant="outlined"
                    validators={['required']}
                    InputLabelProps={{
                      shrink: true
                    }}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    type="number"
                    //disabled={isEditable(obra.ultimoEvento) == false}
                    id="duracaoEtapa"
                    label="Duração da etapa (em dias)*"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    validators={['minNumber:1']}
                    errorMessages={['O valor mínimo é 1 dia']}
                    onChange={handleChange('duracaoEtapa')}
                    value={props.obraEtapa.duracaoEtapa || duracaoEtapa}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={4} md={4} xl={4} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="periodos"
                    label="Agrupamento Financeiro *"
                    margin="normal"
                    onChange={handleChange('periodos')}
                    value={props.obraEtapa.periodos || ''}
                    variant="outlined"
                    validators={['required']}
                    InputLabelProps={{
                      shrink: true
                    }}
                    errorMessages={['Campo obrigatório!']}
                  />
                  <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                    Separe os períodos de cobrança da obra com ponto e vírgula (;).
                    Lembrando que a soma dos perídos precisa totalizar 100%
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                  * Campos obrigatórios
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} xl={12} xs={12} style={{
                display: 'flex',
                alignItems: 'right',
                flexDirection: 'row',
                textAlgin: 'right',
                justifyContent: 'right'
              }}>
                <CustomButton
                  variant="outlined"
                  onClick={() => handleClean()}
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  dark={true}
                  disabled={props.obraEtapa.isLoading || props.obra.ultimoEvento != null}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraEtapa.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraEtapa.id ? (props.obraEtapa.isLoading ? 'Atualizando Grupo...' : 'Atualizar') : (props.obraEtapa.isLoading ? 'Salvando Grupo...' : 'Salvar')}
                </CustomButton>
              </Grid>
            </CardActions>

          </ValidatorForm>

        </Grid>
      }

      <Grid container spacing={3}>
        <Grid item xl={3} lg={3} md={3} xs={12}>
          <Input
            id="term"
            label="term"
            placeholder="Filtro"
            value={term}
            fullWidth
            onChange={handleChange('term')}
          />
        </Grid>
      </Grid>
      <br />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">Código</TableCell>
              <TableCell align="center">Data Início</TableCell>
              <TableCell align="center">Duração</TableCell>
              <TableCell>Título</TableCell>
              <TableCell align="center">Financeiro</TableCell>
              <TableCell align="right">Valor Referência + BDI</TableCell>
              {(props.obra.ultimoEvento == null) && <TableCell align="center">Ações</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraEtapa && obraEtapa.length
              ? obraEtapa.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row" align="center">
                      {n.id ? n.id : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.dataInicio ? moment(n.dataInicio).format("DD/MM/YYYY") : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {(n.dataTermino && n.dataInicio) ? moment(n.dataTermino).diff(n.dataInicio, 'days') : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.titulo ? n.titulo.toUpperCase() : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.periodos?.length ? `${n.periodos.split(';').length} ${n.periodos.split(';').length > 1 ? 'periodos' : 'periodo'} (${n.periodos.split(';').map(p => p + '%')})` : '-'}
                    </TableCell>
                    <TableCell component="th" scope="row" align="right">
                      {Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                      }).format(n.valorReferenciaComBdi)}
                    </TableCell>
                    {(props.obra.ultimoEvento == null) &&
                      <TableCell align="center">
                        <AccessControl
                          rule={'obrasEtapas.edit'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <IconButton
                              aria-label="Edit"
                              component="a"
                              onClick={() => handleEdit(n.id)}
                              size="small"
                            >
                              <Tooltip title={'Editar'}>
                                <Tooltip >
                                  <EditIcon />
                                </Tooltip>
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                        <AccessControl
                          rule={'obrasEtapas.delete'} //permissão necessária para acessar conteúdo
                          yes={() => (
                            <IconButton
                              aria-label="Delete"
                              onClick={() => handleDelete(n.id)}
                              size="small"
                            >
                              <Tooltip title="Excluir">
                                <DeleteIcon />
                              </Tooltip>
                            </IconButton>
                          )}
                        />
                      </TableCell>
                    }
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  <TableRow hover>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Skeleton animation="wave" />
                    </TableCell>
                  </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_ETAPA}
          />
          :
          null
        )
      }
    </Grid>

  )
}

ObraEtapaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraEtapaEdit))
)
export { connectedObraEditPage as ObraEtapaEdit }
