import { crudService } from '../_services/'
import { stringUtils } from 'utils'

export const tabelaInsumoAnoAction = { 
  getAnosImportadosSelect
}

function getAnosImportadosSelect(filterModel) {
  return (dispatch) => {
    let apiEndpoint = 'tabelasInsumos/select/ano' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTabelasInsumosListAno(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTabelasInsumosListAno(tabelaInsumoAno) {
  return {
    type: 'FETCHED_ALL_TABELA_INSUMO_ANO',
    tabelaInsumoAno: tabelaInsumoAno
  }
}


