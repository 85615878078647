import { crudService } from '../_services/'

export const tabelaInsumoAction = {
  getTabelasInsumosSelect
}

function getTabelasInsumosSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tabelasInsumos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTabelasInsumosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTabelasInsumosList(tabelaInsumo) {
  return {
    type: 'FETCHED_ALL_TABELA_INSUMO',
    tabelaInsumo: tabelaInsumo
  }
}

