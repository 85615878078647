import { crudService } from '../_services'

export const unidadeAction = {
  getUnidadeById,
  getUnidadesSelect
}

function getUnidadesSelect() {
  return (dispatch) => {
    let apiEndpoint = 'unidades/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeUnidadesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}


function getUnidadeById(id) {
  return (dispatch) => {
    let apiEndpoint = 'unidades/' + id
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeUnidadesList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeUnidadesList(unidade) {
  return {
    type: 'FETCHED_ALL_UNIDADE',
    unidade: unidade
  }
}

