const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraCoordenada: [],
  open: false,
  id: 0,
  indice: 0,
  idObra: 0,
  obra: null,
  latitude: '',
  longitude: '',
  excluido: false
}

export function obraCoordenada(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_COORDENADA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_COORDENADA_SUCCESS':
      return {
        ...state,
        id: action.obraCoordenada.id,
        isLoading: false,
        indice: action.obraCoordenada.indice,
        idObra: action.obraCoordenada.idObra,
        obra: action.obraCoordenada.obra,
        latitude: action.obraCoordenada.latitude,
        longitude: action.obraCoordenada.longitude,
        excluido: action.obraCoordenada.excluido,
        obraCoordenada: action.obraCoordenada.obraCoordenada
      }
    case 'CREATE_OBRA_COORDENADA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_COORDENADA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_COORDENADA_SUCCESS':
      return {
        ...state,
        id: action.obraCoordenada.id,
        isLoading: false,
        indice: action.obraCoordenada.indice,
        idObra: action.obraCoordenada.idObra,
        obra: action.obraCoordenada.obra,
        latitude: action.obraCoordenada.latitude,
        longitude: action.obraCoordenada.longitude,
        excluido: action.obraCoordenada.excluido,
        obraCoordenada: action.obraCoordenada.obraCoordenada
      }
    case 'EDIT_OBRA_COORDENADA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_COORDENADA':
      return {
        ...state,
        obraCoordenada: action.obraCoordenada,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_COORDENADA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        indice: action.indice,
        idObra: action.idObra,
        obra: action.obra,
        latitude: action.latitude,
        longitude: action.longitude,
        excluido: action.excluido,
        obraCoordenada: action.obraCoordenada
      }
    case 'OBRA_COORDENADA_CLEAR':
      return initialState
    case 'OBRA_COORDENADA_CLEAR_DETAIL':
      return {
        ...state,
        isLoading: false,
        id: 0,        
        indice: 0,
        idObra: 0,
        obra: null,
        latitude: '',
        longitude: '',
        excluido: false
      }
    case 'OBRA_COORDENADA_ADD_SUCCESS':
      return {
        ...state,
        obraCoordenada: [...state.obraCoordenada, action.data.obraCoordenada]
      }
    case 'OBRA_COORDENADA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_COORDENADA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_COORDENADA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
