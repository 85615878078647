/* eslint-disable import/no-unresolved */
import React, { useState, useEffect } from "react";
import Timeline from "react-timelines";
import { connect, useDispatch } from 'react-redux'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { obraMedicaoAction } from '../../../_actions';
//import { buildTimebar, buildTrack } from "./builders";

import "react-timelines/lib/css/style.css";

const now = new Date();

export const fill = (n) => {
  const arr = [];
  for (let i = 0; i < n; i += 1) {
    arr.push(i);
  }
  return arr;
};

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
})

// eslint-disable-next-line no-alert
const clickElement = (element) =>
  alert(`Clicked element\n${JSON.stringify(element, null, 2)}`);


const TimelineGantt = ({ props, idObra, obra }) => {

  console.log('OBRA', obra)

  const { obraMedicao } = props.obraMedicao
  const [open, setOpen] = useState(false);

  const inicio = new Date(obra?.dataInicio);
  const fim = new Date(obra?.dataTermino);

  const start = new Date(inicio.getFullYear(), inicio.getMonth() + 1, inicio.getDate());
  const end = new Date(fim.getFullYear(), fim.getMonth() + 1, fim.getDate());

  console.log('DATA start', start);
  console.log('DATA end', end);

  const elements = [
    {
      id: `t1`,
      title: "night shift",
      start: new Date('2023-10-26 10:00'),
      end: new Date('2023-11-26 14:00'),
      style: {
        backgroundColor: `#015091`,
        color: "",
        borderRadius: "3px",
        boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
        textTransform: "capitalize"
      }
    }
  ];

  const getEtapas = (obrasEtapas) => {
    try {

      let etapasItens = obrasEtapas.flatMap(n1 => n1.obrasEtapasItens);

      let newArray = etapasItens.map(n => {

        return {
          id: n.id,
          title: n.descritivo,
          elements: n.obrasMedicoesEtapasItens.map(n2 => {
            return {
              id: n2.id,
              title: n2.percentual,
              start: new Date(n2.obraMedicao.data),
              end: new Date(n2.obraMedicao.data + 10),
              style: {
                backgroundColor: `#015091`,
                color: "",
                borderRadius: "3px",
                boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)",
                textTransform: "capitalize"
              }
            }
          }),
          style: {
            fontSize: 9
          }
        }

      });

      //let etapas = obrasEtapas.map(n => {

      //  const itens = n.obrasEtapasItens;

      //  const medicoes = itens.flatMap(n1 => n1.obrasMedicoesEtapasItens);

      //  console.log('MEDICOES', medicoes)

        
      //});

      console.log('ETAPAS', newArray)

      return newArray;

    } catch (err) {
      console.log('ERR', err)
    }
  };

  function diasNoMes(ano, mes) {
    return new Date(ano, mes + 1, 0).getDate();
  }

  function obterNomeMes(ano, mes) {
    const data = new Date(ano, mes, 1);
    const opcoes = { month: 'long' };
    return data.toLocaleDateString('pt-BR', opcoes);
  }

  const montarMeses = (inicio, fim) => {

    let meses = [];

    while (inicio <= fim) {

      let data = new Date(inicio);

      console.log('DATA', data)

      meses.push(
        {
          id: `m13${data.getMonth() + 1}`,
          title: obterNomeMes(data.getFullYear(), data.getMonth() + 1).toUpperCase(),
          start: new Date(data.getFullYear(), data.getMonth() + 1, 1),
          end: new Date(data.getFullYear(), data.getMonth() + 1, diasNoMes(data.getFullYear(), data.getMonth() + 1))
        }
      );

      inicio = new Date(data.getFullYear(), data.getMonth() + 1, data.getDate());

    }

    console.log('MESES', meses);
    return meses;
  }

  const timebar = [
    {
      id: "time1",
      title: "MESES",
      cells: montarMeses(inicio, fim),
      useAsGrid: true,
      style: {
      }
    }
  ]

  useEffect(() => {
    console.log('obraMedicaooooooo', obraMedicao)

  }, [obraMedicao])

  return (
    <div className="app">
      <Timeline
        scale={{
          start,
          end
        }}
        isOpen={open}
        clickElement={clickElement}
        timebar={timebar}
        tracks={getEtapas(obra.obrasEtapas)}
        now={new Date(now.getFullYear(), now.getMonth(), now.getDate())}
        // enableSticky
        scrollToNow
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return state
}

const connectedTimelineGanttPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(TimelineGantt))
)
export { connectedTimelineGanttPage as TimelineGantt }
