import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom';
import { uploadFile } from '_services'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { withStyles } from '@mui/styles'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import Typography from '@mui/material/Typography'
import { useConfirm } from 'material-ui-confirm'
import HelpIcon from '@mui/icons-material/Help';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Chip,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  IconButton,
  Switch,
  Tab,
  Tabs,
  Tooltip,
  TextField
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faUserPlus, faPlus } from '@fortawesome/free-solid-svg-icons'
import {
  authActions,
  empresaAction,
  usuarioAction,
  orgaoAction,
  cidadeAction,
  estadoAction,
  usuarioEmpresaAction,
  tipoServicoAction,
  orgaosInteresseAction,
  empresaOrgaoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, cpfMask, cnpjMask, stringUtils, phoneMask, validateCNPJ, MANUAIS_CATEGORIAS } from '../../utils'
import { Page } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import { UsuarioEditModal } from './usuarioEditModal.component';
import { EmpresaAnexoEdit } from '../';
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SwitchBlue = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#1C5585',
    }
  }
}));

const ButtonAdd = styled(Button)({
  backgroundColor: '#015192',
  marginLeft: 7,
  marginRight: 7,
  marginBottom: 3,
  '&:hover': {
    backgroundColor: '#015192',
    borderColor: '#015192',
  },
})

const BadgeShowNames = styled(Badge)({
  fontSize: 15,
  paddingLeft: 7,
  paddingRight: 7,
  color: '#fff'
})

const EmpresaEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, empresa, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const confirm = useConfirm()

  const location = useLocation();
  const isAnexo = location.state;

  const [idOrgao, setIdOrgao] = useState(0);
  const [textOrgao, setTextOrgao] = useState('');

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [open, setOpen] = React.useState(false)

  const [valueOrgao, setValueOrgao] = React.useState(null);
  const [inputValueOrgao, setInputValueOrgao] = React.useState('');
  const [openOrgao, setOpenOrgao] = React.useState(false)

  const [modalUsuario, setModalUsuario] = useState(false);
  const toggleUsuario = () => setModalUsuario(!modalUsuario);

  const [selectedTiposServicos, setSelectedTiposServicos] = useState([]);
  const [selectedEmpresaOrgaos, setSelectedEmpresaOrgaos] = useState([]);

  const orgaos = useSelector((state) => state.orgao.orgao)

  const tiposServico = useSelector((state) => state.tipoServico.tipoServico)
  const empresasOrgaos = useSelector((state) => state.orgao.orgao)

  const { cidade } = useSelector(
    (state) => state.cidade
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const estados = useSelector((state) => state.estado.estado)

  const options = cidade.map(u => ({ id: u.value, nome: u.text }));

  const optionsOrgaos = orgaos.map(u => ({ id: u.value, nome: u.text }));

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  useEffect(() => {

    dispatch(empresaAction.clear())
    dispatch(empresaAction.clearUsuariosEmpresas())
    dispatch(empresaAction.clearTiposServicos())

    dispatch(orgaoAction.getOrgaoSelect())
    dispatch(estadoAction.getEstadoSelect())
    dispatch(tipoServicoAction.getTiposServico())
    dispatch(empresaOrgaoAction.getEmpresaOrgaos())

    if (params.id) {
      dispatch(empresaAction.getEmpresaById(params.id))
      setSelectedEmpresaOrgaos(props.empresa.empresasOrgaos)
    } else {
      dispatch(empresaAction.clear())
      dispatch(empresaAction.clearUsuariosEmpresas())
    }

    if (isAnexo) {
      setTabValue(1);
    }

    if (props.empresa.idEstado != undefined && props.empresa.idEstado !== 0 && props.empresa.idEstado !== '') {
      dispatch(cidadeAction.getCidadeByIdEstado(props.empresa.idEstado))
    }

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);

  }, []);

  const handleOpenModal = () => {
    dispatch(usuarioAction.clear())
    dispatch(usuarioEmpresaAction.clear())
    toggleUsuario();
  }

  useEffect(() => {
    if (props.empresa.idCidade != 0 && params.id != undefined) {
      setValue(props.empresa.cidade)
    }
  }, [props.empresa.idCidade])

  useEffect(() => {
    if (props.empresa.idEstado) {
      dispatch(cidadeAction.getCidadeByIdEstado(props.empresa.idEstado))
    }
  }, [props.empresa.idEstado])

  const handleAddOrgaoOnList = () => {
    if (props.empresa.empresasOrgaos.filter(ts => ts.idOrgao == parseInt(idOrgao)).length > 0) {
      toast.error('Erro ao adicionar! Este órgao já existe!')
    }
    else {
      props.empresa.empresasOrgaos.push({
        'idOrgao': idOrgao,
        'idEmpresa': props.empresa.id,
        'orgao': {
          'nome': textOrgao
        }
      });
      forceUpdate(n => !n);
      setIdOrgao(0);
      setValueOrgao(null);
    }
  }

  const handleRemoveOrgaoOnList = idOrgao => {
    confirm({
      title: 'Você deseja excluir este órgão?',
      description: 'Essa operação desvinculará o órgao desta empresa',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Excluindo órgão...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      props.empresa.empresasOrgaos = props.empresa.empresasOrgaos.filter(ts => ts.idOrgao != parseInt(idOrgao));
      forceUpdate(n => !n);
    })
  };

  const isTipoServicoSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasTiposServicos?.some((n) => n.idTipoServico == id) || selectedTiposServicos?.some((n) => n.idTipoServico == id))
    }
  }

  const isEmpresaOrgaosSelected = (id) => {
    if (id != undefined) {
      return (props.empresa?.empresasOrgaos?.some((o) => o.idOrgao == id) || selectedEmpresaOrgaos?.some((o) => o.id == id))
    }
  }

  function handleChangeTipoServico(id) {
    const isSelected = selectedTiposServicos?.some((n) => n.idTipoServico == id)
    if (isSelected) {
      const newSelected = selectedTiposServicos?.filter((n) => n.idTipoServico != id)
      setSelectedTiposServicos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idTipoServico: id,
        idEmpresa: props.empresa.id
      }
      setSelectedTiposServicos([...selectedTiposServicos, newSelecteds])
    }
  }

  function handleChangeEmpresaOrgaos(id) {
    const isSelected = selectedEmpresaOrgaos?.some((o) => o.idOrgao == id)
    if (isSelected) {
      const newSelected = selectedEmpresaOrgaos?.filter((o) => o.idOrgao != id)
      setSelectedEmpresaOrgaos(newSelected)
    } else {
      let newSelecteds = {
        id: 0,
        idOrgao: id,
        idEmpresa: props.empresa.id
      }
      setSelectedEmpresaOrgaos([...selectedEmpresaOrgaos, newSelecteds])
    }
  }

  useEffect(() => {
    props.empresa.empresasTiposServicos = selectedTiposServicos;
    forceUpdate(n => !n);
  }, [selectedTiposServicos])

  useEffect(() => {
    props.empresa.empresasOrgaos = selectedEmpresaOrgaos;
    forceUpdate(n => !n);
  }, [selectedEmpresaOrgaos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasTiposServicos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasOrgaos])

  useEffect(() => {
    if (value) {
      handleChangeAutoComplete(value)
    }
  }, [value])

  const handleChangeAutoComplete = (obj) => {
    if (obj != null) {
      let selected = cidade.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        cidadeAction.changeDetailsCidade(
          selected[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      setIdOrgao(obj.id);
      setTextOrgao(obj.nome);
    }
    setOpenOrgao(false)
  }


  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.empresasOrgaos])

  useEffect(() => {
    forceUpdate(n => !n);
  }, [props.empresa.usuariosEmpresas])

  useEffect(() => {

  }, [ready, props.empresa.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(empresaAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      razaoSocial: props.empresa.razaoSocial,
      nomeFantasia: props.empresa.nomeFantasia,
      cpf: props.empresa.cpf ? props.empresa.cpf.split('.').join('').replace(/[-/.]/g, '') : '',
      cnpj: props.empresa.cnpj ? props.empresa.cnpj.split('.').join('').replace(/[-/.]/g, '') : '',
      celular: props.empresa.celular ? props.empresa.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      email: props.empresa.email ? props.empresa.email.toLowerCase() : '',
      telefone: props.empresa.telefone ? props.empresa.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', '') : '',
      endereco: props.empresa.endereco,
      informacoesCobranca: props.empresa.informacoesCobranca,
      inscricaoEstadual: props.empresa.inscricaoEstadual,
      idCidade: value ? value.id : props.empresa.idCidade,
      idEstado: props.empresa.idEstado,
      usuariosEmpresas: props.empresa.usuariosEmpresas,
      empresasOrgaos: props.empresa.empresasOrgaos,
      fornecedor: props.empresa.fornecedor,
      autonomo: props.empresa.autonomo,
      prestadorServico: props.empresa.prestadorServico,
      atendeFimDeSemana: props.empresa.atendeFimDeSemana,
      servicosUrgencia: props.empresa.servicosUrgencia,
      empresasTiposServicos: props.empresa.empresasTiposServicos
    }

    if (props.empresa.empresasTiposServicos.length > 0) {
      if (params.id) {
        dispatch(empresaAction.editEmpresaInfo(params.id, payload, true))
      } else {
        dispatch(empresaAction.createEmpresa(payload))
      }
    } else {
      toast.error('Selecione ao menos um tipo de serviço');
    }
  };

  const handleAprovaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja liberar o acesso ao sistema para esta Empresa?',
      description: 'Essa operação é irreversível',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Aprovando...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAction.editAprovaCadastroEmpresa(params.id))
    })
  }

  const handleRecusaCadastroEmpresa = () => {
    confirm({
      title: 'Você deseja recusar o acesso ao sistema para esta Empresa?',
      description: 'Essa operação é irreversível',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Recusando...' : 'Sim, recusar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(empresaAction.editRecusaCadastroEmpresa(params.id))
    })
  }

  const handleRemoveOnList = id => {
    confirm({
      title: 'Você deseja excluir este usuário?',
      description: 'Essa operação desvinculará o usuário desta empresa',
      disabled: props.empresa.isLoading,
      confirmationText: props.empresa.isLoading ? 'Excluindo usuário...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      props.empresa.usuariosEmpresas = props.empresa.usuariosEmpresas.filter(ts => ts.idUsuario != parseInt(id));
      dispatch(usuarioEmpresaAction.deleteUsuarioById(id, true))
      forceUpdate(n => !n);
    })

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Empresa'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Empresa'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Empresas - Editar Empresa'
          : 'Gerência de Empresas - Nova Empresa'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="CADASTRO" {...a11yProps(0)} />
                  <Tab label="ANEXOS" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formEmpresa"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardHeader
                      subheader="Cadastro de Empresas"
                      title={<SegHeader />}
                    />
                    <Grid item lg={1} md={1} xl={1} xs={12}>
                      <IconButton
                        aria-label="Ajuda"
                        component="a"
                        onClick={() =>
                          handleOpenModalHelp()
                        }
                        size="small"
                      >
                        <Tooltip title={'Ajuda'}>
                          <Tooltip>
                            <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                          </Tooltip>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider />
                  <CardContent lg={12} md={12} xl={12} xs={12}>
                    <Grid container spacing={2}>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="razaoSocial"
                          label="Razão Social *"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('razaoSocial')}
                          value={props.empresa.razaoSocial || ''}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="nomeFantasia"
                          label="Nome Fantasia *"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('nomeFantasia')}
                          value={props.empresa.nomeFantasia || ''}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      {props.empresa.autonomo != true &&
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="cnpj"
                            label="CNPJ *"
                            margin="normal"
                            disabled={props.empresa.id && props.empresa.cnpj && validateCNPJ(props.empresa.cnpj)}
                            onChange={handleChange('cnpj')}
                            value={cnpjMask(props.empresa.cnpj) || ''}
                            variant="outlined"
                            validators={props.empresa.autonomo != true ? ['required'] : null}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      }
                      {props.empresa.autonomo == true &&
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="cpf"
                            label="CPF *"
                            margin="normal"
                            onChange={handleChange('cpf')}
                            value={cpfMask(props.empresa.cpf) || ''}
                            variant="outlined"
                            validators={props.empresa.autonomo == true ? ['required'] : null}
                            errorMessages={['Campo obrigatório!']}
                          />
                        </Grid>
                      }
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          type="number"
                          id="ie"
                          inputProps={{ maxLength: 12 }}
                          label="Inscrição Estadual"
                          margin="normal"
                          onChange={handleChange('inscricaoEstadual')}
                          value={props.empresa.inscricaoEstadual || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          label="E-mail *"
                          onInput={stringUtils.toInputLowerCase}
                          value={props.empresa.email || ''}
                          className={classes.textField}
                          onChange={handleChange('email')}
                          id="email"
                          name="email"
                          autoComplete="email"
                          validators={['required', 'isEmail']}
                          errorMessages={[
                            'Você precisa informar o e-mail',
                            'E-mail inválido'
                          ]}
                          disabled={props.empresa.id != 0}
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="telefone"
                          label="Telefone *"
                          margin="normal"
                          onChange={handleChange('telefone')}
                          value={phoneMask(props.empresa.telefone) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={3} md={3} xl={3} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="celular"
                          label="Celular *"
                          margin="normal"
                          onChange={handleChange('celular')}
                          value={phoneMask(props.empresa.celular) || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <SelectValidator
                          className={classes.textField}
                          variant="outlined"
                          id="idEstado"
                          label="Estado"
                          value={props.empresa.idEstado}
                          onChange={handleChange('idEstado')}
                          margin="normal"
                          InputLabelProps={{
                            shrink: true
                          }}
                          fullWidth
                          displayEmpty
                          labelId="idEstado"
                        >
                          <MenuItem disabled value="">
                            <em>Estado</em>
                          </MenuItem>
                          {!isEmpty(estados) &&
                            undefined !== estados &&
                            estados.length &&
                            estados.map((row, index) => (
                              <MenuItem key={index} value={row.value}>
                                {row.text}
                              </MenuItem>
                            ))}
                        </SelectValidator>
                      </Grid>
                      <Grid item lg={4} md={4} xl={4} xs={12} style={{ paddingTop: 31 }}>
                        <Autocomplete
                          value={value}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          id="controllable-states-demo"
                          options={options}
                          margin="normal"
                          variant="outlined"
                          defaultValue={value}
                          getOptionLabel={option => option.nome || ''}
                          renderInput={(params) => <TextField {...params} required label="Selecione uma cidade..." fullWidth />}
                          fullWidth
                        />
                      </Grid>
                      <Grid item lg={6} md={6} xl={6} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="endereco"
                          label="Endereço *"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('endereco')}
                          value={props.empresa.endereco || ''}
                          variant="outlined"
                          validators={['required']}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          multiline
                          minRows={3}
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="informacoesCobranca"
                          label="Informações de Cobrança"
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          onChange={handleChange('informacoesCobranca')}
                          value={props.empresa.informacoesCobranca || ''}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <CardContent style={{ placeItems: 'center' }}>
                          <FormControlLabel
                            control={
                              <SwitchBlue
                                checked={props.empresa.fornecedor}
                                onChange={handleChange('fornecedor')}
                              />
                            }
                            label="Fornecedor"
                          />
                          <FormControlLabel
                            control={
                              <SwitchBlue
                                checked={props.empresa.prestadorServico}
                                onChange={handleChange('prestadorServico')}
                              />
                            }
                            label="Prestador de Serviço"
                          />
                          <FormControlLabel
                            control={
                              <SwitchBlue
                                checked={props.empresa.autonomo}
                                onChange={handleChange('autonomo')}
                              />
                            }
                            label="Autônomo"
                          />
                          <FormControlLabel
                            control={
                              <SwitchBlue
                                checked={props.empresa.atendeFimDeSemana}
                                onChange={handleChange('atendeFimDeSemana')}
                              />
                            }
                            label="Atende em Finais de Semana"
                          />
                          <FormControlLabel
                            control={
                              <SwitchBlue
                                checked={props.empresa.servicosUrgencia}
                                onChange={handleChange('servicosUrgencia')}
                              />
                            }
                            label="Realiza Serviços de Urgência"
                          />
                        </CardContent>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography component="h4" variant="subtitle" align="center">
                          Tipos de serviços oferecidos pela empresa *
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                        {tiposServico && tiposServico != undefined && tiposServico.length > 0 &&
                          tiposServico.map((n, index) => (
                            <Chip label={n.nome} key={index} onClick={() => handleChangeTipoServico(n.id)}
                              variant={isTipoServicoSelected(n.id) ? "filled" : "outlined"} style={{ backgroundColor: isTipoServicoSelected(n.id) && '#015192', color: isTipoServicoSelected(n.id) ? '#FFFFFF' : '#000000', margin: 5 }} />
                          ))
                        }
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography component="h4" variant="subtitle" align="center">
                          Órgãos de interesse *
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                        {empresasOrgaos && empresasOrgaos != undefined && empresasOrgaos.length > 0 &&
                          empresasOrgaos.map((o, index) => (
                            <Chip label={o.text} key={index} onClick={() => handleChangeEmpresaOrgaos(o.value)}
                              variant={isEmpresaOrgaosSelected(o.value) ? "filled" : "outlined"} style={{ backgroundColor: isEmpresaOrgaosSelected(o.value) && '#015192', color: isEmpresaOrgaosSelected(o.value) ? '#FFFFFF' : '#000000', margin: 5 }} />
                          ))
                        }
                      </Grid>
                    </Grid>
                  </CardContent>
                  {params.id &&
                    <>
                      <Grid item md={12} xs={12} style={{ marginTop: '15px', marginBottom: '25px' }}>
                        <Divider />
                      </Grid>
                      <Grid container lg={12} md={12} xl={12} xs={12} spacing={2}>
                        <Grid container style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginTop: 7 }} spacing={2} >
                          <Grid item lg={6} md={6} xl={6} xs={12}>
                            <Typography component="h4" variant="subtitle" align="right" >
                              Cadastrar e vincular usuário a empresa
                            </Typography>
                          </Grid>
                          <AccessControl
                            rule={'usuariosEmpresas.create'} //permissão necessária para acessar conteúdo
                            yes={() => (
                              <Grid item lg={4} md={4} xl={4} xs={12} align="left">
                                <Button style={{ backgroundColor: '#000' }}
                                  name="btnUsuarios"
                                  id="btnUsuarios"
                                  className="btn"
                                  variant="contained"
                                  component='a'
                                  onClick={() => handleOpenModal()}>Cadastrar usuário&nbsp;<FontAwesomeIcon icon={faUserPlus} />
                                </Button>
                              </Grid>
                            )}
                            no={() => <AccessDenied />}
                          />
                        </Grid>
                        <Grid container spacing={2} style={{ marginTop: 6, display: 'flex', flexDirection: 'row', marginLeft: 15, marginRight: 15, justifyContent: 'center' }} align="center">
                          {
                            (props.empresa.usuariosEmpresas != null && props.empresa.usuariosEmpresas.length > 0 ?
                              props.empresa.usuariosEmpresas.map(n => {
                                return (
                                  (n.id !== '' && n.excluido != true ?
                                    <Grid item lg={3} md={3} xl={3} xs={12} style={{ marginBottom: 2 }}>
                                      <Card>
                                        <CardContent>
                                          <Typography variant="h6" component="div">
                                            {(n.usuario ? n.usuario.nome.toUpperCase() : n.idUsuario)}
                                          </Typography>
                                          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                            {(n.usuario ? n.usuario.email : '')}
                                          </Typography>
                                          <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                            {(n.usuario ? n.usuario.idTipoUsuario == 1 ? 'ADMINISTRADOR' : n.usuario.idTipoUsuario == 2 ? 'GESTOR ÓRGÃO PÚBLICO' : n.usuario.idTipoUsuario == 3 ? 'PRESTADOR DE SERVIÇO' : n.usuario.idTipoUsuario == 4 ? 'MOTORISTA' : n.usuario.idTipoUsuario == 5 ? 'FISCAL' : '' : '')}
                                          </Typography>
                                          <Tooltip
                                            title="Excluir usuário"                                          >
                                            <Button sx={{ fontWeigth: 400, marginLeft: 7, marginRight: 7 }} onClick={() => handleRemoveOnList(n.idUsuario)} key={'button' + n.id}>
                                              <FontAwesomeIcon icon={faTrashAlt} style={{ color: '#000', fontSize: 17 }} />
                                            </Button>
                                          </Tooltip>
                                        </CardContent>
                                      </Card>
                                    </Grid>
                                    : null)
                                );
                              })
                              : null)
                          }
                        </Grid>
                      </Grid>
                    </>
                  }
                  <CardActions>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                        * Campos obrigatórios
                      </Typography>
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlgin: 'right',
                      justifyContent: 'right'
                    }}>
                      <CustomButton
                        variant="outlined"
                        onClick={() => history.push('/empresas')}
                      >
                        Cancelar
                      </CustomButton>
                      {empresa.id && (authActions.isPrefeitura() || authActions.isGestor()) ?
                        <>
                          <CustomButton
                            variant="outlined"
                            onClick={handleRecusaCadastroEmpresa}
                            disabled={props.empresa.cadastroLiberado == false || props.empresa.isLoading}
                            startIcon={<CloseIcon />}
                            endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                          >
                            {props.empresa.cadastroLiberado == false ? 'Acesso recusado' : props.empresa.isLoading ? 'Recusando acesso...' : 'Recusar acesso'}
                          </CustomButton>
                          <CustomButton
                            variant="outlined"
                            onClick={handleAprovaCadastroEmpresa}
                            disabled={props.empresa.cadastroLiberado == true || props.empresa.isLoading}
                            startIcon={<DoneIcon />}
                            endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                          >
                            {props.empresa.cadastroLiberado == true ? 'Acesso liberado' : props.empresa.isLoading ? 'Liberando acesso...' : 'Liberar acesso'}
                          </CustomButton>
                        </>
                        : null}
                      <CustomButton
                        type="submit"
                        variant="contained"
                        dark={true}
                        disabled={props.empresa.isLoading}
                        startIcon={<SaveIcon />}
                        endIcon={props.empresa.isLoading && <CircularProgress size={24} />}
                      >
                        {params.id ? (props.empresa.isLoading ? 'Atualizando empresa...' : 'Atualizar') : (props.empresa.isLoading ? 'Salvando empresa...' : 'Salvar')}
                      </CustomButton>
                    </Grid>
                  </CardActions>
                </ValidatorForm>
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <EmpresaAnexoEdit idEmpresa={params.id} />
              </TabPanel>
            </Card>
          </Grid>
        </Grid>
      </Grid>

      {
        (modalUsuario ?
          <UsuarioEditModal toggle={toggleUsuario} modal={modalUsuario} props={props} idempresa={params.id} />
          :
          null
        )
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_EMPRESA_ADMIN}
          />
          :
          null
        )
      }
    </Page >
  )
}

EmpresaEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedEmpresaEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(EmpresaEdit))
)
export { connectedEmpresaEditPage as EmpresaEdit }
