const initialState = {
  totalRegistros: 0,
  totalEmpresas: 0,
  isLoading: false,
  open: false,
  id: '',
  idObraOrcamento: 0,
  idObraEtapaItem: 0,
  status: 0,
  dataAlteracaoStatus: '',
  valor: 0,
  excluido: false,
  obraOrcamentoItem: []
}

export function obraOrcamentoItem(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_ORCAMENTO_ITEM_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_ORCAMENTO_ITEM_SUCCESS':
      return {
        ...state,
        id: action.obraOrcamentoItem.id,
        isLoading: false,
        idObraOrcamento: action.obraOrcamentoItem.idObraOrcamento,
        idObraEtapaItem: action.obraOrcamentoItem.idObraEtapaItem,
        valor: action.obraOrcamentoItem.valor,
        excluido: action.obraOrcamentoItem.excluido,
        status: action.obraOrcamentoItem.status,
        dataAlteracaoStatus: action.obraOrcamentoItem.dataAlteracaoStatus,
        obraOrcamentoItem: action.obraOrcamentoItem.obraOrcamentoItem,
        obrasEtapasItens: action.obraOrcamentoItem.obrasEtapasItens
      }
    case 'CREATE_OBRA_ORCAMENTO_ITEM_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_ORCAMENTO_ITEM_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_ORCAMENTO_ITEM_SUCCESS':
      return {
        ...state,
        id: action.obraOrcamentoItem.id,
        isLoading: false,
        idObraOrcamento: action.obraOrcamentoItem.idObraOrcamento,
        idObraEtapaItem: action.obraOrcamentoItem.idObraEtapaItem,
        valor: action.obraOrcamentoItem.valor,
        excluido: action.obraOrcamentoItem.excluido,
        status: action.obraOrcamentoItem.status,
        dataAlteracaoStatus: action.obraOrcamentoItem.dataAlteracaoStatus,
        obraOrcamentoItem: action.obraOrcamentoItem.obraOrcamentoItem,
        obrasEtapasItens: action.obraOrcamentoItem.obrasEtapasItens
      }
    case 'EDIT_OBRA_ORCAMENTO_ITEM_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_ORCAMENTO_ITEM':
      return {
        ...state,
        obraOrcamentoItem: action.obraOrcamentoItem,
        obrasEtapasItens: action.obrasEtapasItens,
        totalRegistros: action.totalRegistros,
        totalEmpresas: action.totalEmpresas
      }
    case 'OBRA_ORCAMENTO_ITEM_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        idObraOrcamento: action.idObraOrcamento,
        idObraEtapaItem: action.idObraEtapaItem,
        valor: action.valor,
        excluido: action.excluido,
        status: action.status,
        dataAlteracaoStatus: action.dataAlteracaoStatus,
        obraOrcamentoItem: action.obraOrcamentoItem,
        obrasEtapasItens: action.obrasEtapasItens
      }
    case 'OBRA_ORCAMENTO_ITEM_CLEAR':
      return initialState
    case 'OBRA_ORCAMENTO_ITEM_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraOrcamentoItem: [...state.obraOrcamentoItem, action.data.obraOrcamentoItem]
      }
    case 'OBRA_ORCAMENTO_ITEM_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ORCAMENTO_ITEM_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_ORCAMENTO_ITEM_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
