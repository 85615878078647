import React from 'react';
import clsx from 'clsx'
import Card from '@mui/material/Card';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import { Avatar, Typography, CardContent, Grid } from '@mui/material'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@mui/styles'
import { styled } from '@mui/material/styles';
import theme from './../../../../theme'

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  title: {
    fontWeight: 500,
    color: theme.palette.primary.main
  }
}));

const CustomAvatar = styled(Avatar)({
  height: 45,
  width: 45,
  backgroundColor: '#1C5585',
  [theme.breakpoints.down('md')]: {
    height: 35,
    width: 35
  },
})

const CardObrasRecebendoOrcamentos = (props) => {
  const { className, ...rest } = props
  const classes = useStyles();

  const obrasRecebendoOrcamento = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.obrasRecebendoOrcamento : undefined))

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item styles={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item>
              <Typography variant="h5" className={classes.title}>
                {obrasRecebendoOrcamento ? obrasRecebendoOrcamento : 0}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" gutterBottom>
                Obras recebendo orçamento
              </Typography>
            </Grid>
          </Grid>
        <Grid item>
          <CustomAvatar>
            <EngineeringOutlinedIcon sx={{ fontSize: 25, color: '#fff' }} />
          </CustomAvatar>
          </Grid>
        </Grid>        
      </CardContent>
    </Card >
  );
}

CardObrasRecebendoOrcamentos.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardObrasRecebendoOrcamentos
