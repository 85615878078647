const initialState = {
  isLoading: false,
  usuario: [],
  open: false,
  id: '',
  nome: '',
  totalRegistros: 0
}

export function insumo(state = initialState, action) {
  switch (action.type) {    
    case 'FETCHED_ALL_INSUMO':
      return {
        ...state,
        insumo: action.insumo,
        totalRegistros: action.totalRegistros
      }
    case 'INSUMO_CLEAR':
      return initialState
    case 'INSUMO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'INSUMO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
