const initialState = {
  loading: false,
  open: false,
  id: '',
  text: '',
  value: '',
  tabelaInsumoAno: [],
  excluido: false
}

export function tabelaInsumoAno(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_TABELA_INSUMO_ANO':
      return {
        ...state,
        tabelaInsumoAno: action.tabelaInsumoAno
      }
    default:
      return state
  }
}
