import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { usuarioAction } from '../../_actions'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import { phoneMask, history, stringUtils } from '../../utils'
import { useConfirm } from 'material-ui-confirm'

export const UsuarioEditModal = ({ modal, toggle, props, idorgao }) => {

  const [, forceUpdate] = React.useState(0);
  const formRef = React.createRef(null);

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className, orgao, ...rest } = props
  const { match: { params } } = props;

  const isLoading = useSelector(
    (state) => state.usuarioOrgao.isLoading
  )

  useEffect(() => {
  }, [props.orgao.isLoading, isLoading])

  const handleClose = () => {
    dispatch(usuarioAction.clear())
    toggle();
  }

  const handleChange = (prop) => (event) => {
    dispatch(usuarioAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payloadUsuario = {
      nome: props.usuario.nome,
      senha: props.usuario.senha,
      sobrenome: props.usuario.sobrenome,
      email: props.usuario.email.toLowerCase(),
      idTipoUsuario: props.usuario.idTipoUsuario,
      celular: props.usuario.celular && props.usuario.celular.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      matricula: props.usuario.matricula,
      telefone: props.usuario.telefone && props.usuario.telefone.split('-').join('').replace('(', '').replace(')', '').replace(' ', ''),
      usuariosOrgaos: [
        {
          id: 0,
          idUsuario: 0,
          idOrgao: idorgao
        }
      ]
    }

    if (payloadUsuario) {
      dispatch(usuarioAction.createUsuarioOrgao(payloadUsuario))
      dispatch(usuarioAction.clear())
      toggle();
    }

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Usuário'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastro de Usuários"
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="nome"
                    label="Nome *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('nome')}
                    value={props.usuario.nome || ''}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="sobrenome"
                    label="Sobrenome *"
                    margin="normal"
                    onInput={stringUtils.toInputUppercase}
                    onChange={handleChange('sobrenome')}
                    value={props.usuario.sobrenome || ''}
                    variant="outlined"
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                  />
                </Grid>
                <Grid item lg={12} md={6} xl={7} xs={12}>
                  <TextValidator
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    label="E-mail *"
                    value={props.usuario.email || ''}
                    className={classes.textField}
                    onInput={stringUtils.toInputLowerCase}
                    onChange={handleChange('email')}
                    id="email"
                    name="email"
                    autoComplete="email"
                    validators={['required', 'isEmail']}
                    errorMessages={[
                      'Você precisa informar o e-mail',
                      'E-mail inválido'
                    ]}
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={5} xs={12}>
                  <SelectValidator
                    validators={['required']}
                    errorMessages={['Campo obrigatório!']}
                    variant="outlined"
                    id="idTipoUsuario"
                    value={props.usuario.idTipoUsuario}
                    onChange={handleChange('idTipoUsuario') || 0}
                    label="Tipo Usuário *"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true
                    }}
                    fullWidth
                    displayEmpty
                    labelId="idTipoUsuario"
                  >
                    <MenuItem disabled value="">
                      <em>Tipo Usuário</em>
                    </MenuItem>
                    <MenuItem value={5}>FISCAL</MenuItem>
                    <MenuItem value={2}>GESTOR ÓRGÃO PÚBLICO</MenuItem>
                    <MenuItem value={6}>USUÁRIO ÓRGÃO PÚBLICO</MenuItem>
                  </SelectValidator>
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="celular"
                    label="Celular "
                    margin="normal"
                    onChange={handleChange('celular')}
                    value={phoneMask(props.usuario.celular) || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="telefone"
                    label="Telefone "
                    margin="normal"
                    onChange={handleChange('telefone')}
                    value={phoneMask(props.usuario.telefone) || ''}
                    variant="outlined"
                  />
                </Grid>
                <Grid item lg={6} md={6} xl={6} xs={12}>
                  <TextValidator
                    className={classes.textField}
                    fullWidth
                    id="matricula"
                    label="Matrícula"
                    type="number"
                    margin="normal"
                    onChange={handleChange('matricula')}
                    value={props.usuario.matricula || ''}
                    variant="outlined"
                  />
                </Grid>
                {(props.usuario && props.usuario.dataHoraUltimoAcesso ?
                  <Grid item lg={6} md={6} xl={6} xs={12}>
                    <TextValidator
                      className={classes.textField}
                      fullWidth
                      id="dataHoraUltimoAcesso"
                      label="Data/Hora do Último Acesso"
                      margin="normal"
                      value={props.usuario.dataHoraUltimoAcesso ? moment(props.usuario.dataHoraUltimoAcesso).format("DD/MM/YYYY HH:mm") : ''}
                      variant="outlined"
                    />
                  </Grid>
                  :
                  null
                )}
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container lg={12} md={12} xl={12} xs={12}>
                <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                  * Campos obrigatórios
                </Typography>
              </Grid>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.usuario.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.usuario.isLoading && <CircularProgress size={24} />}
                >
                  {props.usuario.isLoading ? 'Salvando usuário...' : 'Salvar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
