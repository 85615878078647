import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete'
import { toast } from 'react-toastify'
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Fab,
  Grid,
  Input,
  InputAdornment,
  ImageList,
  ImageListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  authActions,
  obraAction,
  obraMedicaoAction,
  unidadeAction
} from '../../_actions'
import { styled } from '@mui/material/styles';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { uploadImage, uploadFile } from '_services'
import { stringUtils } from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import CustomButton from '../../components/CustomButton';

const CustomInput = styled(Input)(() => ({
  display: "none"
}));

const CustomDeleteIcon = styled(DeleteIcon)(() => ({
  color: '#4f4f4f',
  marginTop: 47,
  fontSize: 21,
  '&:hover': {
    cursor: 'pointer'
  },
}));

export const ObraMedicaoAddModal = ({ modal, toggle, props, idObra, obraEtapaItem, percentual, obraEtapaModal }) => {

  const formRef = React.createRef(null);

  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [filesUploaded, setFilesUploaded] = useState([]);

  const [descricao, setDescricao] = useState('');
  const [titulo, setTitulo] = useState(!obraEtapaModal ? 'MEDIÇÃO DE ITEM - ' + props.authentication?.user?.nome?.toUpperCase() + ' ' + props.authentication?.user?.sobrenome?.toUpperCase() : obraEtapaModal ? 'MEDIÇÃO DE ETAPA - ' + props.authentication?.user?.nome?.toUpperCase() + ' ' + props.authentication?.user?.sobrenome?.toUpperCase() : '');
  const [quantidade, setQuantidade] = useState('');
  const [percentualValor, setPercentualValor] = useState('');
  const [open, setOpen] = useState(false);
  const [openFile, setOpenFile] = useState(false);

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className, empresa, obraOcorrenciaAnexo, ...rest } = props
  const { match: { params } } = props;

  const handleClose = () => {
    toggle();
  }

  const handleUploadClickImage = (event) => {
    setOpen(true)
    const file = event.target.files[0];
    const payload = {
      file: file
    }

    uploadImage(payload)
      .then((response) => {
        setImagesUploaded([...imagesUploaded, { caminhoArquivo: response.toString() }])
        setOpen(false)
      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  };

  const handleUploadClickFile = (event) => {
    //event.persist(); // Persist the event
    setOpenFile(true)
    const file = event.target.files[0];
    uploadFile(file)
      .then((response) => {
        setFilesUploaded([...filesUploaded, { caminhoArquivo: response.toString() }])
        setOpenFile(false)
      })
      .catch((err) => {
        console.log(err)
        setOpenFile(false)
      })
  };

  const handleRemoveOnList = caminhoArquivo => {
    confirm({
      title: 'Você deseja excluir esta imagem?',
      description: 'Essa operação removerá a imagem desta medição',
      disabled: props.obraMedicao.isLoading,
      confirmationText: props.obraMedicao.isLoading ? 'Excluindo imagem...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      setImagesUploaded(imagesUploaded.filter(ts => ts.caminhoArquivo != caminhoArquivo));
    })
  };

  const handleRemoveOnListFile = caminhoArquivo => {
    confirm({
      title: 'Você deseja excluir este arquivo?',
      description: 'Essa operação removerá o arquivo desta medição',
      disabled: props.obraMedicao.isLoading,
      confirmationText: props.obraMedicao.isLoading ? 'Excluindo arquivo...' : 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      setFilesUploaded(filesUploaded.filter(ts => ts.caminhoArquivo != caminhoArquivo));
    })
  };

  useEffect(() => {
    if (idObra) {
      dispatch(obraAction.getObraById(idObra));

      dispatch(unidadeAction.getUnidadeById(obraEtapaItem.idUnidade));

    }
  }, [])

  const updatePercentualValor = (percentual) => {
    if (percentual >= 0) {
      let perc = obraEtapaItem.quantidade * percentual / 100;

      setQuantidade(perc.toFixed(2));
      setPercentualValor(percentual);
    }
  }

  const updatePercentualValorEtapa = (perc) => {
    if (percentual >= 0) {
      setPercentualValor(perc);
    }
  }

  const updateQuantidade = (quant) => {
    if (quant >= 0) {
      let perc = quant * 100 / obraEtapaItem.quantidade;
      setPercentualValor(perc.toFixed(2));
      setQuantidade(quant);
    }
  }

  const handleSubmit = () => {

    try {

      let listaItens = [];

      if (obraEtapaModal != null) {

        listaItens = obraEtapaModal?.obrasEtapasItens.map(item => {
          return {
            idEmpresa: authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0].idEmpresa : 0,
            idObraMedicao: 0,
            idObraEtapaItem: item.id,
            percentual: percentualValor,
            quantidade: item.quantidade * percentualValor / 100,
            descricao: descricao,
            obrasMedicoesEtapasItensAnexos: imagesUploaded.concat(filesUploaded)
          }
        });

      } else {
        listaItens = [
          {
            idEmpresa: authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0].idEmpresa : 0,
            idObraMedicao: 0,
            idObraEtapaItem: obraEtapaItem.id,
            percentual: percentualValor,
            quantidade: quantidade,
            descricao: descricao,
            obrasMedicoesEtapasItensAnexos: imagesUploaded.concat(filesUploaded)
          }
        ]
      }

      const payload = {
        data: new Date(),
        idObra: idObra,
        idUsuario: props.authentication?.user?.id,
        titulo: titulo,
        obrasOcorrencias: [{
          data: new Date(),
          idObra: idObra,
          idEmpresa: authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0].idEmpresa : null,
          idUsuario: props.authentication?.user?.id,
          idOrgao: props.obra.idOrgao,
          idObraMedicao: 0,
          sistema: false,
          origem: authActions.isEmpresa() ? 'E' : 'O',
          descricao: 'Nova medição registrada pelo usuário ' + props.authentication?.user?.nome?.toUpperCase(),
          obrasOcorrenciasAnexos: imagesUploaded.concat(filesUploaded)

        }],
        obrasMedicoesEtapasItens: listaItens
      }

      console.log('ORGAO DA OBRA =>', props.obra.idOrgao)

      if (payload) {
        if (imagesUploaded && imagesUploaded.length > 0) {
          dispatch(obraMedicaoAction.createObraMedicao(payload))
          toggle();

        } else {
          toast.error('Selecione pelo menos uma imagem para a medição antes de salvar')
        }
      }

    } catch (err) {
      console.log('ERR', err);
    }

  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Medição'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader="Cadastro de Medições"
              title={<SegHeader />}
            />
            <Divider />
            {!obraEtapaModal ?
              <>
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <TableContainer component={Paper}>
                    <Table style={{ backgroundColor: '#F7F7F7' }}>
                      <TableHead>
                        <TableRow size="small">
                          <TableCell size="small" colSpan={3} align="center">
                            <strong>DETALHES DO ITEM</strong>
                          </TableCell>
                        </TableRow>
                        <TableRow size="small">
                          <TableCell size="small" >
                            <b>ITEM</b>
                          </TableCell>
                          <TableCell size="small" align="center">
                            <b>QUANTIDADE</b>
                          </TableCell>
                          <TableCell size="small" align="center">
                            <b>CONCLUSÃO</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow size="small">
                          <TableCell size="small">
                            {obraEtapaItem.descritivo?.toUpperCase()}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {obraEtapaItem.quantidade} {props.unidade?.unidade?.descricao}
                          </TableCell>
                          <TableCell size="small" align="center">
                            {`${percentual} %`}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2} style={{ display: 'flex', alignContent: 'center' }}>
                    <Grid item lg={4} md={4} xs={12} align="center">
                      <CardContent>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando foto...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <CustomInput
                          accept="image/*"
                          id="contained-button-file-2"
                          multiple
                          type="file"
                          onChange={(e) => handleUploadClickImage(e)}
                        />
                        <label htmlFor="contained-button-file-2">
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                      </CardContent>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 4 }} >
                        Resolução máxima: 5000 px
                      </Typography>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 3 }} >
                        Formatos aceitos: JPEG, JPG, PNG e WEBP e Documentos
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={12} align="center">
                      <ImageList cols={4}>
                        {imagesUploaded.map((item) => (
                          <ImageListItem key={item.caminhoArquivo} style={{ display: 'flex', flexDirection: 'row' }}>
                            <img
                              style={{ borderRadius: 10, backgroundSize: 'cover', width: 120, height: 120 }}
                              src={item.caminhoArquivo}
                              alt={item.caminhoArquivo}
                              loading="lazy"
                            />
                            <Tooltip title="Remover imagem">
                              <CustomDeleteIcon size="small" onClick={() => handleRemoveOnList(item.caminhoArquivo)} />
                            </Tooltip>
                          </ImageListItem>
                        ))
                        }
                      </ImageList>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} align="center">
                      <CardContent>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openFile}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando arquivo...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <CustomInput
                          accept="files/*"
                          id="file"
                          type="file"
                          onChange={(e) => handleUploadClickFile(e)}
                        />
                        <label htmlFor="file">
                          <Tooltip title="Enviar arquivo">
                            <Fab component="span" className={classes.button}>
                              <AttachFileIcon />
                            </Fab>
                          </Tooltip>
                        </label>
                      </CardContent>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 4 }} >
                        Enviar arquivos
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={12} align="center">
                      {filesUploaded && filesUploaded.length > 0 &&
                        <Table size="small" fullWidth>
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell>
                                Caminho arquivo
                              </TableCell>
                              <TableCell>
                                Ação
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {filesUploaded.map((item) => (
                            <TableBody>
                              <TableRow key={item.caminhoArquivo}>
                                <TableCell align="left" style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                  <span>{item.caminhoArquivo}</span>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                  <CustomDeleteIcon size="small" style={{ marginTop: 0, marginLeft: 4 }} onClick={() => handleRemoveOnListFile(item.caminhoArquivo)} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      }
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} >
                      <TextValidator
                        id="percentualValor"
                        label="Conclusão *"
                        variant="outlined"
                        type="number"
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        value={percentualValor || 0}
                        onChange={(event) => updatePercentualValor(event.target.value)}
                        InputProps={{
                          min: 0,
                          max: 100,
                          step: 0.5,
                          endAdornment: <InputAdornment position="end">&nbsp;%</InputAdornment>
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        validators={['required', 'minNumber:0']}
                        errorMessages={['Campo obrigatório!', 'Valor precisa ser maior que zero']}
                      />
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} >
                      <TextValidator
                        id="quantidade"
                        label="Quantidade *"
                        variant="outlined"
                        type="number"
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        value={quantidade || 0}
                        onChange={(event) => updateQuantidade(event.target.value)}
                        InputProps={{
                          min: 0,
                          endAdornment: <InputAdornment position="end">&nbsp;{props.unidade?.unidade?.descricao}</InputAdornment>
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        validators={['required', 'minNumber:0']}
                        errorMessages={['Campo obrigatório!', 'Valor precisa ser maior que zero']}
                      />
                    </Grid>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="titulo"
                        label="Título"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={(event) => setTitulo(event.target.value)}
                        value={titulo || 'MEDIÇÃO DO ITEM - ' + props.authentication?.user?.nome?.toUpperCase() + ' ' + props.authentication?.user?.sobrenome?.toUpperCase()}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="descricao"
                        label="Descrição"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={(event) => setDescricao(event.target.value)}
                        value={descricao || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </>
              :
              <>
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Table style={{ backgroundColor: '#F7F7F7' }}>
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" colSpan={4} align="center">
                          <strong>ETAPA: {obraEtapaModal?.titulo?.toUpperCase()}</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow size="small">
                        <TableCell size="small" >
                          <b>ITEM</b>
                        </TableCell>
                        <TableCell size="small" align="right">
                          <b>QUANTIDADE</b>
                        </TableCell>
                        <TableCell size="small" align="right">
                          <b>CONCLUSÃO</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    {obraEtapaModal?.obrasEtapasItens?.map((o) => {
                      return (
                        <TableBody>
                          <TableRow size="small">
                            <TableCell size="small">
                              {o.descritivo?.toUpperCase()}
                            </TableCell>
                            <TableCell size="small" align="right">
                              {o.quantidade} {props.unidade?.unidade?.descricao}
                            </TableCell>
                            <TableCell size="small" align="right">
                              {o.obrasMedicoesEtapasItens?.reduce(function (accumulator, object) {
                                return accumulator + (object.obraMedicao?.situacao == 1 && object.percentual)
                              }, 0)} %
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      )
                    })}
                  </Table>
                </CardContent>
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2} style={{ display: 'flex', alignContent: 'center' }}>
                    <Grid item lg={4} md={4} xs={12} align="center">
                      <CardContent>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando foto...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <CustomInput
                          accept="image/*"
                          id="contained-button-file"
                          multiple
                          type="file"
                          onChange={(e) => handleUploadClickImage(e)}
                        />
                        <label htmlFor="contained-button-file">
                          <Fab component="span" className={classes.button}>
                            <AddPhotoAlternateIcon />
                          </Fab>
                        </label>
                      </CardContent>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 4 }} >
                        Resolução máxima: 5000 px
                      </Typography>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 3 }} >
                        Formatos aceitos: JPEG, JPG, PNG e WEBP
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={12} align="center">
                      <ImageList cols={4}>
                        {imagesUploaded.map((item) => (
                          <ImageListItem key={item.caminhoArquivo} style={{ display: 'flex', flexDirection: 'row' }}>
                            <img
                              style={{ borderRadius: 10, backgroundSize: 'cover', width: 120, height: 120 }}
                              src={item.caminhoArquivo}
                              alt={item.caminhoArquivo}
                              loading="lazy"
                            />
                            <Tooltip title="Remover imagem">
                              <CustomDeleteIcon size="small" onClick={() => handleRemoveOnList(item.caminhoArquivo)} />
                            </Tooltip>
                          </ImageListItem>
                        ))
                        }
                      </ImageList>
                    </Grid>
                    <Grid item lg={4} md={4} xs={12} align="center">
                      <CardContent>
                        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={openFile}>
                          <Grid
                            container
                            spacing={3}
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                          >
                            <Grid item>
                              <CircularProgress color="primary" />
                            </Grid>
                            <Grid item>
                              <Typography variant="h6" color="textPrimary">
                                Enviando arquivo...
                              </Typography>
                            </Grid>
                          </Grid>
                        </Backdrop>
                        <CustomInput
                          accept="files/*"
                          id="file"
                          type="file"
                          onChange={(e) => handleUploadClickFile(e)}
                        />
                        <label htmlFor="file">
                          <Tooltip title="Enviar arquivo">
                            <Fab component="span" className={classes.button}>
                              <AttachFileIcon />
                            </Fab>
                          </Tooltip>
                        </label>
                      </CardContent>
                      <Typography variant="body2" align="center" style={{ fontSize: 11, marginTop: 4 }} >
                        Enviar arquivos
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} xs={12} align="center">
                      {filesUploaded && filesUploaded.length > 0 &&
                        <Table size="small" fullWidth>
                          <TableHead>
                            <TableRow key={0}>
                              <TableCell>
                                Caminho arquivo
                              </TableCell>
                              <TableCell>
                                Ação
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          {filesUploaded.map((item) => (
                            <TableBody>
                              <TableRow key={item.caminhoArquivo}>
                                <TableCell align="left" style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                  <span>{item.caminhoArquivo}</span>
                                </TableCell>
                                <TableCell align="left" style={{ fontSize: 10, verticalAlign: 'middle' }}>
                                  <CustomDeleteIcon size="small" style={{ marginTop: 0, marginLeft: 4 }} onClick={() => handleRemoveOnListFile(item.caminhoArquivo)} />
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          ))}
                        </Table>
                      }
                    </Grid>
                    <Grid item lg={2} md={2} xl={2} xs={12} >
                      <TextValidator
                        id="percentualValor"
                        label="Conclusão *"
                        variant="outlined"
                        type="number"
                        className={classes.textField}
                        fullWidth
                        margin="normal"
                        value={percentualValor || 0}
                        onChange={(event) => updatePercentualValorEtapa(event.target.value)}
                        inputProps={{ min: 0 }}
                        InputProps={{
                          step: "1",
                          endAdornment: <InputAdornment position="end">&nbsp;%</InputAdornment>
                        }}
                        InputLabelProps={{
                          shrink: true
                        }}
                        validators={['required', 'minNumber:1']}
                        errorMessages={['Campo obrigatório!', 'Valor precisa ser maior que zero']}
                      />
                    </Grid>
                    <Grid item lg={10} md={10} xl={10} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="titulo"
                        label="Título"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={(event) => setTitulo(event.target.value)}
                        value={titulo || 'MEDIÇÃO DE ETAPA - ' + props.authentication?.user?.nome?.toUpperCase() + ' ' + props.authentication?.user?.sobrenome?.toUpperCase()}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        id="descricao"
                        label="Descrição"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={(event) => setDescricao(event.target.value)}
                        value={descricao || ''}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </>
            }
            <Divider />
            <CardActions>
              <Grid container lg={12} md={12} xl={12} xs={12}>
                <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                  * Campos obrigatórios
                </Typography>
              </Grid>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <CustomButton
                  onClick={toggle}
                  variant="outlined"
                >
                  Cancelar
                </CustomButton>
                <CustomButton
                  type="submit"
                  dark={true}
                  disabled={props.obraMedicao.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obraMedicao.isLoading && <CircularProgress size={24} />}
                >
                  {props.obraMedicao.isLoading ? 'Salvando medição...' : 'Salvar'}
                </CustomButton>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
