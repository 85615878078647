export function getNomeEvento(evento) {
  try {
    if (evento.tipo == null) {
      return "EM LANÇAMENTO"
    } else if (evento.tipo == "1") {
      return "PENDENTE";
    } else if (evento.tipo == "2") {
      return "RECEBENDO ORÇAMENTOS";
    } else if (evento.tipo == "3") {
      return "EM EXECUÇÃO"; //ERA "ORÇAMENTO APROVADO"
    } else if (evento.tipo == "4") {
      return "RECUSADA";
    } else if (evento.tipo == "5") {
      return "CONCLUÍDA";
    } else if (evento.tipo == "6") {
      return "AVALIADA";
    } else if (evento.tipo == "8") {
      return "DESERTA";
    } else if (evento.tipo == "9") {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getNomeUltimoEvento(ultimoEvento, obra, isLista) {
  try {
    if (ultimoEvento == null) {
      return 'EM LANÇAMENTO';
    } else if (ultimoEvento == "1") {
      return 'PENDENTE';
    } else if (ultimoEvento == "2" && isLista == true) {
      return 'RECEBENDO ORÇAMENTOS (' + obra?.obrasOrcamentos?.length + ')';
    } else if (ultimoEvento == "2" && isLista == false) {
      return 'RECEBENDO ORÇAMENTOS';
    } else if (ultimoEvento == "3") {
      return "EM EXECUÇÃO"; //ERA "ORÇAMENTO APROVADO"
    } else if (ultimoEvento == "4") {
      return "RECUSADA";
    } else if (ultimoEvento == "5") {
      return "CONCLUÍDA";
    } else if (ultimoEvento == "6") {
      return "AVALIADA";
    } else if (ultimoEvento == "7") {
      return "PAUSADA";
    } else if (ultimoEvento == "8") {
      return "DESERTA";
    } else if (ultimoEvento == "9") {
      return "SEM SUCESSO";
    } else {
      return "STATUS INDEFINIDO";
    }
  } catch (err) {
    return "";
  }
}

export function getCor(ultimoEvento) {
  try {
    if (ultimoEvento == null) {
      return "#5e5e5e";
    } else if (ultimoEvento == "1") {
      return "#9E9E9E";
    } else if (ultimoEvento == "2") {
      return "#673AB7";
    } else if (ultimoEvento == "3") {
      return "#2196F3";
    } else if (ultimoEvento == "4") {
      return "#FF5722";
    } else if (ultimoEvento == "5") {
      return "#4CAF50";
    } else if (ultimoEvento == "6") {
      return "#FF9800";
    } else if (ultimoEvento == "7") {
      return "#99FF99";
    } else if (ultimoEvento == "8") {
      return "#FF9999";
    } else if (ultimoEvento == "9") {
      return "#FF2222";
    } else {
      return "#000000";
    }
  } catch (err) {
    return "";
  }
}
