import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'

const useStyles = makeStyles(() => ({
  root: {}
}))

const Tawkto = () => {
  useEffect(() => {
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = 'https://embed.tawk.to/64edea4aa91e863a5c1074c2/1h90lmd3d';
    s.charset = 'UTF-8';
    s.setAttribute('crossorigin', '*');
    document.head.appendChild(s);
  }, []);

  return (
    <div>
      {/* O componente pode ser vazio, pois o chat será carregado pelo script */}
    </div>
  );
}

Tawkto.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string,
  //title: PropTypes.string.isRequired
}

export default Tawkto
