const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraEtapa: [],
  duracaoEtapa: 15,
  open: false,
  id: 0,
  descritivo: '',
  titulo: '',
  periodos: '',
  idObra: 0,
  dataInicio: '',
  dataTermino: '',
  valorReferencia: '',
  valorReferenciaComBdi: '',
  obra: null,
  obrasEtapasItens: []
}

export function obraEtapa(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_ETAPA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_ETAPA_SUCCESS':
      return {
        ...state,
        id: action.obraEtapa.id,
        isLoading: false,
        descritivo: action.obraEtapa.descritivo,
        titulo: action.obraEtapa.titulo,
        periodos: action.obraEtapa.periodos,
        idObra: action.obraEtapa.idObra,
        dataInicio: action.obraEtapa.dataInicio,
        dataTermino: action.obraEtapa.dataTermino,
        valorReferencia: action.obraEtapa.valorReferencia,
        valorReferenciaComBdi: action.obraEtapa.valorReferenciaComBdi,
        obra: action.obraEtapa.obra,
        obrasEtapasItens: action.obraEtapa.obrasEtapasItens
      }
    case 'CREATE_OBRA_ETAPA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_ETAPA_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_ETAPA_SUCCESS':
      return {
        ...state,
        id: action.obraEtapa.id,
        isLoading: false,
        descritivo: action.obraEtapa.descritivo,
        titulo: action.obraEtapa.titulo,
        periodos: action.obraEtapa.periodos,
        idObra: action.obraEtapa.idObra,
        dataInicio: action.obraEtapa.dataInicio,
        dataTermino: action.obraEtapa.dataTermino,
        idEstadoTabelaInsumo: action.obraEtapa.idEstadoTabelaInsumo,
        valorReferencia: action.obraEtapa.valorReferencia,
        valorReferenciaComBdi: action.obraEtapa.valorReferenciaComBdi,
        obra: action.obraEtapa.obra,
        obrasEtapasItens: action.obraEtapa.obrasEtapasItens
      }
    case 'EDIT_OBRA_ETAPA_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_ETAPA':
      return {
        ...state,
        obraEtapa: action.obraEtapa,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_ETAPA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        descritivo: action.descritivo,
        titulo: action.titulo,
        periodos: action.periodos,
        idObra: action.idObra,
        dataInicio: action.dataInicio,
        dataTermino: action.dataTermino,
        valorReferencia: action.valorReferencia,
        valorReferenciaComBdi: action.valorReferenciaComBdi,
        obra: action.obraEtapa,
        obrasEtapasItens: action.obraEtapasEtapasItens
      }
    case 'OBRA_ETAPA_CLEAR':
      return initialState
    case 'OBRA_ETAPA_CLEAR_DETAIL':
      return {
        ...state,
        id: 0,
        descritivo: '',
        titulo: '',
        periodos: '',
        idObra: 0,
        dataInicio: '',
        dataTermino: '',
        valorReferencia: '',
        valorReferenciaComBdi: '',
        obra: null,
        obrasEtapasItens: []
      }
    case 'OBRA_ETAPA_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraobraEtapa: [...state.obraEtapa, action.data.obraEtapa]
      }
    case 'OBRA_ETAPA_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_ETAPA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_ETAPA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
