import { crudService } from '../_services/'

export const tipoAnexoAction = {
  getTiposAnexosSelect,
  getTiposAnexosEmpresaSelect
}

function getTiposAnexosEmpresaSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposAnexos/select/empresa'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposAnexosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function getTiposAnexosSelect() {
  return (dispatch) => {
    let apiEndpoint = 'tiposAnexos/select'
    crudService
      .get(apiEndpoint)
      .then((response) => {
        if (response.data) {
          dispatch(changeTiposAnexosList(response.data))
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

export function changeTiposAnexosList(tipoAnexo) {
  return {
    type: 'FETCHED_ALL_TIPO_ANEXO',
    tipoAnexo: tipoAnexo
  }
}

