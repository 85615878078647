const initialState = {
  dashboard: {
    obrasPendentes: 0,
    obrasRecebendoOrcamento: 0,
    obrasAprovadaEmAndamento: 0,
    obrasRecusadas: 0,
    obrasConcluidas: 0,
    orcamentosEnviados: 0,
    obrasAindaSemOrcamento: 0,
    totalAnexosPendentes: 0,
    totalObras: 0,
    totalUsuarios: 0,
    totalEmpresas: 0,
    graficoObras: { options: [], series: [] },
    graficoObrasPorMes: { options: [], series: [] },
    horasRecebimentoOrcamentos: '',
    valorTotalEmpenhos: 0,
    valorTotalEmpenhosEmAberto: 0,
    valorTotalEmpenhosPagos: 0
  },
  obras: [],
  totalObras: 0,
  isLoading: false,
  isLoadingObrasEmAberto: false,
  isLoadingGraficoObras: false
}

export function dashboard(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_DASHBOARD_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'FETCHED_DASHBOARD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_SUCCESS':
      return {
        ...state,
        dashboard: action.dashboard,
        isLoading: false
      }
    case 'FETCHED_DASHBOARD_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_REQUEST':
      return {
        ...state,
        isLoadingGraficoObras: true
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_SUCCESS':
      return {
        ...state,
        isLoadingGraficoObras: false,
        obras: action.obras,
        totalObras: action.totalObras
      }
    case 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_FAILURE':
      return {
        ...state,
        isLoadingGraficoObras: false,
        error: action.error
      }
    case 'DASHBOARD_CLEAR':
      return initialState
    default:
      return state
  }
}
