import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, obraAction } from '../../_actions'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Chip,
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import SendIcon from '@mui/icons-material/Send';
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import TitleContent from '../TitleContent'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';


const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const ObraAguardandoOrcamento = (props) => {

  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes, className } = props
  const { obra } = props.obra
  const { totalRegistros } = props.obra
  const { isLoading } = props.obra

  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [ready, setReady] = React.useState(false);

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: idOrgao,
        Status: 2
      };

      dispatch(obraAction.getObra(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao]);

  useEffect(() => {
    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        IdOrgao: idOrgao,
        Direction: direction,
        Status: 2
      };
      dispatch(obraAction.getObra(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [status])

  useEffect(() => {

  }, [totalRegistros, isLoading]);

  useEffect(() => {
  }, [ready, props.obra.isLoading])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <Page className={classes.root} title="Obras Aguardando Orçamento">
      <AccessControl
        rule={'obras.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {undefined !== obra && obra.length > 0 ? (
              <TitleContent
                length={obra.length}
                subTitle={'GERÊNCIA DE OBRAS'}
                title={'Lista de Obras Aguardando Orçamentos'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_OBRA_AGUARDANDO_ORCAMENTO}
              />
            ) : (
              <TitleContent
                length={0}
                subTitle={'GERÊNCIA DE OBRAS'}
                  title={'Lista de Obras Aguardando Orçamentos'}
                  telaManual={MANUAIS_CATEGORIAS.LISTAGEM_OBRA_AGUARDANDO_ORCAMENTO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Código</TableCell>
                    <TableCell>Data Início</TableCell>
                    <TableCell>Data Término</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell align="right">Valor Referência</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {undefined !== obra && obra.length
                    ? obra.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.id ? n.id : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.dataInicio ? moment(n.dataInicio).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.dataTermino ? moment(n.dataTermino).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.titulo ? n.titulo.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(n.valorReferenciaComBdi)}
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'obrasOrcamentos.create'} //permissão necessária para acessar conteúdo
                              yes={() =>
                                authActions.isEmpresa() ?
                                  (
                                    <IconButton
                                      aria-label={n.obrasOrcamentos.length > 0 ? "Ver orçamento" : "Enviar Orçamento"}
                                      component="a"
                                      onClick={() =>
                                        history.push(`/obraOrcamento/${n.obrasOrcamentos.length > 0 ? n.obrasOrcamentos[0].id : ''}`, n.id)
                                      }
                                      size="small"
                                    >
                                      <Tooltip title={n.obrasOrcamentos.length > 0 ? "Ver orçamento" : "Enviar Orçamento"}>
                                        <Tooltip >
                                          {n.obrasOrcamentos.length > 0 ? <EditIcon /> : <SendIcon />}
                                        </Tooltip>
                                      </Tooltip>
                                    </IconButton>
                                  )
                                  :
                                  (
                                    <IconButton
                                      aria-label="Enviar orçamento"
                                      component="a"
                                      onClick={() => history.push(`/obraOrcamento/`, n.id)}
                                      size="small"
                                    >
                                      <Tooltip title={'Enviar orçamento'}>
                                        <Tooltip >
                                          <SendIcon />
                                        </Tooltip>
                                      </Tooltip>
                                    </IconButton>
                                  )
                              }
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
    </Page >
  )
}
ObraAguardandoOrcamento.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    obra: state.obra
  }
}
const connectedObraAguardandoOrcamentoPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraAguardandoOrcamento))
)
export { connectedObraAguardandoOrcamentoPage as ObraAguardandoOrcamento }
