import { crudService } from '../_services'
import { stringUtils } from 'utils'
import { toast } from 'react-toastify'

export const notificacaoFiltroAction = {
  getNotificacaoFiltro,
  addNotificacaoFiltro,
  getNotificacaoFiltroById,
  getNotificacaoFiltroByNotificacao,
  onChangeProps,
  /*editNotificacaoFiltroInfo,*/
  editNotificacaoFiltrosDetails,
  createNotificacaoFiltro,
  deleteNotificacaoFiltroById,
  clear,
  clearAll,
  clearInfo
}


function getNotificacaoFiltro(filterModel, showWaiting) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesFiltros' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeNotificacaoFiltrosList(response.data.items, response.data.totalRows))
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createNotificacaoFiltro(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'notificacoesFiltros/'
    crudService.post(apiEndpoint, payload).then(() => {
      dispatch(createNotificacaoFiltroInfo())
      toast.success('Feito! Cadastro realizado com sucesso! 😎')
      dispatch(getNotificacaoFiltroByNotificacao(payload.idNotificacao))
      dispatch(notIsLoading())
    })
  }
}

function getNotificacaoFiltroById(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesFiltros/' + id
    crudService.get(apiEndpoint).then((response) => {
    //  console.log('response.data', response.data)
      dispatch(editNotificacaoFiltrosDetails(response.data))
    })
  }
}

function getNotificacaoFiltroByNotificacao(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesFiltros/notificacao/' + id
    crudService.get(apiEndpoint).then((response) => {
    //  console.log('response.data', response.data)
      dispatch(changeNotificacaoFiltrosList(response.data, 0))
    })
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}

//function editNotificacaoFiltroInfo(id, payload, redirect) {
//  return (dispatch) => {
//    let apiEndpoint = 'notificacoesFiltros/' + id
//    crudService.put(apiEndpoint, payload).then(() => {
//      dispatch(updatedNotificacaoFiltroInfo())
//      dispatch(clear())
//      if (redirect) {
//        history.push('/notificacoesFiltros')
//      }
//    })
//  }
//}

function deleteNotificacaoFiltroById(id) {
  return (dispatch) => {
    let apiEndpoint = 'notificacoesFiltros/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      var ret = JSON.parse(response.request.responseText);
      dispatch(deleteNotificacaoFiltrosDetails())
      dispatch(getNotificacaoFiltroByNotificacao(ret.key))
    })
  }
}

export function addNotificacaoFiltro(payload) {
  return [{ type: 'ADD_NOTIFICACAO_FILTRO', notificacaoFiltro: payload }, clear()]
}

export function changeNotificacaoFiltrosList(notificacaoFiltro, totalRows) {
  return {
    type: 'FETCHED_ALL_NOTIFICACAO_FILTRO',
    notificacaoFiltro: notificacaoFiltro,
    totalRegistros: totalRows
  }
}

export function clear() {
  return {
    type: 'NOTIFICACAO_FILTRO_CLEAR'
  }
}

export function clearAll() {
  return {
    type: 'NOTIFICACAO_FILTRO_CLEAR_ALL'
  }
}

export function clearInfo() {
  return {
    type: 'NOTIFICACAO_FILTRO_CLEAR_INFO'
  }
}

export function handleOnChangeProps(props, value) {
  return {
    type: 'NOTIFICACAO_FILTRO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}

export function editNotificacaoFiltrosDetails(notificacaoFiltro) {
  return [
    { type: 'NOTIFICACAO_FILTRO_DETAIL', notificacaoFiltro },
  ]
}

export function updatedNotificacaoFiltroInfo() {
  return {
    type: 'NOTIFICACAO_FILTRO_UPDATED'
  }
}

export function createNotificacaoFiltroInfo() {
  return {
    type: 'NOTIFICACAO_FILTRO_CREATED_SUCCESSFULLY'
  }
}

export function deleteNotificacaoFiltrosDetails() {
  return {
    type: 'DELETED_NOTIFICACAO_FILTRO_DETAILS'
  }
}

export function isLoading() {
  return {
    type: 'NOTIFICACAO_FILTRO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'NOTIFICACAO_FILTRO_NOTISLOADING'
  }
}
