import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { styled } from '@mui/material/styles';
import { AccessControl } from '../../components/Utils/AccessControl'
import { useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { crudService } from '../../_services';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {
  Autocomplete,
  Box,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import { obraCobrancaAction, obraAction, authActions } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withRouter, } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton/CustomButton'
import { ModalCronograma } from './ModalCronograma.component';
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#015192'
  },
}));

const ObraFinanceiroEdit = ({ props, idObra }) => {

  const { isLoading } = props.obraCobranca;
  const confirm = useConfirm()

  const { obraCobranca } = props.obraCobranca

  const [valueEmpresa, setValueEmpresa] = React.useState(null);
  const [inputValueEmpresa, setInputValueEmpresa] = React.useState('');
  const [empresas, setEmpresas] = useState([]);

  const { obraEtapa } = props.obraEtapa
  const { empresa } = useSelector(
    (state) => state.empresa
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const optionsEmpresa = empresas?.map(u => ({ id: u.id, nome: u.nome }));

  const { match: { params } } = props;

  const location = useLocation();
  const empresaListar = location.state ? location.state.empresa : null;

  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    if (props.authentication.user.idTipoUsuario == 3) {
      setEmpresas([{ id: props.authentication.user.usuariosEmpresas[0].idEmpresa, nome: props.authentication.user.usuariosEmpresas[0].empresa.razaoSocial }]);
    } else {
      crudService
        .get(`obrasOrcamentosItens/itensAprovados/${idObra}`)
        .then(response =>
          setEmpresas(response.data?.map(r => {
            return { id: r.idEmpresa, nome: r.empresa }
          })));
    }
  }, [])

  useEffect(() => {
  }, [ready, isLoading])

  useEffect(() => {

    if (valueEmpresa != null && valueEmpresa != '') {
      let filterModel = {
        IdObra: idObra,
        IdEmpresa: valueEmpresa.id
      };

      dispatch(obraCobrancaAction.getObraCobranca(filterModel))
    }
  }, [valueEmpresa])

  useEffect(() => {

    if (empresaListar) {
      let filterModel = {
        IdObra: idObra,
        IdEmpresa: empresaListar.id
      };

      dispatch(obraCobrancaAction.getObraCobranca(filterModel))

      setValueEmpresa({ id: empresaListar.id, nome: empresaListar.razaoSocial });
      setInputValueEmpresa({ id: empresaListar.id, nome: empresaListar.razaoSocial });
    }

  }, [empresaListar])

  const isItemPaid = (id) => {
    if (id != undefined) {
      return obraCobranca?.find((n) => n.dataPagamento != null && n.id == id)
    }
  }

  function TitleTextProgresso() {
    return (
      <Typography variant="h5">
        <b>{'Progresso Financeiro da Obra'}</b>
      </Typography>
    )
  }

  function SegHeaderProgresso() {
    return <TitleTextProgresso />
  }

  const handleOpenVisualizaNF = (link) => {
    window.open(link, '_blank');
  }

  const handleRealizaPagamento = (id) => {
    confirm({
      title: 'Você deseja realizar o pagamento desta Cobrança?',
      description: 'Essa operação é irreversível',
      disabled: props.obraCobranca.isLoading,
      confirmationText: props.obraCobranca.isLoading ? 'Realizando pagamento...' : 'Sim, receber!',
      cancellationText: 'Não!'
    }).then(() => {
      if (id && id != undefined) {
        dispatch(obraCobrancaAction.editObraCobrancaPaga(id, idObra));
      }
    })
  }

  function TitleText() {
    return (
      <Typography variant="h5">
        <b>{'Receber Cobranças'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <TitleText />
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
        <CustomButton
          style={{ backgroundColor: '#015192' }}
          onClick={() => dispatch(obraAction.handleModalCronograma())}
        >
          <Typography variant="p" color="#fff">Cronograma</Typography>
        </CustomButton>
      </Box>
    )
  }

  return (
    <>
      <ModalCronograma />

      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignItems: 'center', marginTop: 3 }}>
        <CardHeader
          style={{ width: '100%' }}
          subheader="Alterar o status de uma cobrança"
          title={<SegHeader />}
        />
        {(authActions.isEmpresa() && obraEtapa?.length) &&
          <Grid item>
            <CustomButton
              size="small"
              variant="outlined"
              style={{ verticalAlign: 'middle' }}
              onClick={() => history.push(`/obraCobranca/`, idObra)}
              endIcon={<MonetizationOnIcon />}
            >
              Cadastrar nova cobrança
            </CustomButton>
          </Grid>
        }
      </Grid>
      {props.authentication.user.idTipoUsuario != 3 &&
        <Grid item lg={12} md={12} xl={12} xs={12} mb={2}>
          <Autocomplete
            value={valueEmpresa}
            onChange={(event, newValue) => {
              setValueEmpresa(newValue);
            }}
            style={{ marginTop: 16 }}
            inputValue={inputValueEmpresa}
            onInputChange={(event, newInputValue) => {
              setInputValueEmpresa(newInputValue);
            }}
            id="controllable-states-demo"
            options={optionsEmpresa}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={valueEmpresa}
            getOptionLabel={option => option.nome || ''}
            renderInput={(params) => <TextField {...params} label="Selecione uma empresa para listar suas cobranças..." fullWidth required />}
            fullWidth
          />
        </Grid>
      }

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
              <TableCell size="small">
                <strong>DATA</strong>
              </TableCell>
              <TableCell size="small">
                <strong>EMPRESA</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>NOTA FISCAL</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>VALOR</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>STATUS</strong>
              </TableCell>
              <TableCell size="small" align="center">

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraCobranca && obraCobranca.length && valueEmpresa && valueEmpresa != null
              ? obraCobranca.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.dataCobranca ? moment(n.dataCobranca).format("DD/MM/YYYY HH:mm") : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.caminhoNota ?
                        <CustomButton
                          variant="outlined"
                          size="small"
                          style={{ justifyContent: 'center' }}
                          onClick={() => handleOpenVisualizaNF(n.caminhoNota)}
                        >
                          Visualizar
                        </CustomButton>
                        :
                        null}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Tooltip
                        title={n.dataPagamento != null ? `Pago em ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <CircleIcon sx={{ color: n.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.dataPagamento == null ? 'EM ABERTO' : 'PAGO'}</span></div>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <AccessControl
                        rule={'obrasCobrancas.pay'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Checkbox
                            onClick={() => handleRealizaPagamento(n.id)}
                            color="primary"
                            size="small"
                            disabled={isItemPaid(n.id)}
                            checked={isItemPaid(n.id)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  Array.apply(null, { length: 5 }).map((e, i) => (
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
              )
            }
          </TableBody>
        </Table>

      </TableContainer>
      <Divider />
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <CardHeader
            subheader="Exibe o percentual de pagamento em relação a todos os itens da obra que receberam cobrança"
            title={<SegHeaderProgresso />}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 3, ml: 1 }}>
              <BorderLinearProgress variant="determinate" value={props.obra.percentualObraOrcamentosPagos != 0 ? props.obra.percentualObraOrcamentosPagos : 0} />
            </Box>
            <Box sx={{ minWidth: 38 }}>
              <Typography variant="body1" color="text.secondary">{`${props.obra.percentualObraOrcamentosPagos != 0 ? props.obra.percentualObraOrcamentosPagos : 0}%`}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_FINANCEIRO}
          />
          :
          null
        )
      }
    </>
  );
};

ObraFinanceiroEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraFinanceiroEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraFinanceiroEdit))
)
export { connectedObraFinanceiroEditPage as ObraFinanceiroEdit }

export default ObraFinanceiroEdit;
