import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  Tooltip,
  Skeleton,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { obraAnexoAction } from '../../_actions'

export const ObraOrcamentoAnexoEmpresaModal = ({ modal, toggle, props, idObra }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { isLoading } = props.obraMedicaoEtapaItemAnexo

  const obraAnexo = useSelector(
    (state) => state.obraAnexo.obraAnexo
  )

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idObra) {
      dispatch(obraAnexoAction.getObraAnexo({ IdObra: idObra }))
    }
  }, [])

  const handleOpen = (link) => {
    window.open(link);
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              DOCUMENTOS ANEXADOS À OBRA
            </Typography>
          </Grid>
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
            onClick={() => handleClose()}>
            <CloseIcon />
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Table sx={{ minWidth: 650, marginTop: 3 }} aria-label="simple table">
              {undefined !== obraAnexo && obraAnexo.length
                ? obraAnexo.map((n) => {
                  const pathArray = n.caminhoAnexo.split('/');
                  const nomeArquivo = pathArray[pathArray.length - 1];

                  return (
                    <Tooltip title="Visualizar documento">
                      <TableRow hover style={{ cursor: 'pointer' }} onClick={() => handleOpen(n.caminhoAnexo)}>
                        <TableCell align="center">
                          {n.tipoAnexo ? n.tipoAnexo.nome : ''}
                        </TableCell>
                        <TableCell>
                          {nomeArquivo}
                        </TableCell>
                      </TableRow>
                    </Tooltip>
                  )
                })
                :
                (
                  !isLoading ?
                    <TableRow>
                      <TableCell align="center">Nenhum registro encontrado</TableCell>
                    </TableRow>
                    :
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                )
              }
            </Table>
          </Grid>
        </Card >
      </DialogContent >
    </Dialog >
  )
}
