import React, { useEffect, useRef, useState } from 'react';
import {
  CardHeader,
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Tooltip
} from '@mui/material'
import { connect, useDispatch } from 'react-redux'
import { withRouter, } from 'react-router-dom'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import { obraOcorrenciasAction } from '../../_actions/obraOcorrencias.actions';
import { SelectValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Box } from '@material-ui/core';
import toLongDate from '../../utils/toLongDate';
import moment from 'moment';
import { MANUAIS_CATEGORIAS } from '../../utils'
import { crudService } from '../../_services';
import CarouselImage from '../../components/Carousel/CarouselImage';
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const ObraOcorrenciasList = ({ props, idObra, classes }) => {

  const dispatch = useDispatch();

  const formChat = useRef();

  const [empresas, setEmpresas] = useState([]);
  const [carouselVisible, setCarouselVisible] = useState(false);
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props.authentication.user.idTipoUsuario == 3) {
      setEmpresas([{ ...props.authentication.user.usuariosEmpresas[0], selected: true }]);
    } else {
      crudService
        .get(`obrasOrcamentosItens/itensAprovados/${idObra}`)
        .then(response => setEmpresas(response.data?.map(r => { return { ...r, selected: false } })));
    }
  }, [])

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {
    const selectedEmpresa = empresas.find(e => e.selected);

    if (selectedEmpresa) {
      let filterModel = {
        IdObra: idObra,
        IdEmpresa: selectedEmpresa.idEmpresa
      };

      dispatch(obraOcorrenciasAction.getObraOcorrencias(filterModel))
    }
  }, [empresas]);

  function TitleText() {
    return (
      <Typography variant="h5">
        <b>{'Ocorrências'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    return <TitleText />
  }

  return (
    <>
      {carouselVisible && <CarouselImage images={images} openCarousel={carouselVisible} handleClose={() => setCarouselVisible(false)} />}

      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <CardHeader
            subheader="Visualizar fluxo das ocorrências"
            title={<SegHeader />}
          />
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      {props.authentication.user.idTipoUsuario != 3 &&
        <Grid item lg={12} md={12} xl={12} xs={12} mb={2}>
          <ValidatorForm
            className={classes.form}
            ref={formChat}
            id="formChat"
            onSubmit={(event) => console.log(event)}
          >
            <Grid item lg={12} md={12} xl={12} xs={12}>
              <SelectValidator
                className={classes.textField}
                variant="outlined"
                id="idEmpresa"
                label="Empresa"
                value={empresas?.find(e => e.selected)}
                onChange={(event) => setEmpresas(empresas.map(e => {
                  if (e.idEmpresa === event.target.value) {
                    return { ...e, selected: true }
                  } else {
                    return { ...e, selected: false }
                  }
                }))}
                margin="normal"
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
              >
                <MenuItem disabled value="">
                  <em>Empresa</em>
                </MenuItem>
                {empresas?.map((row, index) => (
                  <MenuItem key={index} value={row.idEmpresa}>
                    {row.empresa}
                  </MenuItem>
                ))}
              </SelectValidator>
            </Grid>
          </ValidatorForm>
        </Grid>
      }

      <Grid container spacing={2}>
        {props.obraOcorrencias?.obraOcorrencias?.length > 0 && empresas?.some(e => e.selected) &&
          props.obraOcorrencias.obraOcorrencias.map((ocorrencia, index, ocorrencias) => {
            const rightSide = (ocorrencia.origem === 'E' && props.authentication.user.idTipoUsuario == 3) || (ocorrencia.origem === 'O' && (props.authentication.user.idTipoUsuario == 2 || props.authentication.user.idTipoUsuario == 1));
            return (
              <>
                {(toLongDate(ocorrencia.data) !== toLongDate(ocorrencias[index - 1]?.data) || !index) &&
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Box display="flex" justifyContent="center" style={{ width: '250px', height: '24px', backgroundColor: '#ddd7', borderRadius: '10px' }}>
                      <Typography variant="p" fontSize="14px" style={{ textAlign: 'center' }}>{toLongDate(ocorrencia.data)}</Typography>
                    </Box>
                  </Grid>
                }

                <Grid item lg={12} md={12} xl={12} xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: rightSide ? 'flex-end' : 'flex-start' }}>
                  <Box display="flex" flexDirection="column" style={{ backgroundColor: rightSide ? 'rgb(1, 81, 146)' : '#ddd9', borderRadius: '15px', padding: '15px', paddingBottom: '5px', maxWidth: '60%' }}>
                    <Typography variant="p" fontSize="14px" fontWeight="bold" style={{ color: rightSide ? '#fff' : '#000' }}>{ocorrencia.usuario.nome}</Typography>
                    <Typography variant="p" fontSize="14px" style={{ color: rightSide ? '#fff' : '#000' }}>{ocorrencia.descricao}</Typography>
                    {ocorrencia.obrasOcorrenciasAnexos.length > 0 &&
                      <Box display="flex" flexWrap="wrap" flexDirection="row" maxWidth="100%">
                        {ocorrencia.obrasOcorrenciasAnexos.map((item) =>
                          <img
                            onClick={() => {
                              setImages(ocorrencia.obrasOcorrenciasAnexos.map(a => { return { caminhoAnexo: a.caminhoArquivo } }));
                              setCarouselVisible(true);
                            }}
                            width="60px"
                            height="60px"
                            src={item.caminhoArquivo}
                            style={{ borderRadius: '10px', objectFit: 'cover', marginRight: '10px', marginTop: '10px' }}
                            alt="Imagem de uma ocorrência"
                            loading="lazy"
                          />
                        )}
                      </Box>
                    }
                    <Typography variant="p" fontSize="12px" style={{ textAlign: 'right', color: rightSide ? '#fff' : '#000' }}>{moment(ocorrencia.data).format("HH:MM")}</Typography>
                  </Box>
                </Grid>
              </>
            )
          })
        }
        {props.obraOcorrencias?.obraOcorrencias?.length == 0 && empresas?.some(e => e.selected) && <Typography variant="p" textAlign="center" marginY="50px" width="100%">Sem ocorrências até o momento</Typography>}
      </Grid>
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_OCORRENCIA}
          />
          :
          null
        )
      }
    </>
  );
};

ObraOcorrenciasList.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraOcorrenciasListPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraOcorrenciasList))
)
export { connectedObraOcorrenciasListPage as ObraOcorrenciasList }

export default ObraOcorrenciasList;
