const initialState = {
  anchor: 'left',
  log: [],
  id: 0,
  tipo: '',
  tabela: '',
  chave: '',
  id_usuario: 0,
  dataHora: '',
  campo: '',
  valorAntigo: '',
  valorNovo: '',
  usuario: []
}

export function log(state = initialState, action) {
  switch (action.type) {
    case 'FETCHED_ALL_LOG':
      return {
        ...state,
        log: action.log
      }
    case 'LOG_DETAIL':
      return {
        ...state,
        id: action.id,
        tipo: action.tipo,
        tabela: action.tabela,
        chave: action.chave,
        id_usuario: action.id_usuario,
        dataHora: action.dataHora,
        campo: action.campo,
        valorAntigo: action.valorAntigo,
        valorNovo: action.valorNovo,
        usuario: action.usuario
      }
    case 'LOG_UPDATED':
      return state
    case 'LOG_CLEAR':
      return initialState
    case 'LOG_CLEAR_ALL':
      return {
        ...state,
        log: []
      }
    case 'LOG_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    default:
      return state
  }
}
