import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography';
import {
  Autocomplete,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Button,
  Badge,
  Grid,
  TextField,
  MenuItem
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  subOrgaoAction,
  orgaoAction
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import CustomButton from '../../components/CustomButton/CustomButton'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const SubOrgaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, subOrgao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const [valueOrgao, setValueOrgao] = useState(null);
  const [inputValueOrgao, setInputValueOrgao] = useState('');
  const [openOrgao, setOpenOrgao] = useState(false)

  const { orgao } = useSelector(
    (state) => state.orgao)

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const { usuarioOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  var optionsOrgaos = usuarioOrgao.map(u => ({ id: u.orgao.id, nome: u.orgao.nome }));

  useEffect(() => {

    dispatch(subOrgaoAction.clear())
    dispatch(orgaoAction.getOrgaoSelect())
    if (params.id) {
      dispatch(subOrgaoAction.getSubOrgaoById(params.id))
    } else {
      dispatch(subOrgaoAction.clear())
    }

  }, []);
  useEffect(() => {

  }, [ready, props.subOrgao.isLoading])

  const handleChange = (prop) => (event) => {
    dispatch(subOrgaoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (valueOrgao) {
      handleChangeAutoCompleteOrgao(valueOrgao)
    }
  }, [valueOrgao])

  const handleChangeAutoCompleteOrgao = (obj) => {
    if (obj != null) {
      let selectedOrgao = orgao.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        orgaoAction.changeDetailsOrgao(
          selectedOrgao[0]
        )
      )
    } else {

    }
    setOpenOrgao(false)
  }

  useEffect(() => {
    if (idOrgao != 0) {
      if (usuarioOrgao && usuarioOrgao.length > 0) {
        let selectedOrgao = usuarioOrgao.filter(
          (item) => item.idOrgao == idOrgao
        )
        setValueOrgao(usuarioOrgao.length > 0 ? selectedOrgao[0].orgao : '')
      }
    }
  }, [idOrgao])

  useEffect(() => {
    if (props.subOrgao.idOrgao != 0 && params.id != undefined) {
      setValueOrgao(props.subOrgao.orgao)
    }
  }, [props.subOrgao.idOrgao])

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      nome: props.subOrgao.nome,
      idOrgao: valueOrgao ? valueOrgao.id : props.subOrgao.idOrgao,
    }

    if (params.id) {
      dispatch(subOrgaoAction.editSubOrgaoInfo(params.id, payload, true))
    } else {
      dispatch(subOrgaoAction.createSubOrgao(payload))
    }
  };

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Sub Órgão'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Sub Órgão'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }
  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Sub Órgãos - Editar Sub Órgão'
          : 'Gerência de Sub Órgãos - Novo Sub Órgão'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formSubOrgao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <CardHeader
                  subheader="Cadastro de Sub Órgãos"
                  title={<SegHeader />}
                />
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    <Grid item lg={8} md={8} xl={8} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="nome"
                        label="Nome *"
                        margin="normal"
                        onInput={stringUtils.toInputUppercase}
                        onChange={handleChange('nome')}
                        value={props.subOrgao.nome || ''}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12} style={{ marginTop: 16 }}>
                      <Autocomplete
                        value={valueOrgao}
                        onChange={(event, newValue) => {
                          setValueOrgao(newValue);
                        }}
                        inputValue={inputValueOrgao}
                        onInputChange={(event, newInputValueOrgao) => {
                          setInputValueOrgao(newInputValueOrgao);
                        }}
                        id="controllable-states-demo"
                        options={optionsOrgaos}
                        margin="normal"
                        disabled={(idOrgao != null && idOrgao != '')}
                        variant="outlined"
                        defaultValue={valueOrgao}
                        getOptionLabel={(optionOrgao) => (optionOrgao ? optionOrgao.nome : '')}
                        renderInput={(params) => <TextField {...params} label="Órgão * " fullWidth />}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlign: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      variant="outlined"
                      onClick={() => history.push('/subOrgaos')}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      variant="contained"
                      dark={true}
                      type="submit"
                      disabled={props.subOrgao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.subOrgao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.subOrgao.isLoading ? 'Atualizando sub órgão...' : 'Atualizar') : (props.subOrgao.isLoading ? 'Salvando sub órgão...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Page >
  )
}

SubOrgaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedSubOrgaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(SubOrgaoEdit))
)
export { connectedSubOrgaoEditPage as SubOrgaoEdit }
