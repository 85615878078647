import { crudService } from '../_services'
import { stringUtils } from 'utils'

export const dashboardAction = {
  getDashboard,
  getDashboardGraficoObras,
  clear
}

function getDashboard(filterModel) {
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = 'dashboard' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(success(response.data))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }

  function request() {
    return {
      type: 'FETCHED_DASHBOARD_REQUEST'
    }
  }

  function success(dashboardResponse) {
    return {
      type: 'FETCHED_DASHBOARD_SUCCESS',
      dashboard: dashboardResponse
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_FAILURE', error }
  }
}

function getDashboardGraficoObras() {
  return (dispatch) => {
    dispatch(request())
    let apiEndpoint = 'dashboard/graficoObras';
    crudService
      .get(apiEndpoint)
      .then((response) => {
        //console.log("response", response)
        dispatch(success(response.data.items, response.data.totalRows))
      })
      .catch((error) => {
        dispatch(failure(error))
      })
  }
  function request() {
    return {
      type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_REQUEST'
    }
  }

  function success(obras, totalObras) {
    return {
      type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_SUCCESS',
      obras: obras,
      totalObras: totalObras
    }
  }

  function failure(error) {
    return { type: 'FETCHED_DASHBOARD_GRAFICO_ORDENS_SERVICO_FAILURE', error }
  }

}

export function clear() {
  return {
    type: 'DASHBOARD_CLEAR'
  }
}
