const initialState = {
  totalRegistros: 0,
  isLoading: false,
  subOrgao: [],
  orgao: [],
  id: '',
  nome: '',
  idOrgao: 0,
  excluido: false
}

export function subOrgao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_SUB_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_SUB_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.subOrgao.id,
        isLoading: false,
        orgao: action.subOrgao.orgao,
        nome: action.subOrgao.nome,        
        idOrgao: action.subOrgao.idOrgao,
        excluido: action.excluido
      }
    case 'CREATE_SUB_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_SUB_ORGAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_SUB_ORGAO_SUCCESS':
      return {
        ...state,
        id: action.subOrgao.id,
        isLoading: false,
        orgao: action.subOrgao.orgao,
        nome: action.subOrgao.nome,        
        idOrgao: action.subOrgao.idOrgao,
        excluido: action.subOrgao.excluido
      }
    case 'EDIT_SUB_ORGAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_SUB_ORGAO':
      return {
        ...state,
        subOrgao: action.subOrgao,
        totalRegistros: action.totalRegistros
      }
    case 'SUB_ORGAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        nome: action.nome,
        orgao: action.orgao,
        idOrgao: action.idOrgao,
        excluido: action.excluido
      }
    case 'SUB_ORGAO_CLEAR':
      return initialState
    case 'SUB_ORGAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'SUB_ORGAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'SUB_ORGAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
