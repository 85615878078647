import axios from 'axios'
import { errorHandler } from './errorHandler'
import config from '../config/config'

export async function uploadFile(file) {
  const data = new FormData()
  data.append('file', file)

  return axios
    .post(config.baseUrl + 'uploads/S3', data, getOptions())
    .then((response) => {
      return response.data.url
    })
    .catch((error) => {
     // console.log('error', error)
      return errorHandler(error)
    })
}


function getOptions() {
  let options = {}
  if (localStorage.getItem('token')) {
    options.headers = {
      Authorization: 'Bearer ' + localStorage.getItem('token')
    }
  }
  return options
}
