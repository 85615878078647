import React from 'react';
import Button from '@mui/material/Button'; // Supondo que você esteja usando o Material-UI

const CustomButton = (props) => {
  const { onClick, disabled, children, style: customStyle, dark, ...otherProps } = props; 

  return (
    dark ?
    (
      <Button
          style={{
            marginRight: 4, backgroundColor: '#000',
            color: '#FFF',
            '&:hover': {
              backgroundColor: '#000',
              color: '#FFF'
            }, ...customStyle }}
        onClick={onClick}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </Button>
    )
    :
    (
      <Button
          style={{ marginRight: 4, color: '#000', borderColor: '#000', ...customStyle }}
        onClick={onClick}
        disabled={disabled}
        {...otherProps}
      >
        {children}
      </Button>
    )
  )
};

export default CustomButton;
